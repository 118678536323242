import React from 'react'
import { Form, Button, Input } from 'antd'

const LoginForm = (props) => {
  const { onFinish, onFinishFailed } = props;
  return (
    <Form className="custom-modal-form login" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "This field is required" },
          { type: 'email', message: 'Invalid email format' }
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "This field is required" },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          autoComplete="new-password"
        />
      </Form.Item>
      <Button
        className="btn-primary"
        htmlType="submit"
        style={{ width: '100%' }}
      >
        Log in
      </Button>
    </Form>
  );
};

export default LoginForm;
