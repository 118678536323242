/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Table, Button, Spin } from 'antd'

import SelectComponent from '../../Components/SelectComponent'
import RangePickerComponent from '../../Components/RangePicker'
import DashboardActions from '../../Redux/DashboardRedux'
import OrdersActions from '../../Redux/OrdersRedux'
import DownloadIcon from '../../Components/Icons/Download'
import AreaChartComponent from '../../Components/AreaChartComponent'
import utils from '../../Utils/utils'

import './style.less'

const productFilter = [
  {
    name: 'Platform products',
    value: false
  },
  {
    name: 'All products',
    value: true
  }
];

const canceledFilter = [
  {
    name: 'Excluding canceled orders',
    value: false
  },
  {
    name: 'All',
    value: true
  }
];

const DashboardPage = props => {
  const { dashboard, fetching, avList } = props;

  const [netMargin, setNetMargin] = useState(null);
  const [grossSale, setGrossSale] = useState(null);
  const [purchaseCost, setPurchaseCost] = useState(null);
  const [orderUnit, setOrderUnit] = useState(null);
  const [dailyOverview, setDailyOverview] = useState([]);
  const [vaOverview, setVaOverview] = useState([]);
  const [start, setStart] = useState(false);
  const [filterBy, setFilterBy] = useState({
    av: null,
    date_from: null,
    date_to: null,
    file: false,
    all: false,
    canceled: false
  });

  useEffect(() => {
    props.getDashboard();
    props.getAvList();
    return function cleanup() {
      props.resetDashboard();
    }
  }, [0])

  useEffect(() => {
    if (dashboard) {
      if (dashboard.file_link) {
        utils.openHideLink(dashboard.file_link);
        setStart(false);
        setFilterBy({
          ...filterBy,
          file: false
        });
      } else {
        setNetMargin(dashboard.net_margin?.map(el => (
          {
            date: moment(el.date).format('MM/DD/YYYY'),
            'Net-Margin': Number(el.value.toFixed(2))
          }
        )));
        setGrossSale(dashboard.gross_sale?.map(el => (
          {
            date: moment(el.date).format('MM/DD/YYYY'),
            'Gross-Sale': Number(el.value.toFixed(2))
          }
        )));
        setPurchaseCost(dashboard.purchase_cost?.map(el => (
          {
            date: moment(el.date).format('MM/DD/YYYY'),
            'Purchase-Cost': Number(el.value.toFixed(2))
          }
        )));
        setOrderUnit(dashboard.order_unit?.map(el => (
          {
            date: moment(el.date).format('MM/DD/YYYY'),
            'orders': Number(el.value_order.toFixed(2)),
            'units': Number(el.value_unit.toFixed(2)),
          }
        )));
        setDailyOverview(dashboard.daily_overview);
        setVaOverview(dashboard.va_overview);
      }
    }
  }, [dashboard])

  const handleQuery = () => {
    const query = {
      ...filterBy
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getDashboard(query);
  }

  useEffect(() => {
    if (!start) {
      setStart(true);
    } else {
      handleQuery();
    }
  }, [filterBy])

  const content = (
    <div>
      <div>link</div>
      <div>link</div>
    </div>
  );

  const dailyOverviewColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: text =>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{text && moment.utc(text).format('MM/DD/YYYY')}</span>
          {/* <span>{text && moment.utc(text).format('LT').toLowerCase()}</span> */}
        </div>
    },
    {
      title: 'Gross Sales',
      dataIndex: 'gross_sale',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Sales Overheads',
      dataIndex: 'sale_overheads',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net revenue',
      dataIndex: 'net_revenue',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Purchase Cost',
      dataIndex: 'purchase_cost',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net Profit',
      dataIndex: 'net_profit',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net Margin',
      dataIndex: 'net_margin',
      render: text => text?.toFixed()
    },
    {
      title: 'Number of Orders',
      dataIndex: 'number_of_orders',
      render: text => text?.toFixed()
    },
  ];

  const vaOverviewColumns = [
    {
      title: 'VA Name',
      dataIndex: 'va_name',
    },
    {
      title: 'Gross Sales',
      dataIndex: 'gross_sale',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Sales Overheads',
      dataIndex: 'sale_overheads',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net revenue',
      dataIndex: 'net_revenue',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Purchase Cost',
      dataIndex: 'purchase_cost',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net Profit',
      dataIndex: 'net_profit',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Net Margin',
      dataIndex: 'net_margin',
      render: text => text?.toFixed()
    },
    {
      title: 'Number of Orders',
      dataIndex: 'number_of_orders',
      render: text => text?.toFixed()
    },
  ];

  const changeFilterDate = values => {
    setFilterBy({
      ...filterBy,
      date_from: values ? values[0].format('YYYY-MM-DD') : null,
      date_to: values ? values[1].format('YYYY-MM-DD') : null,
    })
  }

  const changeFilter = (field, value) => {
    setFilterBy({
      ...filterBy,
      [field]: value
    })
  }

  return (
    <div className="content-page">
      <Spin size="large" spinning={fetching}>
        <div className="table-options">
          <div className="table-options-inputs" style={{ width: '100%' }}>
            <RangePickerComponent
              onChange={changeFilterDate}
            />
            <SelectComponent
              showSearch
              extendedFullName={true}
              data={avList}
              allowClear
              onChange={e => changeFilter('av', e)}
              placeholder="VA"
            />
            <SelectComponent data={productFilter}
              onChange={e => changeFilter('all', e)}
              defaultValue={false}
            />
            <SelectComponent data={canceledFilter}
              onChange={e => changeFilter('canceled', e)}
              defaultValue={false}
            />
            <Button
              className="btn-primary orange icon"
              style={{ marginLeft: 'auto' }}
              onClick={() => setFilterBy({
                ...filterBy,
                file: true
              })}
            >
              <DownloadIcon />
              <span>
                Download
              </span>
            </Button>
            {/* <Popover overlayClassName="download-popover" placement="bottomLeft" content={content} trigger="click">
            <div className="popover-wrap">
              <DownloadIcon isActive />
              <span style={{ marginLeft: 8 }}>Download</span>
            </div>
          </Popover> */}
          </div>
        </div>
        <div className="dashboard-content">
          <div className="title">Overall</div>
          <div className="chart-block">
            <div className="chart">
              <AreaChartComponent
                data={netMargin}
                dataKey_XAxis={'date'}
                dataKey_Line={['Net-Margin']}
                color={['red']}
                YLabel="Net Margin"
                gradientData={['red']}
              />
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.overall?.gross_sale)}
                <span className="name">Gross sale</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.overall?.net_revenue)}
                <span className="name">Net Revenue</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.overall?.purchase_cost)}
                <span className="name">Purchase cost</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.overall?.net_profit)}
                <span className="name">Net Profit</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.overall?.net_margin)}
                <span className="name">Net Margin</span>
              </div>
            </div>
          </div>

          <div className="title">Sell side</div>
          <div className="chart-block">
            <div className="chart">
              <AreaChartComponent
                data={grossSale}
                dataKey_XAxis={'date'}
                dataKey_Line={['Gross-Sale']}
                color={['blue']}
                YLabel="Gross sale"
                gradientData={['blue']}
              />
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.sale_tax)}
                <span className="name">Sale tax</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.amazon_fee)}
                <span className="name">Amazon Fee</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.total_refund)}
                <span className="name">Total refund</span>
              </div>
            </div>
          </div>

          <div className="chart-block">
            <div className="chart">
              <AreaChartComponent
                data={purchaseCost}
                dataKey_XAxis={'date'}
                dataKey_Line={['Purchase-Cost']}
                color={['green']}
                YLabel="Purchase cost"
                gradientData={['green']}
              />
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.tax)}
                <span className="name">Tax</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.coupon_savings)}
                <span className="name">Coupon Savings</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.shipping)}
                <span className="name">Shipping</span>
              </div>
            </div>
            <div className="data">
              <div className="value">
                {utils.formatIntegerValue(dashboard?.sell_side?.return_r)}
                <span className="name">Return</span>
              </div>
            </div>
          </div>

          <div className="chart-block">
            <div className="data bottom">
              <div className="name">
                <span style={{ color: '#740bba' }}>Total orders: </span>
                <span> {dashboard?.orders}</span>
              </div>
              <div className="name">
                <span style={{ color: '#e35a20' }}>Total units: </span>
                <span> {dashboard?.units}</span>
              </div>
            </div>
            <div className="chart" style={{ flex: 3 }}>
              <AreaChartComponent
                data={orderUnit}
                dataKey_XAxis={'date'}
                dataKey_Line={['units', 'orders']}
                color={['#e35a20', '#740bba']}
                gradientData={['#e35a20', '#740bba']}
              />
            </div>
          </div>

          <div className="title">Daily overview</div>
          <Table
            className="table"
            rowKey={row => row.date}
            dataSource={dailyOverview}
            columns={dailyOverviewColumns}
            scroll={{ y: 600 }}
            pagination={false}
            loading={false}
            bordered
          />

          <div className="title" style={{ marginTop: 24 }}>VA overview</div>
          <Table
            className="table"
            rowKey={row => row.va_name}
            dataSource={vaOverview}
            columns={vaOverviewColumns}
            scroll={{ y: 600 }}
            pagination={false}
            loading={false}
            bordered
          />
        </div>
      </Spin>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard.list,
    fetching: state.dashboard.fetching,
    avList: state.orders.avList,

  }
}

const mapDispatchToProps = (dispatch) => ({
  getDashboard: (query) => dispatch(DashboardActions.dashboardRequest(query)),
  resetDashboard: () => dispatch(DashboardActions.dashboardReset()),

  getAvList: () => dispatch(OrdersActions.avListRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
