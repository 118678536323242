/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import CartItem from '../Icons/Cart'
import UploadIcon from '../Icons/Upload'
import ListItem from '../Icons/List'
import BellIcon from '../Icons/Bell'
import Ellips from '../Icons/Ellips'
import UserIcon from '../Icons/User'
import UsersIcon from '../Icons/Users'
import DashboardIcon from '../Icons/Dashboard'
import OutIcon from '../Icons/Out'

import AuthActions from '../../Redux/AuthRedux'

import './style.less'

const NavigationMenu = props => {
  const { isNewNotes, permissions } = props;

  const [currentActive, setCurrentActive] = useState(null)
  const [notes, setNotes] = useState(false)

  const history = useHistory();

  const options = [
    'orders',
    'products',
    // 'uploads',
    'divider',
    // 'profile',
    'dashboard',
    'notifications',
    'users',
    'divider',
    'log out'
  ];

  if (!permissions.edit_user) {
    const index = options.findIndex(el => el === 'users');
    options.splice(index, 1);
  }
  if (!permissions?.dashboard) {
    const index = options.findIndex(el => el === 'dashboard');
    options.splice(index, 1);
  }
  if (!permissions?.products_view) {
    const index = options.findIndex(el => el === 'products');
    options.splice(index, 1);
  }
  if (!permissions?.orders_view) {
    const index = options.findIndex(el => el === 'orders');
    options.splice(index, 1);
  }

  const icons = {
    orders: (isActive) => <CartItem isActive={isActive} />,
    products: (isActive) => <ListItem isActive={isActive} />,
    uploads: (isActive) => <UploadIcon isActive={isActive} />,
    notifications: (isActive) => {
      return (
        <>
        <div className='bell'><BellIcon isActive={isActive} />
          {<div className='ellips'> <Ellips isActive={notes} /></div>}
        </div>
        </>
      )
    },
    profile: (isActive) => <UserIcon isActive={isActive} />,
    dashboard: (isActive) => <DashboardIcon isActive={isActive} />,
    users: (isActive) => <UsersIcon isActive={isActive} />,
    'log out': () => <OutIcon />,
  }

  useEffect(() => {
    options.forEach(option => {
      if (history?.location?.pathname?.includes(option)) {
        setCurrentActive(option);
      }
    })
  }, [])

  useEffect(() => {
    setNotes(isNewNotes.notification)
  }, [isNewNotes?.notification])

  const logout = () => {
    props.logout();
    history.push('/login');
  }

  const links = options.map((link, i) => {
    const activeClassName = `header-link header-link--${link} header-link--active`
    const disabledClassName = `header-link header-link--${link}`

    if (link === 'log out') {
      return (
        <li key={i}>
          <NavLink to=""
            className={disabledClassName}
            onClick={logout}
          >
            <div className="header-link-icon">
              {icons[link](link === currentActive)}
            </div>
            <span className="header-link-text">{link}</span>
          </NavLink >
        </li >
      )
    } else if (link === 'divider') {
      return (
        <div key={i} className="header-link-divider" />
      )
    } else {
      return (
        <li key={i}>
          <NavLink to={`/home/${link}`}
            className={({ isActive }) =>
              isActive ? activeClassName : disabledClassName
            }
            onClick={() => setCurrentActive(link)}
          >
            <div className="header-link-icon">
              {icons[link](link === currentActive)}
            </div >
            <span className="header-link-text">{link}</span>
          </NavLink >
        </li >
      )
    }
  })

  return (
    <nav className="header-navigation">
      <ul className="header-navigation-list">
        {links}
      </ul >
    </nav >
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    isNewNotes: state.notes.isNewNotes,
    permissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu)
