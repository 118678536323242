/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal, Button, Table, Popconfirm, Spin, Tooltip, message, Input, Row } from 'antd'
import moment from 'moment'

import ProductsActions from '../../Redux/ProductsRedux'
import OrdersActions from '../../Redux/OrdersRedux'
import ProductStoreForm from './components/ProductStoreForm'
import ProductSupplierForm from './components/ProductSupplierForm'
import Bucket from '../../Components/Icons/Bucket'
import Pencil from '../../Components/Icons/Pencil'

import './style.less'

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
  htmlType: 'submit',
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
};

const ProductCardPage = props => {
  const { products, product, match, noProduct, permissions, comments, saveCommentResult } = props;
  const { id } = match.params;

  const history = useHistory();

  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const [newCommentData, setNewCommentData] = useState(null);
  const [commentsData, setCommentsData] = useState(null);

  const getComments = (id) => {
    let query = { product: id ? id : data?.oms_id }
    props.getComments(query);
  }

  useEffect(() => {
    props.getProduct(id);
  }, [0])

  useEffect(() => {
    if (comments) {
      setCommentsData(comments);
    } 
  }, [comments])

  useEffect(() => {
    if (saveCommentResult) {
      setNewCommentData(null);
      getComments();
    } 
  }, [saveCommentResult])

  useEffect(() => {
    if (product) {
      setData(product);
      getComments(product.oms_id);
    }
    if (noProduct) {
      history.push(`/home/notifications`);
      message.warn('This product was not found on platform')
    }
  }, [product, noProduct])

  useEffect(() => {
    if (products.saveResult || products.deleteResult) {
      props.getProduct(id);
      setModalVisible(false);
    }
  }, [products.saveResult, products.deleteResult])

  // const editStore = record => {
  //   setFormValues(record);
  //   setModalType('editStore');
  //   setModalVisible(true);
  // }

  const editSupplier = record => {
    setFormValues(record);
    setModalType('editSupplier');
    setModalVisible(true);
  }

  const editStore = record => {
    setFormValues(record);
    setModalType('editStore');
    setModalVisible(true);
  }

  const onSubmit = data => {
    if (modalType === 'createStore') {
      props.addStoreToProduct(id, data);
    } else if (modalType === 'createSupplier') {
      props.addSupplierToProduct(id, data);
    } else if (modalType === 'editSupplier') {
      data.supplier_id = formValues.id;
      props.editSupplierUrl(id, data);
    } else if (modalType === 'editStore') {
      data.marketplace_id = formValues.oms_id;
      props.editStoreData(id, data);
    }
  }

  const storeTableColumns = [
    {
      title: 'Store name',
      dataIndex: 'store_name',
    },
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      className: 'too-long',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Default Qty',
      dataIndex: 'default_quantity',
    },
    {
      title: 'Retail price',
      dataIndex: 'retail_price',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Listed by',
      dataIndex: 'listed_by',
      render: text => <span>{text ? `${text.first_name} ${text.last_name}` : null}</span>
    },
    {
      title: 'Listed date',
      dataIndex: 'listed_date',
      render: text =>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{text && moment.utc(text).format('MM/DD/YYYY')}</span>
          <span>{text && moment.utc(text).format('LT').toLowerCase()}</span>
        </div>
    },
    {
      title: 'Handling time',
      dataIndex: 'handling_time',
    },
    {
      title: 'oms ID',
      dataIndex: 'oms_id',
    },
    {
      key: 'action',
      render: (text, record) => (
        <div className="table-action column">
          {permissions?.products_card_edit && 
          <a
            style={{ marginBottom: 8 }}
            onClick={() => editStore(record)}
          >
            <Pencil />
          </a>}
          {/* <Popconfirm
            title="Sure to delete?"
            okButtonProps={{ className: "btn-primary" }}
            cancelButtonProps={{ className: "btn-primary white" }}
            onConfirm={() => props.deleteStoreFromProduct(id, record.oms_id)}
          >
            <a><Bucket /></a>
          </Popconfirm> */}
        </div>
      )
    }
  ];

  const supplierTableColumns = [
    {
      title: 'Supplier',
      dataIndex: 'supplier',
    },
    {
      title: 'Source URL',
      dataIndex: 'source_url',
      className: 'too-long',
      render: text => (
        <a href={text} target="_blank" rel=" noreferrer"
          className="ant-table-cell ant-table-cell--underline ant-table-cell--link">
          {text}
        </a >
      )
    },
    {
      title: 'Item ID',
      dataIndex: 'item_id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Supplier price',
      dataIndex: 'supplier_price',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Shipping cost',
      dataIndex: 'shipping_cost',
      render: text => text?.toFixed(2)
    },
    {
      title: 'Dispatch time',
      dataIndex: 'dispatch_time',
      render: text => text?.toFixed() || 0
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      key: 'action',
      render: (text, record) => (
        <div className="table-action column">
          {permissions?.products_card_edit && <a
            style={{ marginBottom: 8 }}
            onClick={() => editSupplier(record)}
          >
            <Pencil />
          </a>}
          {permissions?.products_card_edit && <Popconfirm
            title="Sure to delete?"
            okButtonProps={{ className: "btn-primary" }}
            cancelButtonProps={{ className: "btn-primary white" }}
            onConfirm={() => props.deleteSupplierFromProduct(id, record.id)}
          >
            <a><Bucket /></a>
          </Popconfirm>}
        </div >
      )
    }
  ];

  const ModalContent = {
    createStore: {
      title: 'Add store',
      children: () => (
        <Spin size="large" spinning={products.saveResult}>
          <ProductStoreForm
            shops={products.storeList}
            onSubmit={onSubmit}
          />
        </Spin>
      ),
      okButtonProps: {
        ...okButtonProps,
        form: 'product-store-form',
        disabled: products.saveResult
      }
    },
    editStore: {
      title: `Edit store`,
      children: () => (
        <Spin size="large" spinning={products.saveResult}>
          <ProductStoreForm
            initial={formValues}
            onSubmit={onSubmit}
          />
        </Spin>
      ),
      okButtonProps: {
        ...okButtonProps,
        htmlType: 'submit',
        form: 'product-store-form'
      }
    },
    createSupplier: {
      title: 'Add supplier',
      children: () => (
        <Spin size="large" spinning={products.saveResult}>
          <ProductSupplierForm
            suppliers={products.supplierList}
            onSubmit={onSubmit}
          />
        </Spin>
      ),
      okButtonProps: {
        ...okButtonProps,
        form: 'product-supplier-form',
        disabled: products.saveResult
      }
    },
    editSupplier: {
      title: `Edit supplier`,
      children: () => (
        <Spin size="large" spinning={products.saveResult}>
          <ProductSupplierForm
            initial={formValues}
            onSubmit={onSubmit}
          />
        </Spin>
      ),
      okButtonProps: {
        ...okButtonProps,
        htmlType: 'submit',
        form: 'product-supplier-form',
        disabled: products.saveResult
      }
    }
  }

  return (
    <div className="product-card-page content-page">
      <div className="block product-info">
        <div className="ava">
          {
            data?.image ?
              <div style={{ backgroundImage: `url(${data.image})` }}></div>
              // <img src={data.image}
              //   alt={data.image} />
              :
              <div className="no-image"></div>
          }
        </div>
        <span className="card-label">ASIN</span>
        <div className="card-item">{data?.asin}</div>
        <span className="card-label">UPC</span>
        <div className="card-item">{data?.upc}</div>
        <span className="card-label">Title</span>
        <div className="card-item">{data?.title}</div>
        <span className="card-label">Product URL</span>
        <div className="card-item">
          <Tooltip title={data?.product_url}>
            <a href={data?.product_url} target="_blank" rel=" noreferrer">
              {data?.product_url}
            </a >
          </Tooltip>
        </div>
      </div>
      <div className="block source">
        <div className="content">
          <div className="title">
            <h3>Marketplaces</h3>
            {/* <Button
              type="primary"
              className="btn-primary"
              onClick={() => {
                props.getStoreList();
                setModalType('createStore');
                setModalVisible(true);
              }}
            >
              Add store
            </Button> */}
          </div>
          <Table
            className="table card"
            rowKey={row => row.oms_id}
            dataSource={data?.marketplaces}
            columns={storeTableColumns}
            scrollToFirstRowOnChange
            pagination={false}
            loading={false}
            bordered
          />
        </div>
        <div className="content suppliers">
          <div className="title">
            <h3>Suppliers</h3>
            {permissions?.products_card_edit && <Button
              type="primary"
              className="btn-primary"
              onClick={() => {
                props.getSupplierList();
                setModalType('createSupplier');
                setModalVisible(true);
              }}
            >
              Add supplier
            </Button>}
          </div>
          <Table
            className="table card"
            rowKey={row => row.id}
            dataSource={data?.suppliers}
            columns={supplierTableColumns}
            scrollToFirstRowOnChange
            pagination={false}
            loading={false}
            bordered
          />
        </div>
        <h3 className='comments-title'>Comments</h3>
        <div className='comments-wrapper products'>
          <div>Add comment</div>
            <Input.TextArea
              className='add-comment-input' 
              maxLength={5000} 
              autoSize={{minRows: 1, maxRows: 2}} 
              disabled={!permissions?.products_card_edit}
              onChange={(e)=> setNewCommentData(e.target.value)}
              value={newCommentData}
            />
            <Row>
              <div 
                className='add-comment-button-cancel'
                onClick={()=> setNewCommentData(null)}
              >
                CANCEL
              </div>
              <div 
                className='add-comment-button-add'
                onClick={()=> newCommentData ? props.saveComment({ text: newCommentData, product: data?.oms_id}) : null}
              >
                ADD COMMENT
              </div>
            </Row>
            {commentsData && commentsData?.map(el => {

              return (
                <div className='comment' key={el.created}>
                  <Row>
                    <div className='comment-date'>{moment(el.created).format('MM/DD/YYYY, h:mm a')}</div>
                    <div className='comment-name'>{el.user_name}</div>
                  </Row>
                  <div className='comment-text'>{el.text}</div>
                </div>
              )
            })}
          </div>
      </div>
      <Modal
        maskClosable={false}
        wrapClassName="modal-wrap"
        className="modal"
        width={400}
        title={ModalContent[modalType]?.title}
        visible={modalVisible}
        children={ModalContent[modalType]?.children()}
        handleClose={() => {
          setModalVisible(false);
          setFormValues(null);
        }}
        onOk={ModalContent[modalType]?.cb}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          setFormValues(null);
        }}
        cancelText="Cancel"
        okButtonProps={ModalContent[modalType]?.okButtonProps}
        cancelButtonProps={cancelButtonProps}
        destroyOnClose={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    role: state.auth.role,
    products: state.products,
    product: state.products.product,
    noProduct: state.products.noProduct,
    permissions: state.auth.permissions,
    comments: state.orders.comments,
    saveCommentResult: state.orders.saveCommentResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProduct: (productId) => dispatch(ProductsActions.productRequest(productId)),
  getStoreList: () => dispatch(ProductsActions.storesRequest()),
  getSupplierList: () => dispatch(ProductsActions.suppliersRequest()),
  addStoreToProduct: (productId, data) => dispatch(ProductsActions.productStoreAddRequest(productId, data)),
  deleteStoreFromProduct: (productId, shopId) => dispatch(ProductsActions.productStoreDeleteRequest(productId, shopId)),
  addSupplierToProduct: (productId, data) => dispatch(ProductsActions.productSupplierAddRequest(productId, data)),
  deleteSupplierFromProduct: (productId, supplierId) => dispatch(ProductsActions.productSupplierDeleteRequest(productId, supplierId)),
  editSupplierUrl: (productId, data) => dispatch(ProductsActions.supplierUrlEditRequest(productId, data)),
  editStoreData: (productId, data) => dispatch(ProductsActions.productStoreEditRequest(productId, data)),
  getComments: (id) => dispatch(OrdersActions.getCommentsRequest(id)),
  saveComment: (data) => dispatch(OrdersActions.saveCommentRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardPage)
