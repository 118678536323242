import React from 'react'
import { Spin } from 'antd'

import './style.less'

const Loader = () => {
  return (
    <div className="popup_wrapper">
      <Spin />
    </div>
  );
};

export default Loader;
