import { call, put } from 'redux-saga/effects'
import ProductsActions from '../Redux/ProductsRedux'
// import { call, put, take, select, delay } from 'redux-saga/effects'
// import ProductsActions, { ProductsTypes, ProductsSelectors} from '../Redux/ProductsRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getProducts(api, authApi, { query }) {
  try {
    const response = yield call(api.getProducts, query)
    if (response && response.ok) {
      yield put(ProductsActions.productsSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getProducts, { query }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getProduct(api, authApi, { productId }) {
  try {
    const response = yield call(api.getProduct, productId)
    if (response && response.ok) {
      yield put(ProductsActions.productSuccess(response.data))
      // yield put(ProductsActions.setProductId(productId))
      yield put(ProductsActions.productReset())
    } else if (response.status === 404) {
      yield put(ErrorsActions.errorSave(null))
      yield put(ProductsActions.productFailure('not found'))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, getProduct, { productId }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveProduct(api, authApi, { data }) {
  try {
    const response = yield call(api.saveProduct, data)
    if (response && response.ok) {
      yield put(ProductsActions.productSaveSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, saveProduct, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

// export function* deleteProduct(api, authApi, { productId }) {
//   try {
//     const response = yield call(api.deleteProduct, productId)
//     if (response && response.ok) {
//       yield put(ProductsActions.productDeleteSuccess(response.data))
//       yield put(ProductsActions.resultsReset())
//     } else {
//       if (response && response.data) {
//         if (response.status === 401) {
//           yield put(ErrorsActions.errorSave(null))
//         } else if (response.status === 500) {
//           yield put(ErrorsActions.errorSave("Server response error"))
//         } else {
//           yield put(ErrorsActions.errorSave(response.data))
//         }
//       } else {
//         yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
//       }
//       yield put(ProductsActions.productsFetchingReset())
//     }

//     if (response && response.status === 401) {
//       const res = yield call(authApi.tokenRefresh)
//       if (res && res.access) {
//         yield put(AuthActions.updateTokens(res.access, res.refresh))
//         yield* deleteProduct(api, authApi, { productId })
//       } else {
//         yield put(AuthActions.logout())
//         yield put(push('/login'))
//       }
//     }
//   } catch (e) {
//     yield put(ProductsActions.productsFetchingReset())
//     yield put(ErrorsActions.errorSave('Server response error: ' + e))
//   }
// }

export function* deleteProducts(api, authApi, { data }) {
  try {
    const response = yield call(api.deleteProducts, data)
    if (response && response.ok) {
      yield put(ProductsActions.productsDeleteSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, deleteProducts, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* changeProductQuantity(api, authApi, { data }) {
  try {
    const response = yield call(api.changeProductQuantity, data)
    if (response && response.ok) {
      yield put(ProductsActions.productQtyEditSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, changeProductQuantity, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getStoreList(api, authApi) {
  try {
    const response = yield call(api.getStoreList)
    if (response && response.ok) {
      yield put(ProductsActions.storesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getStoreList, null, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* addStoreToProduct(api, authApi, { productId, data }) {
  try {
    const response = yield call(api.addStoreToProduct, productId, data)
    if (response && response.ok) {
      yield put(ProductsActions.productStoreAddSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, addStoreToProduct, { productId, data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* deleteStoreFromProduct(api, authApi, { productId, shopId }) {
  try {
    const response = yield call(api.deleteStoreFromProduct, productId, shopId)
    if (response && response.ok) {
      yield put(ProductsActions.productStoreDeleteSuccess(response.data || true))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, deleteStoreFromProduct, { productId, shopId }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getSupplierList(api, authApi) {
  try {
    const response = yield call(api.getSupplierList)
    if (response && response.ok) {
      yield put(ProductsActions.suppliersSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getSupplierList, null, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* addSupplierToProduct(api, authApi, { productId, data }) {
  try {
    const response = yield call(api.addSupplierToProduct, productId, data)
    if (response && response.ok) {
      yield put(ProductsActions.productSupplierAddSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, addSupplierToProduct, { productId, data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* deleteSupplierFromProduct(api, authApi, { productId, supplierId }) {
  try {
    const response = yield call(api.deleteSupplierFromProduct, productId, supplierId)
    if (response && response.ok) {
      yield put(ProductsActions.productSupplierDeleteSuccess(response.data || true))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, deleteSupplierFromProduct, { productId, supplierId }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* editSupplierUrl(api, authApi, { productId, data }) {
  try {
    const response = yield call(api.editSupplierUrl, productId, data)
    if (response && response.ok) {
      yield put(ProductsActions.supplierUrlEditSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, editSupplierUrl, { productId, data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* editStoreData(api, authApi, { productId, data }) {
  try {
    const response = yield call(api.editStoreData, productId, data)
    if (response && response.ok) {
      yield put(ProductsActions.productStoreEditSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, editStoreData, { productId, data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getUploadProductData(api, authApi) {
  try {
    const response = yield call(api.getUploadProductData)
    if (response && response.ok) {
      yield put(ProductsActions.productUploadListSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getUploadProductData, null, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* uploadProductData(api, authApi, { data }) {
  try {
    const response = yield call(api.uploadProductData, data)
    if (response && response.ok) {
      yield put(ProductsActions.productUploadSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, uploadProductData, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* changeProductStatus(api, authApi, { data }) {
  try {
    const response = yield call(api.changeProductStatus, data)
    if (response && response.ok) {
      yield put(ProductsActions.productChangeStatusSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, changeProductStatus, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveHandlingTime(api, authApi, { data }) {
  try {
    const response = yield call(api.saveHandlingTime, data)
    if (response && response.ok) {
      yield put(ProductsActions.productSaveHandlingTimeSuccess(response.data))
      yield put(ProductsActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, saveHandlingTime, { data }, ErrorsActions.errorSave, ProductsActions.productsFetchingReset)
    }
  } catch (e) {
    yield put(ProductsActions.productsFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

// export function * productsSyncStartMonitor() {
//   while (true) {
//     const payload = yield take(ProductsTypes.PRODUCTS_SYNC_TOGGLE);
//     let doWork = payload.syncIsStart;
//     while (doWork) {
//       const { syncIsStart } = yield select(ProductsSelectors.getState);
//       if (!syncIsStart) {
//         break;
//       }
//       const query = {
//         page: 1,
//         page_size: 10
//       }
//       yield put(ProductsActions.productsRequest(query));
//       doWork = syncIsStart;
//       yield delay(30000);
//     }
//   }
// }
