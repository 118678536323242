import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ordersRequest: ['query'],
  ordersSuccess: ['list'],

  // orderRequest: ['orderId'],
  // orderSuccess: ['order'],
  // orderReset: null,

  orderSaveRequest: ['data'],
  orderSaveSuccess: ['saveResult'],

  saveCommentRequest: ['data'],
  saveCommentSuccess: ['saveCommentResult'],

  getCommentsRequest: ['query'],
  getCommentsSuccess: ['comments'],

  // orderDeleteRequest: ['orderId'],
  // orderDeleteSuccess: ['deleteResult'],

  // setOrderId: ['orderId'],

  internalStatusSaveRequest: ['data'],
  internalStatusSaveSuccess: ['saveStatusResult'],

  orderFiltersRequest: null,
  orderFiltersSuccess: ['filters'],

  avListRequest: null,
  avListSuccess: ['avList'],

  assignedSaveRequest: ['data'],
  assignedSaveSuccess: ['saveResult'],

  shippingServicesRequest: null,
  shippingServicesSuccess: ['shippingServices'],

  setTableOrder: ['columnOrder'],
  
  // ordersChangeStatus: ['data'],

  orderTableSettingsReset: null,

  ordersReset: null,

  resultsReset: null,
  ordersFetchingReset: null
})

export const OrdersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  // order: null,
  // orderId: null,
  fetching: false,

  avList: null,
  shippingServices: null,

  saveResult: null,
  saveStatusResult: null,
  saveFetching: false,

  // deleteResult: null,
  // deleteFetching: false,

  page: 1,
  page_size: 10,
  ordering: '',
  search: '',
  filters: null,

  columnOrder: null,

  comments: null,

})

/* ------------- Selectors ------------- */
export const OrdersSelectors = {
  getState: (state) => state.orders
}

/* ------------- Reducers ------------- */

export const ordersRequest = (state, { query }) => {
  const newState = {
    fetching: true
  };
  if (query.page) newState.page = query.page;
  if (query.page_size) newState.page_size = query.page_size;
  if (query.ordering) newState.ordering = query.ordering;
  newState.search = query.q || '';
  return state.merge(newState)
}

export const ordersSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

// export const ordersChangeStatus = (state, { data }) => {

//  const newData = {...state.list};
//   const newList = newData.results.map(el => {
//     let internal_status_obj = null;
//     if (el.id === data.order_id) {
//       internal_status_obj = { id: data.status, name: data.name}
//       return ({...el, internal_status_obj});
//     }
//     return el
//   });
//   return state.merge({ list: {...state.list, results: newList } })
// }

// export const orderRequest = (state, { orderId }) => {
//   return state.merge({ fetching: true })
// }

// export const orderSuccess = (state, { order }) => {
//   return state.merge({ fetching: false, order })
// }

// export const orderReset = (state) => {
//   return state.merge({ order: null })
// }

export const orderSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const orderSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const saveCommentRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const saveCommentSuccess = (state, { saveCommentResult }) => {
  return state.merge({ saveFetching: false, saveCommentResult })
}

// export const orderDeleteRequest = (state, { orderId }) => {
//   return state.merge({ deleteFetching: true })
// }

// export const orderDeleteSuccess = (state, { deleteResult }) => {
//   return state.merge({ deleteFetching: false, deleteResult })
// }

// export const setOrderId = (state, { orderId }) => {
//   return state.merge({ orderId })
// }

export const internalStatusSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const internalStatusSaveSuccess = (state, { saveStatusResult }) => {
  return state.merge({ saveFetching: false, saveStatusResult })
}

export const orderFiltersRequest = (state) => {
  return state.merge({ fetching: true })
}

export const orderFiltersSuccess = (state, { filters }) => {
  return state.merge({ fetching: false, filters })
}

export const avListRequest = (state) => {
  return state.merge({ fetching: true })
}

export const avListSuccess = (state, { avList }) => {
  return state.merge({
    fetching: false, 
    // avList: [...avList, {
    //   id: 'n/a',
    //   first_name: 'N/A',
    //   last_name: ''
    // }]
    avList
  })
}

export const assignedSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const assignedSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const shippingServicesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const shippingServicesSuccess = (state, { shippingServices }) => {
  return state.merge({ fetching: false, shippingServices })
}

export const getCommentsRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const getCommentsSuccess = (state, { comments }) => {
  return state.merge({ fetching: false, comments })
}

export const setTableOrder = (state, { columnOrder }) => {
  return state.merge({ columnOrder })
}

export const orderTableSettingsReset = (state) => {
  const newState = {
    page: 1,
    ordering: '',
    search: '',
  };
  return state.merge(newState)
}

export const ordersReset = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    deleteResult: null,
    saveStatusResult: null,
    saveCommentResult: null,
  })
}

export const ordersFetchingReset = (state) => {
  return state.merge({
    fetching: false,
    saveFetching: false,
    deleteFetching: false,
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ORDERS_REQUEST]: ordersRequest,
  [Types.ORDERS_SUCCESS]: ordersSuccess,

  // [Types.ORDER_REQUEST]: orderRequest,
  // [Types.ORDER_SUCCESS]: orderSuccess,
  // [Types.ORDER_RESET]: orderReset,

  [Types.ORDER_SAVE_REQUEST]: orderSaveRequest,
  [Types.ORDER_SAVE_SUCCESS]: orderSaveSuccess,

  // [Types.ORDER_DELETE_REQUEST]: orderDeleteRequest,
  // [Types.ORDER_DELETE_SUCCESS]: orderDeleteSuccess,

  // [Types.SET_ORDER_ID]: setOrderId,

  [Types.INTERNAL_STATUS_SAVE_REQUEST]: internalStatusSaveRequest,
  [Types.INTERNAL_STATUS_SAVE_SUCCESS]: internalStatusSaveSuccess,

  [Types.ORDER_FILTERS_REQUEST]: orderFiltersRequest,
  [Types.ORDER_FILTERS_SUCCESS]: orderFiltersSuccess,

  [Types.AV_LIST_REQUEST]: avListRequest,
  [Types.AV_LIST_SUCCESS]: avListSuccess,

  [Types.ASSIGNED_SAVE_REQUEST]: assignedSaveRequest,
  [Types.ASSIGNED_SAVE_SUCCESS]: assignedSaveSuccess,

  [Types.SAVE_COMMENT_REQUEST]: saveCommentRequest,
  [Types.SAVE_COMMENT_SUCCESS]: saveCommentSuccess,

  [Types.GET_COMMENTS_REQUEST]: getCommentsRequest,
  [Types.GET_COMMENTS_SUCCESS]: getCommentsSuccess,

  [Types.SHIPPING_SERVICES_REQUEST]: shippingServicesRequest,
  [Types.SHIPPING_SERVICES_SUCCESS]: shippingServicesSuccess,

  [Types.SET_TABLE_ORDER]: setTableOrder,
  
  // [Types.ORDERS_CHANGE_STATUS]: ordersChangeStatus,

  [Types.ORDER_TABLE_SETTINGS_RESET]: orderTableSettingsReset,

  [Types.ORDERS_RESET]: ordersReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.ORDERS_FETCHING_RESET]: ordersFetchingReset,
})
