import React, { useState } from 'react'
import { Form, Input, InputNumber, Select, Radio, Row, Col } from 'antd'
import _ from 'lodash'

import './style.less'

const reqFields = ['platform', 'source_url', 'item_id', 'title'];

const ProductCreateForm = props => {
  const { shops, suppliers, onSubmit } = props;

  const [type, setType] = useState('asin');

  const onFinish = values => {
    let data = { ...values };
    const supplier = _.pick(data, reqFields);
    data = _.omit(data, reqFields);
    data.supplier = supplier;
    onSubmit(data);
  }

  return (
    <Form id="product-create-form" className="custom-modal-form" onFinish={onFinish} layout="vertical" requiredMark={false}>
      <Row gutter={16}>
        <Col span={24}>
          <div className="radio-group">
            <Radio.Group onChange={e => setType(e.target.value)} defaultValue={type}>
              <Radio value='asin'>ASIN</Radio>
              <Radio value='upc' disabled>UPC</Radio>
            </Radio.Group>
          </div>
        </Col>
        <Col span={24}>
          {
            type === 'asin' ?
              <Form.Item
                name="asin"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </Form.Item>
              :
              <Form.Item
                name="upc"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </Form.Item>
          }
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="SKU"
            name="sku"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Marketplaces"
            name="seller_shops"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch={false}
            >
              {
                shops?.map(el => (
                  <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Initial selling price"
            name="price"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Quantity"
            name="quantity"
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'integer', message: 'Please input an integer number' }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Handling time"
            name="handling_time"
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'integer', message: 'Please input an integer number' }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Supplier"
            name="platform"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select>
              {
                suppliers?.map(el => (
                  <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Source URL"
            name="source_url"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Item ID"
            name="item_id"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ProductCreateForm
