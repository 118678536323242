const UploadIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5.0002 9.53542L5 9.5C5 7.84315 6.34315 6.5 8 6.5C8.38642 6.5 8.75578 6.57306 9.09501 6.70612C10.0981 5.36675 11.6979 4.5 13.5 4.5C16.3802 4.5 18.7433 6.71385 18.9804 9.5327C20.6861 9.76685 22 11.23 22 13C22 14.933 20.433 16.5 18.5 16.5H12.5V10.7071L15.1464 13.3536C15.3417 13.5488 15.6583 13.5488 15.8536 13.3536C16.0488 13.1583 16.0488 12.8417 15.8536 12.6464L12.3536 9.14645C12.1583 8.95118 11.8417 8.95118 11.6464 9.14645L8.14645 12.6464C7.95118 12.8417 7.95118 13.1583 8.14645 13.3536C8.34171 13.5488 8.65829 13.5488 8.85355 13.3536L11.5 10.7071V16.5H5.5C3.567 16.5 2 14.933 2 13C2 11.2367 3.30396 9.77794 5.0002 9.53542ZM11.5 16.5H12.5L12.5 19.5C12.5 19.7761 12.2761 20 12 20C11.7239 20 11.5 19.7761 11.5 19.5L11.5 16.5Z"
        fill={isActive ? "#FFFFFF" : "#262F56"} />
    </svg>

  );
};

export default UploadIcon;
