/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Table, Input } from 'antd'
import { useHistory } from 'react-router-dom'

import SearchIcon from '../../Components/Icons/Search'
import PaginationComponent from '../../Components/PaginationComponent'
import TableOptionsActions from '../../Components/TableOptionsActions'
import UsersActions from '../../Redux/UsersRedux'
import UserForm from './components/UserForm'

const { Search } = Input;

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
};

const UsersPage = props => {
  const { users, selectedUser, permissions } = props;

  const history = useHistory();

  const [start, setStart] = useState(false);
  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [paginate, setPaginate] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    if (!start) {
      setPaginate({
        page: 1,
        page_size: users.page_size
      });
      setStart(true);
    }
    props.getUsersRoles();
  }, [0])

  const handleQuery = page => {
    const query = {
      q: searchQuery,
      ordering: sortField,
      page: page ? page : paginate.page,
      page_size: paginate.page_size,
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getUsers(query);
  }

  useEffect(() => {
    if (start) {
      handleQuery();
    }
  }, [paginate, sortField])

  useEffect(() => {
    if (start) {
      handleQuery(1);
    }
  }, [searchQuery])

  useEffect(() => {
    if (users.list) {
      setData(users.list.results)
    }
  }, [users.list])

  useEffect(() => {
    if (selectedUser) {
      const data = {
        email: selectedUser.email,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        user_role: selectedUser.user_role?.id,
      }
      setFormValues(data);
    }
  }, [selectedUser])

  useEffect(() => {
    if (users.saveResult || users.deleteResult || users.blockResult) {
      setModalVisible(false);
      setFormValues(null);
    }
  }, [users.saveResult, users.deleteResult, users.blockResult])

  const pageSizeHandler = value => {
    setPaginate({
      page: 1,
      page_size: value
    });
  }

  // const handleTableSort = ({ order, field }) => {
  //   if (order && field) {
  //     const ordering = order === 'ascend' ? `${field}` : `-${field}`
  //     setSortField(ordering)
  //   } else setSortField(null)
  // }

  const onPaginate = (page, page_size) => {
    setPaginate({ page, page_size });
  }

  const saveUser = () => {
    setModalType('create');
    setModalVisible(true);
  }

  const editUser = record => {
    props.getUser(record.id);
    setModalType('edit');
    setModalVisible(true);
  }

  const handleSearch = value => {
    let val = value.trim().toLowerCase();
    if (val !== '') setSearchQuery(value)
    else setSearchQuery(null)
  }

  const onSubmit = data => {
    if (modalType === 'edit') {
      props.saveUser({ ...data, id: selectedUser?.id });
      setFormValues(null);
    } else {
      props.saveUser(data);
    }
  }

  const ModalContent = {
    create: {
      title: 'Create new user',
      children: () => (
        <UserForm
          roles={users.roles}
          onSubmit={onSubmit}
        />
      ),
      okButtonProps: {
        ...okButtonProps,
        htmlType: 'submit',
        form: 'user-form'
      }
    },
    edit: {
      title: `Edit User: ${selectedUser?.first_name} ${selectedUser?.last_name}`,
      children: () => (
        <UserForm
          roles={users.roles}
          initial={formValues}
          onSubmit={onSubmit}
        />
      ),
      okButtonProps: {
        ...okButtonProps,
        htmlType: 'submit',
        form: 'user-form'
      }
    },
    delete: {
      title: `Are you sure?`,
      children: () => (
        <div>
          Are you sure you want to delete this user?
          <p>Actions can not be reverted!</p>
        </div>
      ),
      okButtonProps,
      cb: () => {
        props.deleteUser(selectedUser?.id)
      },
    },
    block: {
      title: `Are you sure?`,
      children: () => (
        <div>
          Are you sure you want to block this user?
          <p>You can unblock the user anytime!</p>
        </div>
      ),
      okButtonProps,
      cb: () => {
        props.markAsDeletedUser(selectedUser?.id)
      },
    },
    unblock: {
      title: `Are you sure?`,
      children: () => (
        <div>
          Are you sure you want to unblock this user?
        </div>
      ),
      okButtonProps,
      cb: () => {
        props.unmarkAsDeletedUser(selectedUser?.id)
      }
    }
  }

  const tableColumns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      align: 'center',
      // sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      align: 'center',
      // sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      // sorter: true,
    },
    {
      title: 'Role',
      dataIndex: ['user_role', 'name'],
      align: 'center',
    },
    {
      title: 'Email confirmation',
      dataIndex: 'is_active',
      align: 'center',
      render: text => text ? <span>Active</span> : <span>Inactive</span>,
    },
    {
      title: 'Onboarding',
      dataIndex: 'onboarding_finished',
      align: 'center',
      render: text => text ? <span>Finished</span> : <span>Unfinished</span>,
    },
    {
      title: 'Status',
      dataIndex: 'is_deleted',
      align: 'center',
      render: text => text ? <span>Blocked</span> : <span>Unblocked</span>,
    },
    {
      key: 'action',
      render: (text, record) => (
        <div className="table-action">
          {permissions.edit_user && <Button
            className="btn-primary white"
            style={{ margin: '0 4px' }}
            onClick={() => editUser(record)}
          >
            Edit
          </Button>}
          {permissions.block_user && <Button
            className="btn-primary orange"
            style={{ margin: '0 4px' }}
            onClick={() => {
              props.getUser(record.id);
              setModalType(record.is_deleted ? 'unblock' : 'block');
              setModalVisible(true);
            }}
          >
            {record.is_deleted ? 'Unblock' : 'Block'}
          </Button>}
          {permissions.delete_user && <Button
            className="btn-primary red"
            style={{ margin: '0 4px' }}
            onClick={() => {
              props.getUser(record.id);
              setModalType('delete');
              setModalVisible(true);
            }}
          >
            Delete
          </Button>}
        </div>
      ),
    },
  ]

  return (
    <div className="content-page">
      <div className="table-options">
        < div className="table-options-inputs" >
          <Search
            style={{ marginRight: 0 }}
            placeholder="Search"
            className="search-input"
            allowClear
            onSearch={handleSearch}
            suffix={<SearchIcon />}
          />
          < div className="header-link-divider" />
          {permissions.create_user && <Button
            className="btn-primary"
            onClick={saveUser}
          >
            Create user
          </Button>}
          < div className="header-link-divider" />
          {permissions.role === 'Admin' && <Button
            className="btn-primary white"
            onClick={()=> history.push(`/home/permissions`)}
          >
            Permissions
          </Button>}
        </div >
        <TableOptionsActions
          defaultValue={users.page_size}
          pageSizeHandler={pageSizeHandler}
        />
      </div >
      <Table
        className="table"
        rowKey={row => row.id}
        dataSource={data}
        columns={tableColumns}
        scrollToFirstRowOnChange
        pagination={false}
        loading={users.fetching}
        bordered
      />
      <PaginationComponent
        onChange={(page, page_size) => onPaginate(page, page_size)}
        total={users.list?.count}
        current={users.page}
        pageSize={users.page_size}
      />
      <Modal
        wrapClassName="modal-wrap"
        className="modal"
        width={400}
        title={ModalContent[modalType]?.title}
        visible={modalVisible}
        children={ModalContent[modalType]?.children()}
        handleClose={() => {
          setModalVisible(false);
          setFormValues(null);
        }}
        onOk={ModalContent[modalType]?.cb}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          setFormValues(null);
        }}
        cancelText="Cancel"
        okButtonProps={ModalContent[modalType]?.okButtonProps}
        cancelButtonProps={cancelButtonProps}
        destroyOnClose={true}
      />
    </div >
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  role: state.auth.role,
  users: state.users,
  selectedUser: state.users.selectedUser,
  permissions: state.auth.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getUsers: (query) => dispatch(UsersActions.usersRequest(query)),
  getUsersRoles: () => dispatch(UsersActions.userRolesRequest()),
  getUser: (id) => dispatch(UsersActions.userRequest(id)),
  saveUser: (data) => dispatch(UsersActions.userSaveRequest(data)),
  deleteUser: (id) => dispatch(UsersActions.userDeleteRequest(id)),
  markAsDeletedUser: (id) => dispatch(UsersActions.userBlockRequest(id)),
  unmarkAsDeletedUser: (id) => dispatch(UsersActions.userUnblockRequest(id)),

})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
