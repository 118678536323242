/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import LoginForm from './components/LoginForm'
import Loader from '../../Components/Loader'
import AuthActions from '../../Redux/AuthRedux'
import errorHandler from '../../Utils/errorHandler'

import './style.less'

const LoginPage = props => {
  const { error, fetching } = props;

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error])

  const onSubmit = data => {
    props.signIn(data.email.trim(), data.password.trim());
  }

  const onSubmitFail = (values) => console.log(values)


  return (
    <div className="login-page__wrapper">
      <div className="login-page__image" />
      < div className="login-page__form" >
        <div className="login-page__form__wrapper">
          <div className="login-page--logo" />
          <h3 className="login-page--title">Login</h3>
          <LoginForm
            onFinish={onSubmit}
            onFinishFailed={onSubmitFail}
          />
          <p className="login-page__forgot-password">
            Forgot password?
          </p>
        </div>
      </div >
      {fetching && <Loader />}
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password, pathname, hash) => dispatch(AuthActions.signInRequest(email, password, pathname, hash)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
