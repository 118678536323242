import React from 'react'
import { Form, Input, Select, Row, Col } from 'antd'

const { TextArea } = Input;

const ProductSupplierForm = (props) => {
  const { initial, suppliers, onSubmit } = props

  const onFinish = values => {
    const data = { ...values };
    onSubmit(data);
  }

  return (
    <Form id="product-supplier-form" className="custom-modal-form" onFinish={onFinish} layout="vertical" requiredMark={false} initialValues={initial}>
      <Row gutter={16}>
        {initial ?
          <Col span={24}>
            <Form.Item
              label="Source URL"
              name="source_url"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 12 }} />
            </Form.Item>
          </Col>
          :
          <>
            <Col span={24}>
              <Form.Item
                label="Supplier"
                name="platform"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Select>
                  {
                    suppliers?.map(el => (
                      <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Source URL"
                name="source_url"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Item ID"
                name="item_id"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </>
        }
      </Row>
    </Form>
  );
};

export default ProductSupplierForm;
