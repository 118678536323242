import { call, put } from 'redux-saga/effects'
import OrdersActions from '../Redux/OrdersRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getOrders(api, authApi, { query }) {
  try {
    const response = yield call(api.getOrders, query)
    if (response && response.ok) {
      yield put(OrdersActions.ordersSuccess(response.data))
    } else {
      if (response.status === 404) {
        yield put(OrdersActions.ordersRequest({...query, page: query.page > 1 ? query.page -1 : null}))
      } else {
        yield* handleSagasError(api, authApi, response, getOrders, { query }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
      }
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveOrder(api, authApi, { data }) {
  try {
    const saveFunc = data.id ? api.updateOrder : api.createOrder;
    const response = yield call(saveFunc, data)
    if (response && response.ok) {
      yield put(OrdersActions.orderSaveSuccess(response.data))
      yield put(OrdersActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, saveOrder, { data }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* updateInternalStatus(api, authApi, { data }) {
  try {
    const response = yield call(api.updateInternalStatus, data)
    if (response && response.ok) {
      yield put(OrdersActions.internalStatusSaveSuccess(response.data))
      yield put(OrdersActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, updateInternalStatus, { data }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getOrderFilters(api, authApi) {
  try {
    const response = yield call(api.getOrderFilters)
    if (response && response.ok) {
      yield put(OrdersActions.orderFiltersSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getOrderFilters, null, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }

  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getAvList(api, authApi) {
  try {
    const response = yield call(api.getAvList)
    if (response && response.ok) {
      yield put(OrdersActions.avListSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getAvList, null, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }

  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* changeAssigned(api, authApi, { data }) {
  try {
    const response = yield call(api.changeAssigned, data)
    if (response && response.ok) {
      yield put(OrdersActions.assignedSaveSuccess(response.data))
      yield put(OrdersActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, changeAssigned, { data }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getShippingServices(api, authApi) {
  try {
    const response = yield call(api.getShippingServices)
    if (response && response.ok) {
      yield put(OrdersActions.shippingServicesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getShippingServices, null, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }

  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveComment(api, authApi, { data }) {
  try {
    const response = yield call(api.saveComment, data)
    if (response && response.ok) {
      yield put(OrdersActions.saveCommentSuccess(response.data))
      yield put(OrdersActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, saveComment, { data }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getComments(api, authApi, { query }) {
  try {
    const response = yield call(api.getComments, query)
    if (response && response.ok) {
      yield put(OrdersActions.getCommentsSuccess(response.data))
      yield put(OrdersActions.resultsReset())
    } else {
      yield* handleSagasError(api, authApi, response, getComments, { query }, ErrorsActions.errorSave, OrdersActions.ordersFetchingReset)
    }
  } catch (e) {
    yield put(OrdersActions.ordersFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
