/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Upload, Table, Button } from 'antd'
import moment from 'moment'

import UploadIcon from '../../../../Components/Icons/Upload'
import DownloadIcon from '../../../../Components/Icons/Download'

import './style.less'


const ProductUploadComponent = props => {
  const { uploadList, uploadResult, getUploadProductData, changeUploadData, fileListData, setFileListData } = props;

  const [data, setData] = useState([]);
  const [active, setActive] = useState(false);
  // const [fileListData, setFileListData] = useState([]);

  const scroll = document?.documentElement?.clientHeight ? document.documentElement.clientHeight - 399 : 300;

  useEffect(() => {
    changeUploadData(null);
    getUploadProductData();
  }, [0])

  useEffect(() => {
    let arr = uploadList ? uploadList.asMutable({ deep: true }) : [];
    arr = arr.map((el, i) => ({
      id: el.id,
      number: i + 1,
      name: el.file_name,
      url: el.file_url,
      date: el.created,
      quantity: el.quantity,
      errors: el.errors,
      status: el.status
    }))
    setData(arr);
  }, [uploadList])

  useEffect(() => {
    if (uploadResult) {
      setFileListData([]);
    }
  }, [uploadResult])

  const tableColumns = [
    {
      title: '#',
      dataIndex: 'number',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) =>
        <a href={record.url} target="_blank" rel="noreferrer"
          className="ant-table-cell ant-table-cell--underline ant-table-cell--link"
        >
          {text}
        </a>
    },
    {
      title: 'Upload date',
      dataIndex: 'date',
      render: text =>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{text && moment.utc(text).format('MM/DD/YYYY')}</span>
          <span>{text && moment.utc(text).format('LT').toLowerCase()}</span>
        </div>
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Errors',
      dataIndex: 'errors',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const onChange = ({ file, fileList }) => {
    let formData = new FormData();
    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    setFileListData(fileList);
    changeUploadData(formData);
  }

  const fakeRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 500);
  };

  return (
    <div className="product-upload">
      <div className="btn-panel">
        <Upload
          accept=".csv"
          // method="get"
          maxCount={1}
          fileList={fileListData}
          onChange={onChange}
          customRequest={fakeRequest}
        >
          <Button
            className="btn-primary white icon"
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
            <UploadIcon isActive={active} />
            <span style={{ color: active ? '#FFF' : '#232D53' }}>
              Upload file
              </span>
          </Button>
        </Upload>
        <a href={`${process.env.REACT_APP_BASE_API_ROUTE}/media/example/example.csv`} target="_blank" rel="noreferrer">
        {/* <a href="https://oms.edropshipempireservices.com/api/media/example/example.csv" target="_blank" rel="noreferrer"> */}
          <Button
            className="btn-primary orange icon"
          >
            <DownloadIcon />
            <span>
              Download template
            </span>
          </Button>
        </a>
      </div>
      <Table
        className="table"
        rowKey={row => row.id}
        dataSource={data}
        columns={tableColumns}
        scroll={{ y: scroll }}
        pagination={false}
        loading={false}
        bordered
      />
    </div>
  );
}

export default ProductUploadComponent
