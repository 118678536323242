import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import AuthActions from '../../Redux/AuthRedux'

export function* handleSagasError(api, authApi, response, fn, argsObj, errFn, resetFn) {
  if (response && response.data) {
    if (response.status === 401) {
      yield put(errFn(null))
      const res = yield call(authApi.tokenRefresh)
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh))
        yield* fn(api, authApi, argsObj)
      } else {
        yield put(AuthActions.logout())
        yield put(push('/login'))
      }
    } else if (response.status === 500) {
      yield put(errFn("Server response error"))
    } else {
      yield put(errFn(response.data))
    }
  } else {
    yield put(errFn(response?.originalError?.message || "INTERNET DISCONNECTED"))
  }
  yield put(resetFn())
}
