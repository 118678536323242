import React from 'react'
import { Spin } from 'antd'

import './style.less'

export default function StartupPage() {
  return (
    <Spin spinning={true} size="large">
      <div className="page-full-wrapper" />
    </Spin>
  )
}
