/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Form, Input, InputNumber, DatePicker, TimePicker, Select, Row, Col, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'

import OrdersActions from '../../../../Redux/OrdersRedux'

import './style.less'
import Bucket from '../../../../Components/Icons/Bucket'

const reqFields = ['supplier_source_url', 'product_sku', 'internal_status', 'yra', 'sra', 'supplier_platform', 'status', 'order_id', 
'tracking_number', 'tracking_link', 'carrier_tracking_link', 'customer_name', 'ship_to', 'quantity', 'tax', 'subtotal', 'shipping_price', 'coupon_savings', 'carrier', 'delivery_status', 
'service', 'shipping_date_manual', 'shipping_time_manual', 'joint', 'jointSupplier', 'tracking_info', 'refund_shipping_cost'];

// const trackInfo = ['tracking_id', 'carrier', 'delivery_status', 'service', 'shipping_date_manual', 'shipping_time_manual'];

const supplierStatus = ['in_progress', 'shipped', 'canceled'];

const OrderForm = props => {
  const { data, suppliers, statuses, avList, shippingServices, onSubmit, setClose, getShippingServices, permissions, comments, saveCommentResult } = props;

  const [form] = Form.useForm();
  const [active, setActive] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [newCommentData, setNewCommentData] = useState(null);
  const [commentsData, setCommentsData] = useState(null);

  const getComments = () => {
    let query = { order: data?.order_id_ }
    props.getComments(query);
  }

  useEffect(() => {
    getShippingServices();
    getComments();
  }, [0])

  useEffect(() => {
    if (comments) {
      setCommentsData(comments);
    } 
  }, [comments])

  useEffect(() => {
    if (saveCommentResult) {
      // setCommentsData(null);
      setNewCommentData(null);
      getComments();
    } 
  }, [saveCommentResult])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      let newData = [];
      data.joint.forEach((el, i) => {
        if (el.internal_status === 'refunded') {
          newData[i] = true;
        }
      })
      setActive(newData);
      newData = [];
      data.jointSupplier.forEach((el, i) => {
        // if (el.carrier && shippingServices) {
        //   newData[i] = shippingServices[el.carrier];
        // }
        el.tracking_info.forEach((treck)=> {
          if (treck.carrier && shippingServices) {
            newData[i] = shippingServices[(treck.carrier === 'FEDEX' ? 'FedEx' : treck.carrier)]
          }
        })
      })
      setServiceList(newData);
      if (data.assigned == null) {
        form.setFieldsValue({ assigned: 'n/a' });
      }
    }
    if (shippingServices) {
      setCarrierList(Object.keys(shippingServices));
    }
  }, [data, shippingServices])

  const onFinish = (values, isLocalUpdate) => {
    let res = { ...values };
    // console.log('res', res)
    // data.jointSupplier.forEach((el, i) => {
    //   if (el.tracking_info) {
    //     el.tracking_info.forEach((el,i) => {
    //       if (el.shipping_date_manual) {
    //         el.shipping_date_manual = el.shipping_date_manual.format('YYYY-MM-DD');
    //       }
    //       if (el.shipping_time_manual) {
    //         el.shipping_time_manual = el.shipping_time_manual.format('HH:mm');
    //       }
    //     })
    //   }
    // });
    const products = [];
    res.joint.forEach(el => {
      products.push(_.pick(el, reqFields));
    });
    res.jointSupplier.forEach((el, i) => {

      let obj = _.pick(el, reqFields);
      products[i] = {
        ...products[i],
        ...obj,
        id: data.id[i],
        ship_to: el.ship_to?.includes(`${el.customer_name}, `) ? el.ship_to.replace(`${el.customer_name}, `, '') : el.ship_to,
        comment: res.comment,
        assigned_to: res.assigned,
        // net_margin: res.net_margin,
        tracking_info: el.tracking_info
        .map((el => {
          // let shipping_date_manual = null
          // let shipping_time_manual = null
          // if (el.shipping_date_manual) {
          //   shipping_date_manual = el.shipping_date_manual.format('YYYY-MM-DD')
          // }
          // if (el.shipping_time_manual) {
          //   shipping_time_manual = el.shipping_time_manual.format('HH:mm:ss')
          // }
          //   return ({...el, shipping_date_manual, shipping_time_manual})
          let shipping_date = null
          let shipping_time = null
          if (el.shipping_date) {
            shipping_date = el.shipping_date.format('YYYY-MM-DD')
          }
          if (el.shipping_time) {
            shipping_time = el.shipping_time.format('HH:mm:ss')
          }
          return ({...el, shipping_date, shipping_time})
        }))
      }
    });

    products.forEach((product, i) => {
      // product.tracking_info = {};
      for (let key in product) {
        if (product[key] === '' || product[key] == null) {
          product[key] = null;
        }
      }
      // product.tracking_info_t.forEach((t_info, i) =>{
      //   t_info.tracking_info_t[i] = {};
      //   // console.log(t_info)
      //   for (let key in t_info) {
      //     if (trackInfo.includes(key)) {
      //       // console.log(key)
      //       product.tracking_info_t[i][key] = t_info[key];
      //       delete t_info[key];
      //     }
      //   }
      // })
    });
    // console.log('products', products)
    setClose(!isLocalUpdate); //if is local update true - setclose is false 
    products.forEach(product => {
      onSubmit(product);
    })
  }

  const onStatusChange = (value, index) => {
    const newData = [...active];
    if (value === 'refunded') {
      newData[index] = true;
      setActive(newData);
    } else {
      newData[index] = false;
      setActive(newData);
    }
    onFinish(form.getFieldsValue(), true);    
  }

  const onAssignedChange = assigned => {
    onFinish(form.getFieldsValue(), true);  
    // setClose(false);
    // changeAssigned({
    //   order_id: data.order_id_,
    //   assigned
    // });
  }

  const onRefundedFieldChange = (field, value, index) => {
    if (data.joint[index][field] === value) {
      return;
    } else {
      onFinish(form.getFieldsValue(), true);
    }
  }

  // const onSupplierFieldChange = (field, value, index) => {
  //   if (data.jointSupplier[index][field] === value) {
  //     return;
  //   } else {
  //     onFinish(form.getFieldsValue(), true);
  //   }
  // }

  const onCarrierChange = (value, index) => {
    const list = [...serviceList];
    const newData = form.getFieldsValue();
    if (data.jointSupplier[index].carrier === value) {
      newData.jointSupplier[index].service = data.jointSupplier[index].service;
    } else {
      newData.jointSupplier[index].service = null;
    }
    form.setFieldsValue(newData);
    list[index] = shippingServices[value];
    setServiceList(list);
  }

  const removeItem = (index) => {
    const newServiceList = [...serviceList];
    // const newData = form.getFieldsValue();
    newServiceList.splice(index, 1);
    setServiceList(newServiceList);
  }
  // console.log(form.getFieldsValue().jointSupplier[fieldName - 1].tracking_info[i].tracking_info_id)

  const addItem = () => {
    const newServiceList = [...serviceList];
    newServiceList.push([]);
    setServiceList(newServiceList);
  }

  const onFieldChange = (field, value) => {
    onFinish(form.getFieldsValue(), true);
  }

  return (
    <>
    <Form id="supplier-order-form" className="custom-modal-form" form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
      <div className="top">
        <div className="img-block">
          {
            data?.ava?.map((el, i) => (
              <div className="ava" key={i}>
                {
                  el ?
                    <img src={el} alt={el} />
                    :
                    <div className="no-image" />
                }
              </div>
            ))
          }
        </div>
        <div className="top-form">
          <Row gutter={16}>
            <Col span={3}>
              <Form.Item
                label="Net profit"
                name="net_profit"
              >
                <InputNumber step="0.00" disabled />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Net revenue"
                name="net_revenue"
              >
                <InputNumber step="0.00" disabled />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Net margin"
                name="net_margin"
              >
                <InputNumber step="0.00" disabled />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Amazon fee"
                name="amazon_fee"
              >
                <InputNumber step="0.00" disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Date"
                name="date"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Order placed by/ Assigned to"
                name="assigned"
              >
                <Select 
                  onChange={onAssignedChange}
                  disabled={!permissions?.orders_card_edit}
                >
                  {
                    avList?.map(el => (
                      <Select.Option key={el.id} value={el.id}>{`${el.first_name} ${el.last_name}`}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="joint">
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }, i) => (
                  <Row gutter={16} key={key}>   
                    <div style={{ width: '100%'}}>
                      {
                        data?.ava?.length > 1 ?
                          <img src={data.ava[i]} alt={data.ava[i]} style={{ width: 20}}/>
                          :
                          null
                      }
                    </div>
                    <Col span={5}>
                      <Form.Item
                        label="ASIN"
                        {...restField}
                        name={[name, 'asin']}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="SKU"
                        {...restField}
                        name={[name, 'product_sku']}
                      >
                        <Input disabled={!permissions?.orders_card_edit} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        label="Source URL"
                        {...restField}
                        name={[name, 'supplier_source_url']}
                      >
                        <Input title={data?.joint[i]?.supplier_source_url} disabled={!permissions?.orders_card_edit}/>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        label="Title"
                        {...restField}
                        name={[name, 'title']}
                      >
                        <Input disabled title={data?.joint[i]?.title} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label="Internal status"
                        {...restField}
                        name={[name, 'internal_status']}
                      >
                        <Select 
                          onChange={e => onStatusChange(e, i)}
                          disabled={!permissions?.orders_card_edit}
                        >
                          {
                            statuses?.map(el => (
                              <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Refund admin fee"
                        {...restField}
                        name={[name, 'refund_admin_fee']}
                      >
                        <InputNumber
                          step="0.00"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Refund shipping cost"
                        {...restField}
                        name={[name, 'refund_shipping_cost']}
                      >
                        <InputNumber
                          step="0.00"
                          disabled={!active[i] || !permissions?.orders_card_edit}
                          onBlur={e => onRefundedFieldChange('refund_shipping_cost', e.target.value, i)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="You Refunded the Buyer Amount"
                        {...restField}
                        name={[name, 'yra']}
                      >
                        <InputNumber
                          step="0.00"
                          disabled={!active[i] || !permissions?.orders_card_edit}
                          onBlur={e => onRefundedFieldChange('yra', e.target.value, i)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Supplier Refunded Your Amount"
                        {...restField}
                        name={[name, 'sra']}
                      >
                        <InputNumber
                          step="0.00"
                          disabled={!active[i] || !permissions?.orders_card_edit}
                          onBlur={e => onRefundedFieldChange('sra', e.target.value, i)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </div>
      </div>

      <div className="order-info-block">
        <div className="title">Amazon Order Information:</div>
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item
              label="Order ID"
              name="order_id_"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Paid"
              name="paid"
            >
              <Select disabled>
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Buyer name"
              name="buyer_name"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Buyer email"
              name="buyer_email"
            >
              <Input disabled title={data?.buyer_email} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label="Marketplace"
              name="marketplace"
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label="Buyer paid"
              name="total_price"
            >
              <InputNumber step="0.00" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Total tax"
              name="total_tax"
            >
              <InputNumber step="0.00" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Item sale price"
              name="item_sale_price"
            >
              <InputNumber step="0.00" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Adjustment amount"
              name="adjustment_amount"
            >
              <InputNumber step="0.00" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Total shipping"
              name="total_shipping"
            >
              <InputNumber step="0.00" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Qty"
              name="quantity_"
            >
              <InputNumber disabled />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="jointAmazon">
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, ...restField }, i) => (
                <Row gutter={16} key={key}>
                <div style={{ width: '100%'}}>
                    {
                      data?.ava?.length > 1 ?
                        <img src={data.ava[i]} alt={data.ava[i]} style={{ width: 20}}/>
                        :
                        null
                    }
                  </div>
                  <Col span={4}>
                    <Form.Item
                      label="Status"
                      {...restField}
                      name={[name, 'status_']}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Ship to"
                      {...restField}
                      name={[name, 'ship_to_']}
                    >
                      <Input disabled title={data?.jointAmazon[i]?.ship_to_} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Estimated date delivery"
                      {...restField}
                      name={[name, 'delivery_estimated_date']}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Latest ship date"
                      {...restField}
                      name={[name, 'latest_ship_date']}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </>
          )
          }
        </Form.List>
      </div>
      <Form.List name="jointSupplier">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }, i) => (
              <div className="order-info-block" key={key}>
                <div className="title">
                  <span style={{marginRight: 10 }}>
                    {
                      data?.ava?.length > 1 ?
                        <img src={data.ava[i]} alt={data.ava[i]} style={{ width: 20}}/>
                        :
                        null
                    }
                  </span>
                  Supplier Order Information:
                </div>
                <Row gutter={16}>
                      <Col span={8}>
                      <Form.Item
                          label="Supplier"
                          {...restField}
                          name={[name, 'supplier_platform']}
                        >
                          <Select disabled={!permissions?.orders_card_edit}>
                            {
                              suppliers?.map(el => (
                                <Select.Option key={el.id} value={el.name}>{el.name}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Status (supplier)"
                          // name="status"
                          {...restField}
                          name={[name, 'status']}
                        >
                          <Select disabled={!permissions?.orders_card_edit}>
                            {
                              supplierStatus?.map(el => (
                                <Select.Option key={el} value={el}>{el}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Order ID (supplier)"
                          {...restField}
                          name={[name, 'order_id']}
                        >
                          <Input disabled={!permissions?.orders_card_edit} />
                        </Form.Item>
                      </Col>
            
                      <Col span={8}>
                        <Form.Item
                          label="Order Cost"
                          {...restField}
                          name={[name, 'subtotal']}
                        >
                          <InputNumber
                            step="0.00"
                            onBlur={e => onFieldChange('subtotal', e.target.value)}
                            disabled={!permissions?.orders_card_edit}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Tax"
                          {...restField}
                          name={[name, 'tax']}
                        >
                          <InputNumber
                            step="0.00"
                            onBlur={e => onFieldChange('tax', e.target.value)}
                            disabled={!permissions?.orders_card_edit}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Shipping"
                          {...restField}
                          name={[name, 'shipping_price']}
                        >
                          <InputNumber
                            step="0.00"
                            onBlur={e => onFieldChange('shipping_price', e.target.value)}
                            disabled={!permissions?.orders_card_edit}
                          />
                        </Form.Item>
                      </Col>
            
                      <Col span={8}>
                        <Form.Item
                          label="Coupon Savings"
                          {...restField}
                          name={[name, 'coupon_savings']}
                        >
                          <InputNumber
                            step="0.00"
                            onBlur={e => onFieldChange('coupon_savings', e.target.value)}
                            disabled={!permissions?.orders_card_edit}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Purchase cost"
                          {...restField}
                          name={[name, 'purchase_cost']}
                        >
                          <InputNumber step="0.00" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Qty"
                          {...restField}
                          name={[name, 'quantity']}
                        >
                          <InputNumber disabled={!permissions?.orders_card_edit} />
                        </Form.Item>
                      </Col>
            
                      {/* <Col span={12}>
                        <Form.Item
                          label="Tracking link"
                          {...restField}
                          name={[name, 'carrier_tracking_link']}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                      <Col span={12}>
                        <Form.Item
                          label="Email link"
                          {...restField}
                          name={[name, 'tracking_link']}
                        >
                          <Input disabled={!permissions?.orders_card_edit} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Ship to information"
                          {...restField}
                          name={[name, 'ship_to']}
                        >
                          <Input disabled={!permissions?.orders_card_edit} />
                        </Form.Item>
                      </Col>
                    </Row>
            
                    <Row gutter={16}>
                      <Form.List name={[name, 'tracking_info']}>
                        {(tracking_info, { add, remove }) => (
                          <>  
                            {tracking_info?.map((field, index) => 
                              <Fragment key={field.key}>
                                <Row style={{ borderBottom: '1px dashed grey', marginBottom: 10}}></Row>
                                <Col span={5} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'tracking_id']}
                                    fieldKey={[field.fieldKey, 'tracking_id']}
                                    label="Tracking number"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <Input disabled={!permissions?.orders_card_edit || !data?.changeable[i]}/>
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'carrier']}
                                    fieldKey={[field.fieldKey, 'carrier']}
                                    label="Carrier"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <Select allowClear onChange={e => onCarrierChange(e, i)} disabled={!permissions?.orders_card_edit || !data?.changeable[i]}>
                                      {
                                        carrierList?.map(el => (
                                          <Select.Option key={el} value={el}>{el}</Select.Option>
                                        ))
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'service']}
                                    fieldKey={[field.fieldKey, 'service']}
                                    label="Shipping service"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <Select allowClear disabled={!permissions?.orders_card_edit || !data?.changeable[i]}>
                                      {
                                        serviceList[i]?.map(el => (
                                          <Select.Option key={el} value={el}>{el}</Select.Option>
                                        ))
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'shipping_date']}
                                    fieldKey={[field.fieldKey, 'shipping_date']}
                                    // name={[field.name, 'shipping_date_manual']}
                                    // fieldKey={[field.fieldKey, 'shipping_date_manual']}
                                    label="Shipping date"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <DatePicker
                                      disabled={!permissions?.orders_card_edit || !data?.changeable[i]}
                                      format="MM/DD/YYYY"
                                      suffixIcon={<DownOutlined />}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'shipping_time']}
                                    fieldKey={[field.fieldKey, 'shipping_time']}
                                    // name={[field.name, 'shipping_time_manual']}
                                    // fieldKey={[field.fieldKey, 'shipping_time_manual']}
                                    label="Shipping time"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <TimePicker
                                      use12Hours
                                      format="h:mm a"
                                      disabled={!permissions?.orders_card_edit || !data?.changeable[i]}
                                      suffixIcon={<DownOutlined />}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={4} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'delivery_status']}
                                    fieldKey={[field.fieldKey, 'delivery_status']}
                                    label="Delivery status"
                                    // rules={[{ required: true, message: '' }]}
                                  >
                                    <Input disabled/>
                                  </Form.Item>
                                </Col>
                                <Col span={2} style={{ height: '70px'}}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'qty']}
                                    fieldKey={[field.fieldKey, 'qty']}
                                    label="Qty"
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <InputNumber step="0" disabled={!permissions?.orders_card_edit || !data?.changeable[i]}/>
                                  </Form.Item>
                                </Col>
                                <Col span={21} style={{ height: '70px'}}>
                                  <Form.Item
                                    label="Tracking link"
                                    {...field}
                                    name={[field.fieldKey, 'carrier_tracking_link']}
                                  >
                                    <Input disabled={!permissions?.orders_card_edit || !data?.changeable[i]} />
                                  </Form.Item>
                                </Col>
                                <Col span={1} className="delete-cell">
                                  {permissions?.orders_card_edit &&
                                     <div onClick={() => { remove(field.name); removeItem(index) }}>
                                      {data?.changeable[i] && <Bucket />}
                                    </div>
                                  }
                                </Col>
                                {/* <Row style={{ borderBottom: '1px dashed grey', marginBottom: 10}}></Row> */}
                              </Fragment>
                            )}
                            <Col span={12} style={{ marginTop: 10}}>
                              {(permissions?.orders_card_edit) && <Button className="btn-primary" disabled={!data?.changeable[i]} onClick={() => { add(); addItem() }}
                              >
                                Add tracking
                              </Button>}
                            </Col>
                          </>
                          )
                        }
                      </Form.List>
                    </Row>
                  </div>
                )
              )}
            </>
          )
        }
      </Form.List>
      <br />
      {/* <Row gutter={16}>
        <Form.Item
          label="Comment"
          name="comment"
          className='comment1'
        >
          <Input.TextArea maxLength={5000} autoSize={{minRows: 2, maxRows: 6}} disabled={!permissions?.orders_card_edit}/>
        </Form.Item>
      </Row> */}
    </Form >
    <div className='comments-wrapper'>
      <div>Add comment</div>
        <Input.TextArea
          className='add-comment-input' 
          maxLength={5000} 
          autoSize={{minRows: 1, maxRows: 2}} 
          disabled={!permissions?.orders_card_edit}
          onChange={(e)=> setNewCommentData(e.target.value)}
          value={newCommentData}
        />
        <Row>
          <div 
            className='add-comment-button-cancel'
            onClick={()=> setNewCommentData(null)}
          >
            CANCEL
          </div>
          <div 
            className='add-comment-button-add'
            onClick={()=> newCommentData ? props.saveComment({ text: newCommentData, order: data?.order_id_}) : null}
          >
            ADD COMMENT
          </div>
        </Row>
        {commentsData && commentsData?.map(el => {

          return (
            <div className='comment' key={el.created}>
              <Row>
                <div className='comment-date'>{moment(el.created).format('MM/DD/YYYY, h:mm a')}</div>
                <div className='comment-name'>{el.user_name}</div>
              </Row>
              <div className='comment-text'>{el.text}</div>
            </div>
          )
        })}
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    comments: state.orders.comments,
    saveCommentResult: state.orders.saveCommentResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getComments: (query) => dispatch(OrdersActions.getCommentsRequest(query)),
  saveComment: (data) => dispatch(OrdersActions.saveCommentRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm)