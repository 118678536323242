// a library to wrap and simplify api calls
import apisauce from 'apisauce'
//import usersApi from './UsersApi'
import API from './Api'

// our 'constructor'
const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {

  const accessData = { accessToken: null, refreshToken: null };
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json'
    },

    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  // const serialize = (obj, prefix) => {
  //   var str = [],
  //     p;
  //   for (p in obj) {
  //     if (obj.hasOwnProperty(p)) {
  //       var k = prefix ? prefix + "." + p  : p,
  //         v = obj[p];
  //       str.push((v !== null && typeof v === "object") ?
  //         serialize(v, k) :
  //         encodeURIComponent(k) + "=" + encodeURIComponent(v));
  //     }
  //   }
  //   return str.join("&");
  // }

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;
    if (accessToken) {
      api.setHeader('Authorization', 'JWT ' + accessToken)

    } else {
      api.setHeader('Authorization', null)
    }
    API.updateAccessToken(accessToken, refreshToken)
  }

  const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/token/', { email, password })
        .then(response => {
          if (response && response.ok && response.data && response.data.access) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh
            })
          } else if (response && response.ok) {
            resolve(response);
          } else if (response.status === 401) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error)
        })
    })
  };

  const tokenRefresh = () => {
    return new Promise((resolve, reject) => {
      api.post('/auth/token/refresh/', {
        refresh: accessData.refreshToken
      })
        .then(response => {
          if (response && response.ok && response.data && response.data.access) {
            updateAccessToken(response.data.access, response.data.refresh);
          }
          resolve({
            access: response.data.access,
            refresh: response.data.refresh
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  
  const getUserPermissions = () => {
    return api.get('/role/user_permissions/');
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    updateAccessToken,
    signIn,
    tokenRefresh,
    getUserPermissions
  }
}

// let's return back our create method as the default.
export default create();
