// a library to wrap and simplify api calls
import apisauce from 'apisauce'

// our 'constructor'
const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const accessData = { accessToken: null };

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',

    },
    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "." + p : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;
    if (accessToken) {
      api.setHeader('Authorization', 'JWT ' + accessToken);
    } else {
      api.setHeader('Authorization', null);
    }
  }


  // ___________________________________________________________

  const getOrders = query => {
    return api.get('/oms/orders/?' + serialize(query));
  }

  const createOrder = data => {
    return api.post(`/oms/orders/`, data);
  }

  const updateOrder = data => {
    const { id } = data;
    const newData = { ...data };
    delete newData.id;
    return api.put(`/oms/update_order/${id}/`, newData);
  }

  const updateInternalStatus = data => {
    return api.post(`/oms/edit_status/`, data);
  }

  const getOrderFilters = () => {
    return api.get('/oms/for_filters/');
  }

  const getAvList = () => {
    return api.get('/oms/av_list/');
  }

  const changeAssigned = data => {
    return api.post('/oms/edit_assigned/', data);
  }

  const getShippingServices = () => {
    return api.get('/oms/shipping_services/');
  }
 
  const saveComment = (data) => {
    return api.post('/oms/comment/', data);
  }

  const getComments = (query) => {
    return api.get('/oms/comment/?'  + serialize(query));
  }

  // ------------------------------------------------------

  const getProducts = query => {
    return api.get('/oms/products/?' + serialize(query));
  }

  const getProduct = productId => {
    return api.get(`/oms/products/${productId}/`);
  }

  const saveProduct = data => {
    return api.post(`/oms/products/`, data);
  }

  const deleteProduct = productId => {
    return api.delete(`/oms/products/${productId}/`);
  }

  const deleteProducts = data => {
    return api.post(`/oms/products/bulk_delete/`, data);
  }

  const changeProductQuantity = data => {
    return api.post(`/oms/products/edit_quantity/`, data);
  }

  const getStoreList = () => {
    return api.get(`/oms/shops/`);
  }

  const addStoreToProduct = (productId, data) => {
    return api.post(`/oms/products/${productId}/marketplace/`, data);
  }

  const deleteStoreFromProduct = (productId, shopId) => {
    return api.post(`/oms/products/${productId}/marketplace_delete/`, {
      marketplace_id: shopId
    });
  }

  const getSupplierList = () => {
    return api.get(`/oms/suppliers/`);
  }

  const addSupplierToProduct = (productId, data) => {
    return api.post(`/oms/products/${productId}/supplier/`, data);
  }

  const deleteSupplierFromProduct = (productId, supplierId) => {
    return api.post(`/oms/products/${productId}/supplier_delete/`, {
      supplier_id: supplierId
    });
  }

  const editSupplierUrl = (productId, data) => {
    return api.put(`/oms/products/${productId}/edit_source_url/`, data);
  }

  const editStoreData = (productId, data) => {
    return api.put(`/oms/products/${productId}/update_marketplace/`, data);
  }

  const getUploadProductData = () => {
    return api.get(`/oms/upload_file/`);
  }

  const uploadProductData = data => {
    return api.post(`/oms/upload_file/`, data, { timeout: 30000 });
  }

  const changeProductStatus = data => {
    return api.post(`/oms/products/edit_status/`, data);
  }

  const saveHandlingTime = (data) => {
    return api.post(`/oms/products/edit_handling_time/`, data);
  }

  // ___________________________________________________________

  const getUsers = query => {
    return api.get(`/admin-panel/users/?` + serialize(query));
  }

  const getUser = id => {
    return api.get(`/admin-panel/users/${id}/`);
  }
  const createUser = data => {
    return api.post(`/admin-panel/users/`, data);
  }

  const updateUser = data => {
    const { id } = data;
    const newData = { ...data };
    delete newData.id;
    return api.patch(`/admin-panel/users/${id}/`, newData);
  }

  const deleteUser = id => {
    return api.delete(`/admin-panel/users/${id}/`);
  }

  const markAsDeletedUser = id => {
    return api.delete(`/admin-panel/users/${id}/delete/`);
  };

  const unmarkAsDeletedUser = id => {
    return api.post(`/admin-panel/users/${id}/delete/`);
  };

  const getUserRoles = () => {
    return api.get(`/admin-panel/users/roles/`);
  }

  const getPermissionsList = () => {
    return api.get(`/role/permissions_list/`);
  }
 
  const updatePermissionsList = (data) => {
    return api.put(`/role/edit_permissions/`, data);
  }

  // ___________________________________________________________

  const getDashboard = query => {
    return api.get(`/oms/dashboard/?` + serialize(query));
  }

  // ___________________________________________________________

  const getNotes = query => {
    return api.get(`/oms/notifications/?` + serialize(query));
  }
  // for testing
  // return api.get(`/oms/notifications/create_test_notifications/`);
  
  const getNewNotes = () => {
    return api.get(`/oms/notifications/new_notifications/`);
  }

  const getHistoryNotes = query => {
    return api.get(`/oms/history/?` + serialize(query));
  }

  const saveNoteHistory = data => {
    return api.post(`/oms/history/`, data);
  }

  // ___________________________________________________________

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    updateAccessToken,

    getOrders,
    createOrder,
    updateOrder,
    updateInternalStatus,
    getOrderFilters,
    getAvList,
    changeAssigned,
    getShippingServices,
    saveComment,
    getComments,

    getProducts,
    getProduct,
    saveProduct,
    deleteProduct,
    deleteProducts,
    changeProductQuantity,
    getStoreList,
    addStoreToProduct,
    deleteStoreFromProduct,
    getSupplierList,
    addSupplierToProduct,
    deleteSupplierFromProduct,
    editSupplierUrl,
    editStoreData,
    getUploadProductData,
    uploadProductData,
    changeProductStatus,
    saveHandlingTime,

    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    markAsDeletedUser,
    unmarkAsDeletedUser,
    getUserRoles,
    getPermissionsList,
    updatePermissionsList,

    getDashboard,

    getNotes,
    getNewNotes,
    getHistoryNotes,
    saveNoteHistory,
  }
}

// let's return back our create method as the default.
export default create()
