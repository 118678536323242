import { call, put, select } from 'redux-saga/effects'
import UsersActions, { UsersSelectors } from '../Redux/UsersRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getUsers(api, authApi, { query }) {
  try {
    const response = yield call(api.getUsers, query)
    if (response && response.ok) {
      yield put(UsersActions.usersSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getUsers, { query }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getUser(api, authApi, { id }) {
  try {
    const response = yield call(api.getUser, id);
    if (response && response.ok) {
      yield put(UsersActions.userSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getUser, { id }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveUser(api, authApi, { data }) {
  try {
    const { page, page_size } = yield select(UsersSelectors.getState);
    const saveFunc = data.id ? api.updateUser : api.createUser;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(UsersActions.userSaveSuccess(response.data))
      yield put(UsersActions.resultsReset())
      yield put(UsersActions.usersRequest({ page, page_size }))
    } else {
      yield* handleSagasError(api, authApi, response, saveUser, { data }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* deleteUser(api, authApi, { id }) {
  try {
    const { page_size } = yield select(UsersSelectors.getState);
    const response = yield call(api.deleteUser, id)
    if (response && response.ok) {
      yield put(UsersActions.userDeleteSuccess(response.data || true))
      yield put(UsersActions.resultsReset())
      yield put(UsersActions.usersRequest({ page: 1, page_size }))
    } else {
      yield* handleSagasError(api, authApi, response, deleteUser, { id }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* markAsDeletedUser(api, authApi, { id }) {
  try {
    const { page, page_size } = yield select(UsersSelectors.getState);
    const response = yield call(api.markAsDeletedUser, id)
    if (response && response.ok) {
      yield put(UsersActions.userBlockSuccess(response.data))
      yield put(UsersActions.resultsReset())
      yield put(UsersActions.usersRequest({ page, page_size }))
    } else {
      yield* handleSagasError(api, authApi, response, markAsDeletedUser, { id }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* unmarkAsDeletedUser(api, authApi, { id }) {
  try {
    const { page, page_size } = yield select(UsersSelectors.getState);
    const response = yield call(api.unmarkAsDeletedUser, id)
    if (response && response.ok) {
      yield put(UsersActions.userUnblockSuccess(response.data))
      yield put(UsersActions.resultsReset())
      yield put(UsersActions.usersRequest({ page, page_size }))
    } else {
      yield* handleSagasError(api, authApi, response, unmarkAsDeletedUser, { id }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getUserRoles(api, authApi) {
  try {
    const response = yield call(api.getUserRoles);
    if (response && response.ok) {
      yield put(UsersActions.userRolesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getUserRoles, null, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getPermissionsList(api, authApi) {
  try {
    const response = yield call(api.getPermissionsList);
    if (response && response.ok) {
      yield put(UsersActions.getPermissionsListSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getPermissionsList, null, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* updatePermissionsList(api, authApi, { data }) {
  try {
    const response = yield call(api.updatePermissionsList, data);
    if (response && response.ok) {
      yield put(UsersActions.updatePermissionsListSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, updatePermissionsList, { data }, ErrorsActions.errorSave, UsersActions.userFetchingReset)
    }
  } catch (e) {
    yield put(UsersActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
