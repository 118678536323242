import React, { Fragment } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts'

const AreaChartComponent = props => {
  const { data, width, height, dataKey_XAxis, dataKey_YAxis, dataKey_Line, color, showLegend, YLabel, gradientData } = props;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey_XAxis} hide />
        <YAxis dataKey={dataKey_YAxis}>
          <Label
            value={YLabel} angle="-90" position="left" offset={2} />
        </YAxis>
        <Tooltip />
        {
          showLegend &&
          <Legend />
        }
        {
          dataKey_Line?.map((line, i) => (
            <Fragment key={line}>
              <defs>
                <linearGradient id={line} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={gradientData[i]} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={gradientData[i]} stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey={line}
                stroke={color[i]}
                fillOpacity={1}
                fill={`url(#${line})`}
                dot
                activeDot={{ r: 6 }}
              />
            </Fragment>
          ))
        }
      </AreaChart >
    </ResponsiveContainer>
  )
}

export default AreaChartComponent
