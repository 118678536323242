const BellIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.9997 3.86475C9.00437 3.86475 6.5762 6.68031 6.5762 9.6756V11.5821C6.5762 12.5871 6.14642 13.5441 5.39527 14.2118C4.58924 14.9283 5.09602 16.2612 6.17445 16.2612H17.8249C18.9033 16.2612 19.4101 14.9283 18.6041 14.2118C17.8529 13.5441 17.4231 12.5871 17.4231 11.5821V9.6756C17.4231 6.68031 14.995 3.86475 11.9997 3.86475ZM14.3244 17.8108C14.3244 19.0945 13.2838 20.1351 12.0001 20.1351C10.7164 20.1351 9.67572 19.0945 9.67572 17.8108L9.67584 17.7871C9.67825 17.5451 9.89333 17.3735 10.1354 17.3762L13.9031 17.419C14.1284 17.4215 14.3244 17.5855 14.3244 17.8108Z"
        fill={isActive ? "#526DCB" : "#262F56"} />
    </svg>
  );
};

export default BellIcon;
