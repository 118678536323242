/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'

const UserForm = (props) => {
  const { initial, roles, onSubmit } = props

  const [form] = Form.useForm();

  useEffect(() => {
    if (initial) {
      form.setFieldsValue(initial);
    }
  }, [initial])

  const onFinish = values => {
    const data = { ...values };
    onSubmit(data);
  }


  return (
    <Form id="user-form" form={form} className="custom-modal-form" onFinish={onFinish} layout="vertical" requiredMark={false}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Invalid email format' },
              { required: true, message: 'This field is required' },
              { min: 8, message: 'Field must contain at least 8 characters' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="First name"
            name="first_name"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Last name"
            name="last_name"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        {
          !initial &&
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="Role"
            name="user_role"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select>
              {
                roles?.map(el => (
                  <Select.Option key={el.id} value={el.id}>{el.title}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
