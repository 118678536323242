/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Spin, Input, Tooltip } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import './style.less'

import RangePickerComponent from '../../Components/RangePicker'
import SelectComponent from '../../Components/SelectComponent'
import SearchIcon from '../../Components/Icons/Search'

import PaginationComponent from '../../Components/PaginationComponent'
import TableOptionsActions from '../../Components/TableOptionsActions'
import NotesActions from '../../Redux/NotesRedux'

import Clock from '../../Components/Icons/Clock'
// import Bucket from '../../Components/Icons/Bucket'
// import Eyes from '../../Components/Icons/Eyes'

const { Search } = Input;

const NotesPage = props => {
  const { fetching, notes_notes, notes, isNewNotes, permissions } = props;

  const history = useHistory();

  const [notesData, setNotesData] = useState(null);
  const [paginate, setPaginate] = useState(null);
  const [start, setStart] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  const [filterBy, setFilterBy] = useState({
    date_from: null,
    date_to: null,
    all: false,
    type: null,
    error_type: null,
    // file: false,
    // canceled: false
  });

  useEffect(() => {
    if (!start) {
      setPaginate({
        page: notes.page,
        page_size: notes.page_size
      });
      setStart(true);
    }

    return () => {
      props.resetNotes();
    };

  }, [0])

  useEffect(() => {
    if (notes_notes?.results) {
      setNotesData(notes_notes.results);
    }
  }, [notes_notes?.results])


  const handleQuery = (page, page_size) => {
    const query = {
      q: searchQuery,
      page: page ? page : paginate.page,
      page_size: page_size ? page_size : paginate.page_size,
      ...filterBy
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getNotes(query);
  }

  useEffect(() => {
    if (start) {
      handleQuery();
    }
  }, [start])

  useEffect(() => {
    if (start) {
      // props.notesSyncToggle(false);
      // const timer = () => setTimeout(() => {
        props.getNewNotes();
        handleQuery();
      //   props.notesSyncToggle(true);
      // }, 10000);
      // const timerId = timer();
      // return () => {
      //   clearTimeout(timerId);
      // };
    }
  }, [isNewNotes?.notification])

  useEffect(() => {
    if (start) {
      handleQuery(1);
    }
  }, [searchQuery, filterBy])

  const changeFilterDate = values => {
    setFilterBy({
      ...filterBy,
      date_from: values ? values[0].format('YYYY-MM-DD') : null,
      date_to: values ? values[1].format('YYYY-MM-DD') : null,
    })
  }

  const changeFilter = (field, value) => {
    setFilterBy({
      ...filterBy,
      [field]: value
    })
  }

  const onPaginate = (page, page_size) => {
    handleQuery(page);
    setPaginate({ page, page_size });
    if (isNewNotes) {
      props.isNewNotesReset()
    }
  }

  const pageSizeHandler = value => {
    handleQuery(1, value);
    setPaginate({
      page: 1,
      page_size: value
    });
  }

  const handleSearch = value => {
    let val = value.trim().toLowerCase();
    if (val !== '') setSearchQuery(value)
    else setSearchQuery(null)
  }

  return (
    <div className="content-page">
      <Spin size="large" spinning={fetching}>
        <div className="table-options">
          <div className="table-options-inputs" 
          // style={{ width: '100%' }}
          >
            <Search
              style={{ marginRight: 0 }}
              placeholder="Search by ID"
              className="search-input"
              allowClear
              onSearch={handleSearch}
              suffix={<SearchIcon />}
            />
          <div className="header-link-divider" />
            <SelectComponent
              showSearch
              // extendedFullName={true}
              data={['order', 'product']}
              allowClear
              onChange={e => changeFilter('type', e)}
              placeholder="Order/Product"
              width={140}
            />
            <SelectComponent
              showSearch
              // extendedFullName={true}
              data={['inactive after creating', 'oos', 'dispatch time', 'manual inactivation', 'inactive on Amazon', 'manual activation', 'canceled order', 'delivered tracking']}
              allowClear
              onChange={e => changeFilter('error_type', e)}
              placeholder="Type"
              width={140}
              marginRight={0}
            />
            <div className="header-link-divider" />
            <RangePickerComponent
              onChange={changeFilterDate}
            />
        </div>
        <TableOptionsActions
          defaultValue={notes.page_size}
          pageSizeHandler={pageSizeHandler}
        />
      </div>
      <div className="notifications-content">
          {/* <div className="title">Not viewed</div> */}
          {!notesData?.length && <div className="not-found">Not found. Try another search query</div>}
          {notesData && notesData?.map((el) => {
            return (
              <Row key={el.id} className={el.new_notification ? "note" : "note grey"}>
                {/* <Row><Col  className="note_type"></Col></Row> */}
                  <Col span={24} className="note_row">
                     <div className="note_row__item-id">
                     <span style={{ marginRight: 4, color: '#78797d' }}>ID:</span>
                     <Tooltip title={el.item_id} placement="topLeft">
                      <div
                        onClick={ 
                          el.type === 'product'
                            ? (permissions?.products_card_view ? () => history.push({pathname:`/home/product/${el.oms_id}`}) : null) :
                          el.type === 'order'
                            ? (permissions?.orders_view ? () => history.push({pathname:`/home/orders/`, state: el.item_id }) : null) :
                              null
                        }
                      >
                        
                        {el.item_id}
                      </div>
                      </Tooltip>
                    </div>
                    <div 
                      className={el.new_notification ? "note_row__text new" : "note_row__text"}
                    >{el.name_obj.name}</div>
                    <div className="note_row__date">
                      <Clock /><span></span> {moment(el.created).format('DD MMMM YYYY, h:mm a')}
                    </div>
                    <div className="note_row__delete">
                      {/* <a onClick={ deleteNotification }><Eyes /></a> */}
                      {/* <a onClick={ deleteNotification }><Bucket /></a> */}
                    </div>

                  </Col>
                  {/* <Col span={3} className="note_date"> */}
                  {/* </Col> */}
                  {/* <Col span={1} className="note_delete"> */}
                       {/* <a onClick={ deleteNotification }><Eyes /></a> */}
                  {/* </Col> */}
                {/* </Row> */}
                {/* <Row>
                  <Col span={24} className="note_author">
                    {el.author}
                  </Col>
                </Row> */}
              </Row>
              // {/* <div key={el.id} className="note">
              //       <div className="note_text">{el.text}</div>
              //       <div className="note_date">
              //         <Clock /><span style={{ width: 5}}></span> {moment(el.date).format('DD MMMM YYYY, h:mm')}
              //       </div>
              //       <div className="note_delete">
              //         <a onClick={ deleteNotification }><Bucket /></a>
              //       </div>
              //       <div>{el.author}</div>
              // </div> */}
            )
          })}
          
      </div>
     </Spin>
      <PaginationComponent
          onChange={(page, page_size) => onPaginate(page, page_size)}
          total={notes_notes?.count}
          current={notes.page}
          pageSize={notes.page_size}
      />
    </div >
  )
}

const mapStateToProps = (state) => ({
  fetching: state.notes.fetching,
  notes_notes: state.notes.notes,
  notes: state.notes,
  isNewNotes: state.notes.isNewNotes,
  permissions: state.auth.permissions,
  // auth: state.auth,
  // role: state.auth.role,
})

const mapDispatchToProps = (dispatch) => ({
  getNotes: (query) => dispatch(NotesActions.getNotesRequest(query)),
  getNewNotes: () => dispatch(NotesActions.getNewNotesRequest()),
  notesSyncToggle: (syncIsStart) => dispatch(NotesActions.notesSyncToggle(syncIsStart)),
  resetNotes: () => dispatch(NotesActions.notesSettingReset()),
  isNewNotesReset: () => dispatch(NotesActions.isNewNotesReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotesPage)
