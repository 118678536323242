import React from 'react'
import { Pagination } from 'antd'

import './style.less'

const PaginationComponent = (props) => {
  const { onChange, current, total, pageSize, showSizeChanger } = props

  return (
    <Pagination
      onChange={onChange}
      total={total}
      current={current}
      pageSize={pageSize}
      showSizeChanger={!!showSizeChanger}
    />
  );
};

export default PaginationComponent;
