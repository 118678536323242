import React from 'react'

// import BackwardsIcon from '../Icons/Backwards'
import SelectComponent from '../SelectComponent'
// import OrderIcon from '../Icons/Order'

import './style.less'

const TableOptionsActions = (props) => {
  const { defaultValue, pageSizeHandler } = props

  return (
    <div className="table-options-actions">
      {/* <ButtonComponent className="table-options-actions-button"
        onClick={(event) => console.log(event)}
      >
        <div style={{ width: '25px', height: '24px' }}>
          <BackwardsIcon />
        </div>
      </ButtonComponent> */}
      <SelectComponent data={[10, 20, 50]}
        onChange={e => pageSizeHandler(e)}
        defaultValue={defaultValue}
        className="table-options-page-size select--transparent"

      />
      {/* <ButtonComponent className="btn table-options-customize"
      onClick={(event) => console.log(event)}
      >
      <div className="table-options-customize-icon">
          <OrderIcon />
        </div>
        <span>Customize</span>
      </ButtonComponent> */}
    </div>
  );
};

export default TableOptionsActions;
