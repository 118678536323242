const UsersIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4843 11.8307C14.6631 10.8845 15.4178 9.43152 15.4178 7.80233C15.4178 6.17314 14.6631 4.7202 13.4843 3.77399C13.9553 3.59688 14.4656 3.5 14.9985 3.5C17.3746 3.5 19.3008 5.42622 19.3008 7.80233C19.3008 10.1784 17.3746 12.1047 14.9985 12.1047C14.4656 12.1047 13.9553 12.0078 13.4843 11.8307Z" fill={isActive ? "#526DCB" : "#262F56"} />
      <circle r="4.30233" transform="matrix(-1 0 0 1 8.97599 7.80233)" fill={isActive ? "#526DCB" : "#262F56"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.406 13.9729C13.9224 13.8762 14.4551 13.8256 14.9997 13.8256C17.6287 13.8256 19.9824 15.0047 21.5607 16.8629C22.747 18.2595 22.0584 20.3185 20.3532 20.9894C18.6952 21.6418 16.8893 22 14.9997 22C14.9606 22 14.9216 21.9999 14.8826 21.9996C16.7412 21.3943 18.0001 20.1817 18.0001 18.5582C18.0001 16.5338 16.0427 14.5236 13.406 13.9729Z" fill={isActive ? "#526DCB" : "#262F56"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3307 20.9894C16.0359 20.3185 16.7245 18.2595 15.5383 16.8629C13.9599 15.0047 11.6063 13.8256 8.9772 13.8256C6.34815 13.8256 3.99448 15.0047 2.41615 16.8629C1.22987 18.2595 1.91848 20.3185 3.62368 20.9894C5.28169 21.6418 7.0876 22 8.9772 22C10.8668 22 12.6727 21.6418 14.3307 20.9894Z" fill={isActive ? "#526DCB" : "#262F56"} />
    </svg>
  );
};

export default UsersIcon;
