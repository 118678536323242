import { call, put, delay } from 'redux-saga/effects'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { push } from 'connected-react-router'

import { handleSagasError } from './handleSagasError'

export function* signIn(authApi, { email, password, pathname }) {
  try {
    const response = yield call(authApi.signIn, email, password)
    if (response && response.access && response.userInfo) {
      yield put(AuthActions.signInSuccess(response.userInfo, response.access, response.refresh))
      yield put(AuthActions.getUserPermissionsRequest())
      yield delay(500)
      // yield put(push('/home/orders'))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(response.data))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
    }
  } catch (e) {
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* getUserPermissions(authApi) {
  try {
    const response = yield call(authApi.getUserPermissions)
    if (response && response.ok) {
      yield put(AuthActions.getUserPermissionsSuccess(response.data))
      yield put(push(response.data?.orders_view ? '/home/orders' : 
        response.data?.products_view ? '/home/products' : 
        response.data?.dashboard ? '/home/dashboard' : '/home/notifications'
      ))
    } else {
      yield* handleSagasError(authApi, authApi, response, getUserPermissions, ErrorsActions.errorSave)
    }
  } catch (e) {
    yield put(AuthActions.authFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
