import { call, put } from 'redux-saga/effects'
import DashboardActions from '../Redux/DashboardRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getDashboard(api, authApi, { query }) {
  try {
    const response = yield call(api.getDashboard, query)
    if (response && response.ok) {
      yield put(DashboardActions.dashboardSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getDashboard, { query }, ErrorsActions.errorSave, DashboardActions.dashboardFetchingReset)
    }
  } catch (e) {
    yield put(DashboardActions.dashboardFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
