const DownloadIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.6364 12.3636C8.28492 12.0121 7.71508 12.0121 7.3636 12.3636C7.01213 12.7151 7.01213 13.2849 7.3636 13.6364L11.3636 17.6364C11.5324 17.8052 11.7613 17.9 12 17.9C12.2387 17.9 12.4676 17.8052 12.6364 17.6364L16.6364 13.6364C16.9879 13.2849 16.9879 12.7151 16.6364 12.3636C16.2849 12.0121 15.7151 12.0121 15.3636 12.3636L12.9 14.8272V7.00001H17C18.6569 7.00001 20 8.34315 20 10V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V10C4 8.34315 5.34315 7.00001 7 7.00001H11.1V14.8272L8.6364 12.3636ZM11.1 7.00001V3.00001C11.1 2.50295 11.5029 2.10001 12 2.10001C12.4971 2.10001 12.9 2.50295 12.9 3.00001V7.00001H11.1Z" fill={isActive ? "#BFBFBF" : "#FFFFFF"} />
    </svg>

  );
};

export default DownloadIcon;
