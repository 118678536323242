import React from 'react'

const ProfilePage = () => {
  return (
    <div>
      Profile
    </div>
  );
};

export default ProfilePage;
