import { call, put, take, select, delay } from 'redux-saga/effects'
import NotesActions, { NotesTypes, NotesSelectors} from '../Redux/NotesRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getNotes(api, authApi, { query }) {
  try {
    const response = yield call(api.getNotes, query)
    if (response && response.ok) {
      yield put(NotesActions.getNotesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getNotes, query, ErrorsActions.errorSave, NotesActions.notesFetchingReset)
    }
  } catch (e) {
    yield put(NotesActions.notesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getNewNotes(api, authApi,) {
  try {
    const response = yield call(api.getNewNotes)
    if (response && response.ok) {
      yield put(NotesActions.getNewNotesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getNewNotes, ErrorsActions.errorSave, NotesActions.notesFetchingReset)
    }
  } catch (e) {
    yield put(NotesActions.notesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function * notesSyncStartMonitor() {
    while (true) {
      const payload = yield take(NotesTypes.NOTES_SYNC_TOGGLE);
      let doWork = payload.syncIsStart;
      while (doWork) {
        const { syncIsStart } = yield select(NotesSelectors.getState);
        if (!syncIsStart) {
          break;
        }
        yield put(NotesActions.getNewNotesRequest());
        doWork = syncIsStart;
        yield delay(30000);
      }
    }
  }

export function* getHistoryNotes(api, authApi, { query }) {
  try {
    const response = yield call(api.getHistoryNotes, query)
    if (response && response.ok) {
      yield put(NotesActions.getHistoryNotesSuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, getHistoryNotes, query, ErrorsActions.errorSave, NotesActions.notesFetchingReset)
    }
  } catch (e) {
    yield put(NotesActions.notesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* saveNoteHistory(api, authApi, { data }) {
  try {
    const response = yield call(api.saveNoteHistory, data)
    if (response && response.ok) {
      yield put(NotesActions.saveNoteHistorySuccess(response.data))
    } else {
      yield* handleSagasError(api, authApi, response, saveNoteHistory, data, ErrorsActions.errorSave, NotesActions.notesFetchingReset)
    }
  } catch (e) {
    yield put(NotesActions.notesFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
