const DashboardIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="7" height="5" rx="1" fill={isActive ? "#526DCB" : "#262F56"} />
      <path d="M13 6.6C13 6.03995 13 5.75992 13.109 5.54601C13.2049 5.35785 13.3578 5.20487 13.546 5.10899C13.7599 5 14.0399 5 14.6 5H18.4C18.9601 5 19.2401 5 19.454 5.10899C19.6422 5.20487 19.7951 5.35785 19.891 5.54601C20 5.75992 20 6.03995 20 6.6V11.4C20 11.9601 20 12.2401 19.891 12.454C19.7951 12.6422 19.6422 12.7951 19.454 12.891C19.2401 13 18.9601 13 18.4 13H14.6C14.0399 13 13.7599 13 13.546 12.891C13.3578 12.7951 13.2049 12.6422 13.109 12.454C13 12.2401 13 11.9601 13 11.4L13 6.6Z" fill={isActive ? "#526DCB" : "#262F56"} />
      <path d="M5 12.6C5 12.0399 5 11.7599 5.10899 11.546C5.20487 11.3578 5.35785 11.2049 5.54601 11.109C5.75992 11 6.03995 11 6.6 11H10.4C10.9601 11 11.2401 11 11.454 11.109C11.6422 11.2049 11.7951 11.3578 11.891 11.546C12 11.7599 12 12.0399 12 12.6V17.4C12 17.9601 12 18.2401 11.891 18.454C11.7951 18.6422 11.6422 18.7951 11.454 18.891C11.2401 19 10.9601 19 10.4 19H6.6C6.03995 19 5.75992 19 5.54601 18.891C5.35785 18.7951 5.20487 18.6422 5.10899 18.454C5 18.2401 5 17.9601 5 17.4L5 12.6Z" fill={isActive ? "#526DCB" : "#262F56"} />
      <rect x="13" y="14" width="7" height="5" rx="1" fill={isActive ? "#526DCB" : "#262F56"} />
    </svg>

  );
};

export default DashboardIcon;
