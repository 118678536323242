const Pencil = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9057 6.82355C17.6247 7.10459 17.4841 7.24511 17.3318 7.31874C17.0569 7.45152 16.7365 7.45152 16.4617 7.31874C16.3093 7.24511 16.1688 7.10459 15.8877 6.82355L14.6769 5.61276C14.3959 5.33172 14.2554 5.1912 14.1817 5.03881C14.049 4.76398 14.049 4.44355 14.1817 4.16872C14.2554 4.01634 14.3959 3.87582 14.6769 3.59478V3.59478C14.958 3.31374 15.0985 3.17322 15.2509 3.09959C15.5257 2.9668 15.8461 2.9668 16.121 3.09959C16.2734 3.17322 16.4139 3.31374 16.6949 3.59478L17.9057 4.80557C18.1867 5.08661 18.3273 5.22712 18.4009 5.37951C18.5337 5.65434 18.5337 5.97477 18.4009 6.2496C18.3273 6.40199 18.1867 6.54251 17.9057 6.82355V6.82355ZM8 15.5009C7.05719 15.5009 6.58579 15.5009 6.29289 15.208C6 14.9151 6 14.4437 6 13.5009V13.1005C6 12.6918 6 12.4874 6.07612 12.3036C6.15224 12.1199 6.29676 11.9753 6.58579 11.6863L11.4469 6.82518C12.1136 6.15851 12.4469 5.82518 12.8611 5.82518C13.2754 5.82518 13.6087 6.15851 14.2754 6.82518L14.6757 7.22552C15.3424 7.89219 15.6757 8.22552 15.6757 8.63974C15.6757 9.05395 15.3424 9.38728 14.6757 10.054L9.81456 14.9151C9.52553 15.2041 9.38101 15.3486 9.19724 15.4248C9.01347 15.5009 8.8091 15.5009 8.40035 15.5009H8Z" fill="#526DCB" />
      <rect x="4" y="17" width="16" height="3" rx="1" fill="#526DCB" />
    </svg>
  );
};

export default Pencil;
