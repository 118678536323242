import React from 'react'
import { useLocation } from 'react-router-dom'

import NavigationMenu from '../NavigationMenu'

import './style.less'

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const text = pathname.split('/')[2];

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-logo" />
        <h2 className="header-title">{text}</h2>
      </div>
      <NavigationMenu />
    </header>
  );
};

export default Header;
