import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  productsRequest: ['query'],
  productsSuccess: ['list'],

  productRequest: ['productId'],
  productSuccess: ['product'],
  productFailure: ['data'],
  productReset: null,

  productSaveRequest: ['data'],
  productSaveSuccess: ['saveResult'],

  // productDeleteRequest: ['productId'],
  // productDeleteSuccess: ['deleteResult'],

  productsDeleteRequest: ['data'],
  productsDeleteSuccess: ['deleteResult'],

  productQtyEditRequest: ['data'],
  productQtyEditSuccess: ['editQtyResult'],

  storesRequest: null,
  storesSuccess: ['storeList'],

  productStoreAddRequest: ['productId', 'data'],
  productStoreAddSuccess: ['saveResult'],

  productStoreDeleteRequest: ['productId', 'shopId'],
  productStoreDeleteSuccess: ['deleteResult'],

  suppliersRequest: null,
  suppliersSuccess: ['supplierList'],

  productSupplierAddRequest: ['productId', 'data'],
  productSupplierAddSuccess: ['saveResult'],

  productSupplierDeleteRequest: ['productId', 'supplierId'],
  productSupplierDeleteSuccess: ['deleteResult'],

  supplierUrlEditRequest: ['productId', 'data'],
  supplierUrlEditSuccess: ['saveResult'],

  productStoreEditRequest: ['productId', 'data'],
  productStoreEditSuccess: ['saveResult'],

  productUploadListRequest: null,
  productUploadListSuccess: ['uploadList'],

  productUploadRequest: ['data'],
  productUploadSuccess: ['uploadResult'],

  productChangeStatusRequest: ['data'],
  productChangeStatusSuccess: ['changeStatusResult'],
  
  productSaveHandlingTimeRequest: ['data'],
  productSaveHandlingTimeSuccess: ['saveHandlingTimeResult'],

  // setProductId: ['productId'],

  productTableSettingsReset: null,

  productsReset: null,

  resultsReset: null,
  productsFetchingReset: null
})

export const ProductsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  product: null,
  noProduct: null,
  // productId: null,
  fetching: false,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  editQtyResult: null,

  uploadList: null,
  uploadResult: null,
  uploadFetching: false,

  changeStatusResult: null,
  saveHandlingTimeResult: null,

  page: 1,
  page_size: 10,
  ordering: '',
  search: '',

  storeList: null,
  supplierList: null

})

/* ------------- Selectors ------------- */
export const ProductsSelectors = {
  getState: (state) => state.products
}

/* ------------- Reducers ------------- */

export const productsRequest = (state, { query }) => {
  const newState = {
    fetching: true
  };
  if (query.page) newState.page = query.page;
  if (query.page_size) newState.page_size = query.page_size;
  if (query.ordering) newState.ordering = query.ordering;
  newState.search = query.q || '';
  return state.merge(newState)
}

export const productsSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const productRequest = (state, { productId }) => {
  return state.merge({ fetching: true })
}

export const productSuccess = (state, { product }) => {
  return state.merge({ fetching: false, product })
}

export const productFailure = (state, { data }) => {
  return state.merge({ fetching: false, noProduct: data })
}

export const productReset = (state) => {
  return state.merge({ product: null })
}

export const productSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const productSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

// export const productDeleteRequest = (state, { productId }) => {
//   return state.merge({ deleteFetching: true })
// }

// export const productDeleteSuccess = (state, { deleteResult }) => {
//   return state.merge({ deleteFetching: false, deleteResult })
// }

export const productsDeleteRequest = (state, { data }) => {
  return state.merge({ deleteFetching: true })
}

export const productsDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const productQtyEditRequest = (state, { data }) => {
  return state.merge({ fetching: true })
}

export const productQtyEditSuccess = (state, { editQtyResult }) => {
  return state.merge({ fetching: false, editQtyResult })
}

export const storesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const storesSuccess = (state, { storeList }) => {
  return state.merge({ fetching: false, storeList })
}

export const productStoreAddRequest = (state, { productId, data }) => {
  return state.merge({ saveFetching: true })
}

export const productStoreAddSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const productStoreDeleteRequest = (state, { productId, shopId }) => {
  return state.merge({ deleteFetching: true })
}

export const productStoreDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const suppliersRequest = (state) => {
  return state.merge({ fetching: true })
}

export const suppliersSuccess = (state, { supplierList }) => {
  return state.merge({ fetching: false, supplierList })
}

export const productSupplierAddRequest = (state, { productId, data }) => {
  return state.merge({ saveFetching: true })
}

export const productSupplierAddSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const productSupplierDeleteRequest = (state, { productId, supplierId }) => {
  return state.merge({ deleteFetching: true })
}

export const productSupplierDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const supplierUrlEditRequest = (state, { productId, data }) => {
  return state.merge({ saveFetching: true })
}

export const supplierUrlEditSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const productStoreEditRequest = (state, { productId, data }) => {
  return state.merge({ saveFetching: true })
}

export const productStoreEditSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const productUploadListRequest = (state) => {
  return state.merge({ uploadFetching: true })
}

export const productUploadListSuccess = (state, { uploadList }) => {
  return state.merge({ uploadFetching: false, uploadList })
}

export const productUploadRequest = (state, { data }) => {
  return state.merge({ uploadFetching: true })
}

export const productUploadSuccess = (state, { uploadResult }) => {
  return state.merge({ uploadFetching: false, uploadResult })
}

export const productChangeStatusRequest = (state, { data }) => {
  return state.merge({ fetching: true })
}

export const productChangeStatusSuccess = (state, { changeStatusResult }) => {
  return state.merge({ fetching: false, changeStatusResult })
}

export const productSaveHandlingTimeRequest = (state, { data }) => {
  return state.merge({ fetching: true })
}

export const productSaveHandlingTimeSuccess = (state, { saveHandlingTimeResult }) => {
  return state.merge({ fetching: false, saveHandlingTimeResult })
}


// export const setProductId = (state, { productId }) => {
//   return state.merge({ productId })
// }

export const productTableSettingsReset = (state) => {
  const newState = {
    page: 1,
    ordering: '',
    search: '',
  };
  return state.merge(newState)
}

export const productsReset = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    deleteResult: null,
    editQtyResult: null,
    uploadResult: null,
    changeStatusResult: null,
    saveHandlingTimeResult: null,
    noProduct: null,
  })
}

export const productsFetchingReset = (state) => {
  return state.merge({
    fetching: false,
    saveFetching: false,
    deleteFetching: false,
    uploadFetching: false
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCTS_REQUEST]: productsRequest,
  [Types.PRODUCTS_SUCCESS]: productsSuccess,

  [Types.PRODUCT_REQUEST]: productRequest,
  [Types.PRODUCT_SUCCESS]: productSuccess,
  [Types.PRODUCT_FAILURE]: productFailure,
  [Types.PRODUCT_RESET]: productReset,

  [Types.PRODUCT_SAVE_REQUEST]: productSaveRequest,
  [Types.PRODUCT_SAVE_SUCCESS]: productSaveSuccess,

  // [Types.PRODUCT_DELETE_REQUEST]: productDeleteRequest,
  // [Types.PRODUCT_DELETE_SUCCESS]: productDeleteSuccess,

  [Types.PRODUCTS_DELETE_REQUEST]: productsDeleteRequest,
  [Types.PRODUCTS_DELETE_SUCCESS]: productsDeleteSuccess,

  [Types.PRODUCT_QTY_EDIT_REQUEST]: productQtyEditRequest,
  [Types.PRODUCT_QTY_EDIT_SUCCESS]: productQtyEditSuccess,

  [Types.STORES_REQUEST]: storesRequest,
  [Types.STORES_SUCCESS]: storesSuccess,

  [Types.PRODUCT_STORE_ADD_REQUEST]: productStoreAddRequest,
  [Types.PRODUCT_STORE_ADD_SUCCESS]: productStoreAddSuccess,

  [Types.PRODUCT_STORE_DELETE_REQUEST]: productStoreDeleteRequest,
  [Types.PRODUCT_STORE_DELETE_SUCCESS]: productStoreDeleteSuccess,

  [Types.SUPPLIERS_REQUEST]: suppliersRequest,
  [Types.SUPPLIERS_SUCCESS]: suppliersSuccess,

  [Types.PRODUCT_SUPPLIER_ADD_REQUEST]: productSupplierAddRequest,
  [Types.PRODUCT_SUPPLIER_ADD_SUCCESS]: productSupplierAddSuccess,

  [Types.PRODUCT_SUPPLIER_DELETE_REQUEST]: productSupplierDeleteRequest,
  [Types.PRODUCT_SUPPLIER_DELETE_SUCCESS]: productSupplierDeleteSuccess,

  [Types.SUPPLIER_URL_EDIT_REQUEST]: supplierUrlEditRequest,
  [Types.SUPPLIER_URL_EDIT_SUCCESS]: supplierUrlEditSuccess,

  [Types.PRODUCT_STORE_EDIT_REQUEST]: productStoreEditRequest,
  [Types.PRODUCT_STORE_EDIT_SUCCESS]: productStoreEditSuccess,

  [Types.PRODUCT_UPLOAD_LIST_REQUEST]: productUploadListRequest,
  [Types.PRODUCT_UPLOAD_LIST_SUCCESS]: productUploadListSuccess,

  [Types.PRODUCT_UPLOAD_REQUEST]: productUploadRequest,
  [Types.PRODUCT_UPLOAD_SUCCESS]: productUploadSuccess,

  [Types.PRODUCT_CHANGE_STATUS_REQUEST]: productChangeStatusRequest,
  [Types.PRODUCT_CHANGE_STATUS_SUCCESS]: productChangeStatusSuccess,

  [Types.PRODUCT_SAVE_HANDLING_TIME_REQUEST]: productSaveHandlingTimeRequest,
  [Types.PRODUCT_SAVE_HANDLING_TIME_SUCCESS]: productSaveHandlingTimeSuccess,

  // [Types.SET_PRODUCT_ID]: setProductId,

  [Types.PRODUCT_TABLE_SETTINGS_RESET]: productTableSettingsReset,

  [Types.PRODUCTS_RESET]: productsReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.PRODUCTS_FETCHING_RESET]: productsFetchingReset,
})
