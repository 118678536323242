/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Pagination, Spin} from 'antd'
import moment from 'moment'

import './style.less'

import NotesActions from '../../../../Redux/NotesRedux'

const HistoryPopUp = props => {
  const { notes, fetching, historyNotes, asin } = props;
  // const { permissions, notes, fetching, historyNotes, saveResult, asin } = props;

  const [historyData, setHistoryData] = useState(null);
  const [paginate, setPaginate] = useState(null);
  const [start, setStart] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  // const [newNoteData, setNewNoteData] = useState(null);

  useEffect(() => {
    if (!start) {
      setPaginate({
        page: 1,
        page_size: notes?.history_page_size || 10
      });
      setStart(true);
    }
    return () => {
      props.resetHistoryNotes();
    };
  }, [0])

  const handleQuery = (page, page_size) => {
    const query = {
      asin: asin,
      q: searchQuery,
      page: page ? page : paginate?.page,
      page_size: page_size ? page_size : paginate?.page_size,
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getHistoryNotes(query);
  }

  useEffect(() => {
    if (historyNotes?.results) {
      setHistoryData(historyNotes?.results);
    }
  }, [historyNotes?.results])
 
  useEffect(() => {
    if (start) {
      handleQuery(1);
    }
  }, [searchQuery])

  useEffect(() => {
    if (start) {
      handleQuery();
    }
  }, [paginate])

  // useEffect(() => {
  //   if (saveResult) {
  //     setNewNoteData(null);
  //     handleQuery(1);
  //   }
  // }, [saveResult])

  const onPaginate = (page, page_size) => {
    setPaginate({ page, page_size });
  }

  const onShowSizeChange = (current, pageSize) => {
    setPaginate({
      page: 1,
      page_size: pageSize
    });
  };


  return (
    <div>
      <Spin spinning={fetching}>
      {/* <div>Add note</div>
      <Input.TextArea
        className='add-note-popup' 
        maxLength={5000} 
        autoSize={{minRows: 1, maxRows: 2}} 
        disabled={!permissions?.orders_card_edit}
        onChange={(e)=> setNewNoteData(e.target.value)}
        value={newNoteData}
      />
      <Row>
        <div 
          className='add-note-button-cancel'
          onClick={()=> setNewNoteData(null)}
        >
          CANCEL
        </div>
        <div 
          className='add-note-button-add'
          onClick={()=> newNoteData ? props.saveNoteHistory({asin, text: newNoteData}) : null}
        >
          ADD NOTE
        </div>
      </Row> */}
      {/* <br/> */}
      <Row>
        <div 
          className='notes-filtres all'
          onClick={()=> setSearchQuery('')}
        >
          ALL
        </div>
        <div 
          className='notes-filtres user'
          onClick={()=> setSearchQuery('user')}
        >
          USER
        </div>
        <div 
          className='notes-filtres system'
          onClick={()=> setSearchQuery('system')}
        >
          SYSTEM
        </div> 
      </Row>
      {!historyData?.length && <div className="not-found">Not found.</div>}

      {historyData && historyData?.map((el) => {
        return (
          <Row key={el.id} className="note">
            <div className="note-history-date">
              {moment(el.created).format('DD MMMM YYYY, h:mm a')}
            </div>
            <div className='comment-name'>{el.user_name}</div>
            <Col span={24} className="note-history-col">
              <div className={"note-history-text"}>
                {el.text}
              </div>   
            </Col>
          </Row>)
        }
      )}
      <Pagination
        onChange={(page, page_size) => onPaginate(page, page_size)}
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        current={notes?.history_page}
        total={notes?.historyNotes?.count}
        className='history-popup'
        pageSize={notes?.history_page_size}
      />
      </Spin>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    permissions: state.auth.permissions,
    notes: state.notes,
    historyNotes: state.notes.historyNotes,
    fetching: state.notes.historyFetching,
    // saveResult: state.notes.saveResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getNotes: (query) => dispatch(NotesActions.getNotesRequest(query)),
  getHistoryNotes: (query) => dispatch(NotesActions.getHistoryNotesRequest(query)),
  saveNoteHistory: (data) => dispatch(NotesActions.saveNoteHistoryRequest(data)),
  resetHistoryNotes: () => dispatch(NotesActions.historyNotesSettingReset()),
  // resetNotes: () => dispatch(NotesActions.notesSettingReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPopUp)
