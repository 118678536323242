import React from 'react'

import Header from '../../Components/Header'

import './style.less'

const MainPage = (props) => {
  const { children } = props;

  return (
    <div className="container">
      <Header />
      <main className="main">
        {children}
      </main >
    </div >
  );
};

export default MainPage;
