/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Table, Input, Checkbox } from 'antd'

import './style.less'

import UsersActions from '../../../../Redux/UsersRedux'

// import SearchIcon from '../../Components/Icons/Search'
// import PaginationComponent from '../../Components/PaginationComponent'
// import TableOptionsActions from '../../Components/TableOptionsActions'
// import UsersActions from '../../Redux/UsersRedux'
// import UserForm from './components/UserForm'

// const { Search } = Input;

// const okButtonProps = {
//   type: "primary",
//   className: "btn-primary",
// };

// const cancelButtonProps = {
//   type: "primary white",
//   className: "btn-primary",
// };

// const role = 'admin';
// const role = 'product_lister';

const PermissionsPage = props => {
  const { permissionsList, users, permissions } = props;

  const [initData, setInitData] = useState(null);
  const [data, setData] = useState(null);
// console.log(permissions)
// console.log(initData)
  useEffect(() => {
    props.getPermissionsList();
  }, [0])

  useEffect(() => {
    if (permissionsList) {
      let obj = permissionsList ? permissionsList.asMutable({ deep: true }) : [];
      setInitData(obj);
        let arr = Object.keys(obj);
        arr = arr.map((el, i)=> ({
          id: i+1,
          functionality: el,
          product_lister: permissionsList[el],
          order_manager: permissionsList[el],
          manager: permissionsList[el],
          admin: permissionsList[el],
        }))
        setData(arr);
    }
  }, [permissionsList])

  const onChange = (e, row, col) => {
    // console.log(e, row, col)
    if (!e.target.checked) {
      const newData = initData;
      const newData2 = newData[row].filter(el => el !== col)
      setInitData({...newData, [row]: [...newData2] })
    }
    if (e.target.checked) {
      const newData = initData;
      const newData2 = newData[row];
      const newData3 = newData2.push(col);
      setInitData({...newData, [row]: [...newData2] })
    }
  };

  const onSave = () => {
    // console.log(initData)
    props.updatePermissionsList(initData)
  }
   
  const tableColumns = [
    {
      title: 'Functionality / Roles',
      dataIndex: 'functionality',
      // align: 'center',
      className: 'permissions functionality',
      width: 300,
      render: (text, record ) => {
        return (
          <span>{text}</span>
        )
      }
    },
    {
      title: 'Product Lister ',
      dataIndex: 'product_lister',
      align: 'center',
      className: 'permissions',
      width: 140,
      render: (text, record) => {
        return (
          <Checkbox
            className='permissions'
            key={record.functionality}
            defaultChecked={text.includes('Product Lister')}
            disabled={permissions?.role !== 'Admin'}
            onChange={(e)=>onChange(e, record.functionality, 'Product Lister')}
          >
          </Checkbox>
        )
      }
    },
    {
      title: 'Order Manager',
      dataIndex: 'order_manager',
      align: 'center',
      className: 'permissions',
      width: 140,
      render: (text, record) => {
        return (
          <Checkbox
            key={record.functionality}
            defaultChecked={text.includes('Order Manager')}
            disabled={permissions?.role !== 'Admin'}
            onChange={(e)=>onChange(e, record.functionality, 'Order Manager')}
          >
          </Checkbox>
        )
      }
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      align: 'center',
      className: 'permissions',
      width: 140,
      render: (text, record) => {
        return (
          <Checkbox
            key={record.functionality}
            defaultChecked={text.includes('Manager')}
            disabled={permissions?.role !== 'Admin'}
            onChange={(e)=>onChange(e, record.functionality, 'Manager')}
          >
          </Checkbox>
        )
      }
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      align: 'center',
      className: 'permissions',
      width: 140,
      render: (text, record) => {
        return (
          <Checkbox
            key={record.functionality}
            defaultChecked={text.includes('Admin')}
            // disabled={permissions?.role !== 'Admin'}
            disabled={true}
            onChange={(e)=>onChange(e, record.functionality, 'Admin')}
          >
          </Checkbox>
        )
      }
    },
  ]

  return (
    <div className="content-page">
      <div className="table-options">
        <div className="table-options-inputs" >
          {/* <div style={{ height: 10 }}></div> */}
        </div >
      </div >
      <div className="permissions-table-wrapper">
        <Table
          className="table permissions"
          rowKey={row => row.id}
          dataSource={data}
          columns={tableColumns}
          scrollToFirstRowOnChange
          pagination={false}
          loading={users.fetching}
          bordered
        />
        {/* < div className="header-link-divider" /> */}
        {permissions?.role === 'Admin' && <Button
            className="btn-primary"
            onClick={onSave}
            style={{margin: '20px auto', marginTop: '20px', width: 200}}
          >
            Save
          </Button>}
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  permissions: state.auth.permissions,
  role: state.auth.role,
  users: state.users,
  permissionsList: state.users.permissionsList,
})

const mapDispatchToProps = (dispatch) => ({
  getPermissionsList: () => dispatch(UsersActions.getPermissionsListRequest()),
  updatePermissionsList: (data) => dispatch(UsersActions.updatePermissionsListRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsPage)
