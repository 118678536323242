import { put, select } from 'redux-saga/effects'
import StartupActions from '../Redux/StartupRedux'
import { AuthSelectors } from '../Redux/AuthRedux'
import AuthApi from '../Services/AuthApi'
import { push } from 'connected-react-router'

// process STARTUP actions
export function* startup(action) {

  const userInfo = yield select(AuthSelectors.getUserInfo);
  const accessToken = yield select(AuthSelectors.getAccessToken);
  const refreshToken = yield select(AuthSelectors.getRefreshToken);
  const pathname = document.location.pathname;

  if (accessToken && userInfo) {
    AuthApi.updateAccessToken(accessToken, refreshToken);
    if (pathname.includes('/home/')) {
      yield put(push(pathname))
    }
  } else {
    if (pathname.includes('/home/')) {
      yield put(push('/login'))
    } else {
      yield put(push(pathname))
    }
  }

  yield put(StartupActions.startupSuccess())

}
