/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Modal, Tooltip, Spin, Select, Input, Typography } from 'antd'
import ReactDragListView from 'react-drag-listview'

import SearchIcon from '../../Components/Icons/Search'
import SelectComponent from '../../Components/SelectComponent'
import RangePickerComponent from '../../Components/RangePicker'
import TableOptionsActions from '../../Components/TableOptionsActions'
import TableComponent from '../../Components/TableComponent'
import PaginationComponent from '../../Components/PaginationComponent'
import OrdersActions from '../../Redux/OrdersRedux'
import ProductsActions from '../../Redux/ProductsRedux'
import OrderForm from './components/OrderForm'
import RefundableForm from './components/RefundableForm'
import HistoryPopUp from './components/HistoryPopUp'

import History from '../../Components/Icons/history'

const { Paragraph } = Typography;

const { Search } = Input;

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
};

const productFilter = [
  {
    name: 'Platform products',
    value: false
  },
  {
    name: 'All products',
    value: true
  }
];

const OrdersPage = props => {
  const { orders, products, location, permissions } = props;
  const { state } = location;

  const [start, setStart] = useState(false);
  const [data, setData] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [paginate, setPaginate] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filterBy, setFilterBy] = useState({
    customer_order__status: null,
    supplier_order__supplier_platform: null,
    customer_order__shop__name: null,
    supplier_order__status: null,
    date_from: null,
    date_to: null,
    assigned_to: null,
    all: false,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [tableColumns, setTableColumns] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [close, setClose] = useState(true);
  const [spinActivator, setSpinActivator] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [currentAsin, setCurrentAsin] = useState(null);

  const openHistory = (asin) => {
    setCurrentAsin(asin);
  }

  const columns = {
    customer_order__asin: {
      title: 'ASIN / SKU',
      dataIndex: 'customer_order__asin',
      width: 132,
      render: (text, record) => {
        return (
        text?.map((el, i) => {
          return(
          // <div className="twin-cell" key={i} style={{ padding: '8px 0' }}>
          <div className="three-cell" key={i} style={{ padding: '6px 0' }}>
            <div className="table-product-preview">
              {record.ava[i] ?
                <img src={record.ava[i]} alt={record.ava[i]} />
                :
                <div className="no-image"></div>
              }
            </div>
            <Tooltip title={el}>
              <a href={`http://www.amazon.com/dp/${el}`} target="_blank" rel="noreferrer">
                {el}
              </a>
            </Tooltip>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {record?.customer_order__sku[i]}
                <span style={{width: 6}}></span>
                <Tooltip title={'See product history'}>
                <a
                  style={{display: 'flex', alignItems: 'center'}}
                  onClick={() => {
                    return (permissions?.orders_card_view ? openHistory(el) : null)
                  }}
                >
                  <History />
                </a>
                </Tooltip>
            </div>
          </div>
        )})
        )
      }
    },
    customer_order__purchased_at: {
      title: 'Date',
      dataIndex: 'customer_order__purchased_at',
      width: 88,
      sorter: true,
      render: text =>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span className='date'>{text && moment.utc(text).format('MM/DD/YYYY')}</span>
          <span className='date'>{text && moment.utc(text).format('LT').toLowerCase()}</span>
        </div>
    },
    customer_order__status: {
      title: 'Status (Amazon)',
      dataIndex: 'customer_order__status',
      width: 84,
      render: text =>
        text?.map((el, i) => (
          <div key={i} style={{ padding: '8px 0' }}>{el}</div>
        ))
    },
    supplier_order__status: {
      title: 'Status (supplier)',
      dataIndex: 'supplier_order__status',
      width: 84,
      render: text =>
        text?.map((el, i) => (
          <div key={i} style={{ padding: '8px 0' }}>{el}</div>
        ))
    },
    // delivery_status: {
    //   title: 'Delivery status',
    //   dataIndex: 'delivery_status',
    //   width: 110,
    //   render: text =>
    //     text?.map((el, i) => (
    //       <div key={i} style={{ padding: '8px 0' }}>{el}</div>
    //     ))
    // },
    internal_status: {
      title: 'Internal status',
      dataIndex: 'internal_status',
      width: 116,
      render: (text, record) =>
        text?.map((el, i) => (
          <Select
            key={i}
            defaultValue={el?.id}
            style={{ width: '100%', display: 'block', padding: '8px 0' }}
            onChange={e => onUpdateStatus(e, record, i)}
            disabled={!permissions?.orders_edit}
          >
            {
              orders.filters?.internal_statuses?.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))
            }
          </Select>
        ))
    },
    // customer_order__sku: {
    //   title: 'SKU',
    //   dataIndex: 'customer_order__sku',
    //   width: 120,
    //   render: text =>
    //     text?.map((el, i) => (
    //       <Tooltip title={el} key={i}>
    //         <div style={{ padding: '8px 0' }}>{el}</div>
    //       </Tooltip>
    //     ))
    // },
    customer_order__order_number: {
      title: 'Order ID',
      dataIndex: 'customer_order__order_number',
      width: 90,
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <a 
              id="order_id" 
              // onClick={() => setCurrentRecordId(record.id)} //old
              onClick={() => {
                return (permissions?.orders_card_view ? beforeOpenForm(record.id, record) : null)
               }}
            >
              <Paragraph
                style={{textAlign: 'center' }}
                copyable={{ tooltips: false }}
                ellipsis={true}
              >
              {text}
              </Paragraph>
            </a>

          </Tooltip >
        )
      }
    },
    supplier_order__order_id: {
      title: 'Order ID (supplier)',
      dataIndex: 'supplier_order__order_id',
      width: 94,
      render: text =>
        text?.map((el, i) => {
        if (el) {
        return (
            <Tooltip title={el} key={i}>
              <div style={{ padding: '8px 0px' }}>
                <Paragraph
                  style={{textAlign: 'center', color: '#232D53' }}
                  copyable={{ tooltips: false }}
                  ellipsis={true}
                >
                  {el}
                </Paragraph>
              </div>
            </Tooltip>
            )
          } else {
            return null
          }
        })
    },
    // customer_order__shop__name: {
    //   title: 'Marketplace',
    //   dataIndex: 'customer_order__shop__name',
    //   width: 160,
    //   render: text => <Tooltip title={text}>{text}</Tooltip>
    // },
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    supplier_source_url: {
      title: 'Source URL',
      dataIndex: 'supplier_source_url',
      width: 90,
      render: (text, record) =>
        text?.map((el, i) => (
          <Tooltip title={el} key={i}>
            <a 
              href={el} 
              target="_blank" 
              rel="noreferrer" 
              // style={{ display: 'block', padding: '8px 0', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <Paragraph
                  style={{textAlign: 'center'}}
                  copyable={{ tooltips: false }}
                  ellipsis={true}
                >
                  {el}
                </Paragraph>
            </a>
          </Tooltip>
        ))
    },
    latest_ship_date: {
      title: 'Latest ship date',
      dataIndex: 'latest_ship_date',
      width: 88,
      render: text =>
        text?.map((el, i) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0' }} key={i}>
            <span className='date'>{el && moment.utc(el).format('MM/DD/YYYY')}</span>
            <span className='date'>{el && moment.utc(el).format('LT').toLowerCase()}</span>
          </div>
        ))
    },
    delivery_estimated_date: {
      title: 'Estimated delivery date',
      dataIndex: 'delivery_estimated_date',
      width: 88,
      render: text =>
        text?.map((el, i) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0' }} key={i}>
            <span className='date'>{el && moment.utc(el).format('MM/DD/YYYY')}</span>
            <span className='date'>{el && moment.utc(el).format('LT').toLowerCase()}</span>
          </div>
        ))
    },
    supplier_order__tracking_number: {
      title: 'Tracking #',
      dataIndex: 'supplier_order__tracking_number',
      width: 94,
      render: (text, record) =>
        text?.map((el, i) => {
          return (
            <Tooltip title={el} key={i}>
            {record.supplier_order__carrier_tracking_link[i]
              ?
              <a 
                href={record.supplier_order__carrier_tracking_link.filter(link => link?.includes(el))}
                target="_blank" 
                rel="noreferrer" 
                // style={{ display: 'block', padding: '8px 0', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                <Paragraph
                  style={{textAlign: 'center',}}
                  copyable={{ tooltips: false }}
                  ellipsis={true}
                >
                  {el}
                </Paragraph>
              </a>
              :
              <div style={{ padding: '8px 0', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{el}</div>
            }
            </Tooltip>
          )
        }
        )
    },
    supplier_order__quantity: {
      title: 'Qty',
      dataIndex: 'supplier_order__quantity',
      width: 40,
    },
    customer_order__amount_paid: {
      title: 'Buyer paid',
      dataIndex: 'customer_order__amount_paid',
      width: 70,
      sorter: true,
    },
    net_revenue: {
      title: 'Net revenue',
      dataIndex: 'net_revenue',
      width: 68,
    },
    supplier_order__total_price: {
      title: 'Purchase cost',
      dataIndex: 'supplier_order__total_price',
      width: 74,
      render: text =>
        text?.map((el, i) => (
          <div key={i} style={{ padding: '8px 0' }}>{el}</div>
        ))
    },
    net_profit: {
      title: 'Net profit',
      dataIndex: 'net_profit',
      width: 56,
    },
    assigned: {
      title: 'Order placed by/ Assigned to',
      dataIndex: 'assigned',
      width: 100,
      render: (text, record) =>
        <Select
          defaultValue={text?.id || 'n/a'}
          style={{ width: '100%' }}
          onChange={e => onUpdateAssigned(e, record)}
          disabled={!permissions?.orders_edit}
        >
          {
            orders.avList?.map(el => (
              <Select.Option key={el.id} value={el.id}>{`${el.first_name} ${el.last_name}`}</Select.Option>
            ))
          }
        </Select>
    },
  };

  useEffect(() => {
    if (!start) {
      setPaginate({
        page: 1,
        page_size: orders?.page_size
      });
      setStart(true);
    }
    props.getOrderFilters();
    props.getAvList();
    // if (orders.columnOrder) {
    //   const res = [];
    //   orders.columnOrder.forEach(el => {
    //     res.push(columns[el]);
    //   })
    //   setTableColumns(res);
    // } else {
      setTableColumns(Object.values(columns));
      props.setTableOrder(Object.keys(columns))
    // }
  }, [0])

  useEffect(() => {
    if (orders.columnOrder && orders?.filters?.internal_statuses) {
        const res = [];
        orders.columnOrder.forEach(el => {
          res.push(columns[el]);
        })
        setTableColumns(res);
      }
  }, [orders.columnOrder, orders?.filters?.internal_statuses])

  const transformValue = value => {
    if (value == null) {
      return null;
    } else if (isFinite(value)) {
      return Number(value).toFixed(2);
    } else {
      return 0
    }
  }

  useEffect(() => {
    let arr = orders.list?.results ? orders.list.results.asMutable({ deep: true }) : [];

    let yourArrayWithoutDuplicates = [...new Set(arr.map(a => a.customer_order.order_number))]

    let duplicates = [...arr]
    yourArrayWithoutDuplicates.forEach(item => {
      const i = duplicates.findIndex(el => item === el.customer_order.order_number)
      duplicates = duplicates
        .slice(0, i)
        .concat(duplicates.slice(i + 1, duplicates.length))
    })

    yourArrayWithoutDuplicates = yourArrayWithoutDuplicates.map(el =>
      arr.find(item => item.customer_order.order_number === el)
    );

    yourArrayWithoutDuplicates = yourArrayWithoutDuplicates.map(el => {

      const res = duplicates.filter(item => item.customer_order.order_number === el.customer_order.order_number);
      const newData = {
        id: [el.id],
        changeable: [el.changeable],
        ava: [el.customer_order?.image],
        customer_order__asin: [el.customer_order?.asin], //ASIN
        customer_order__purchased_at: el.customer_order?.purchased_at, //Date
        customer_order__status: [el.customer_order?.status], //Status
        supplier_order__status: [el.supplier_order?.status], //Status (supplier)
        // delivery_status: [el.supplier_order?.tracking_info?.delivery_status], //Delivery status
        delivery_status: el.supplier_order?.tracking_info?.map(el => el.delivery_status), //Delivery status
        internal_status: [el.internal_status_obj], //Internal status
        customer_order__sku: [el.customer_order?.sku], //SKU
        customer_order__order_number: el.customer_order?.order_number, //Order ID
        supplier_order__order_id: [el.supplier_order?.order_id], //Order ID (supplier)
        customer_order__shop__name: el.customer_order?.marketplace_name, //Marketplace
        supplier_source_url: [el.supplier_order?.supplier_source_url], //Source URL
        delivery_estimated_date: [el.customer_order?.delivery_end_date], //Estimated delivery date
        latest_ship_date: [el.latest_ship_date], //Latest ship date
        supplier_order__tracking_number: (el.supplier_order?.tracking_info?.map(el => el.tracking_id)), //Tracking #
        supplier_order__carrier_tracking_link: (el.supplier_order?.tracking_info?.map(el => el.carrier_tracking_link)), //url for Tracking #
        // supplier_order__carrier_tracking_link: el.supplier_order?.carrier_tracking_link?.split(','), //url for Tracking #
        supplier_order__tracking_link: el.supplier_order?.tracking_link, //url for Tracking #
        supplier_order__quantity: el.customer_order?.quantity_sold, //Qty
        customer_order__amount_paid: [transformValue(el.customer_order?.total_amount)], //Buyer paid
        supplier_order__total_price: [transformValue(el.supplier_order?.purchase_cost)], //Purchase cost
        net_revenue: transformValue(el.customer_order?.net_revenue), //Net revenue
        net_profit: transformValue(el.net_profit), //Net profit
        assigned: el.customer_order?.assigned_to,
        // ship_to: generateString(el.customer_order?.ship_to),
        net_margin: [transformValue(el.net_margin)],
        amazon_fee: [transformValue(el.customer_order?.amazon_fee)],
        refund_admin_fee: [transformValue(el.customer_order?.refund_admin_fee)],
        refund_shipping_cost: [transformValue(el.customer_order?.refund_shipping_cost)],
        yra: [transformValue(el.customer_order?.yra)],
        sra: [transformValue(el.supplier_order?.sra)],
      };
      if (!res.length) {
        return newData;
      } else {
        res.forEach(item => {
          newData.id.push(item.id);
          newData.ava.push(item.customer_order?.image);
          newData.customer_order__asin.push(item.customer_order?.asin);
          newData.customer_order__status.push(item.customer_order?.status);
          newData.supplier_order__status.push(item.supplier_order?.status);
          newData.delivery_status.push(item.supplier_order?.tracking_info?.delivery_status);
          newData.internal_status.push(item.internal_status_obj);
          newData.customer_order__sku.push(item.customer_order?.sku);
          newData.supplier_order__order_id.push(item.supplier_order?.order_id);
          newData.supplier_source_url.push(item.supplier_order?.supplier_source_url);
          newData.delivery_estimated_date.push(item.customer_order?.delivery_end_date);
          newData.latest_ship_date.push(item.latest_ship_date);
          newData.supplier_order__tracking_number = [...newData.supplier_order__tracking_number, ...(item.supplier_order?.tracking_info?.map(el => el.tracking_id))];
          newData.supplier_order__carrier_tracking_link = [...newData.supplier_order__carrier_tracking_link, ...(item.supplier_order?.tracking_info?.map(el => el.carrier_tracking_link))];
          newData.supplier_order__total_price.push(transformValue(item.supplier_order?.purchase_cost));
          newData.net_margin.push(transformValue(item.net_margin));
          newData.amazon_fee.push(transformValue(item.customer_order?.amazon_fee));
          newData.refund_admin_fee.push(transformValue(item.customer_order?.refund_admin_fee));
          newData.refund_shipping_cost.push(transformValue(item.customer_order?.refund_shipping_cost));
          newData.yra.push(transformValue(item.customer_order?.yra));
          newData.sra.push(transformValue(item.supplier_order?.sra));
          newData.changeable.push(item.changeable);
        })
        return newData;
      }
    })
    // console.log(yourArrayWithoutDuplicates)
    setData(yourArrayWithoutDuplicates);
  }, [orders.list]);

  const handleQuery = (page) => {
    let query = {
      q: searchQuery,
      ordering: sortField,
      page: page ? page : paginate.page,
      page_size: paginate?.page_size ? paginate?.page_size : orders?.page_size,
      ...filterBy
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getOrders(query);
  }

  useEffect(() => {
    if (start) {
      handleQuery();
    }
  }, [paginate, sortField])

  useEffect(() => {
    if (start) {
      handleQuery(1);
    }
  }, [searchQuery, filterBy])
  // console.log('сurrentrecor', currentRecord );
  useEffect(() => {
    if (orders.saveResult) {
      handleQuery(orders?.page);
      // console.log('orders.saveResult',orders.saveResult);
      if (currentRecord?.data?.joint) {
        const newData = {
          ...currentRecord?.data,

          net_profit: orders.saveResult.net_profit,
          net_margin: orders.saveResult.net_margin,
          net_revenue: orders.saveResult.customer_order.net_revenue,
          assigned: orders.saveResult.customer_order?.assigned_to?.id,
          comment: orders.saveResult.comment,
          // joint: currentRecord?.data?.joint.map(el=> ({
          //   ...el, 
          //   refund_admin_fee: orders.saveResult.customer_order.refund_admin_fee,
          //   refund_shipping_cost: orders.saveResult.customer_order.refund_shipping_cost,
          //   yra: orders.saveResult.customer_order.yra,
          //   sra: orders.saveResult.supplier_order.sra,
          //   internal_status: orders.saveResult.internal_status,
          // })), 
          joint: currentRecord?.data?.joint.map((el,i) => {
            const index = currentRecord.data.id?.findIndex(item => item === orders.saveResult.id)
            if (i === index) {
              return (
                {
                  ...el, 
                  refund_admin_fee: orders.saveResult.customer_order.refund_admin_fee,
                  refund_shipping_cost: orders.saveResult.customer_order.refund_shipping_cost,
                  yra: orders.saveResult.customer_order.yra,
                  sra: orders.saveResult.supplier_order.sra,
                  internal_status: orders.saveResult.internal_status,
                  product_sku: orders.saveResult.customer_order.sku,
                  supplier_source_url: orders.saveResult.supplier_order.supplier_source_url,
                }
              )
            } else {
              return ({...el})
            }
          }), 

          jointSupplier: currentRecord?.data.jointSupplier.map((el,i) => {
            const index = currentRecord.data.id?.findIndex(item => item === orders.saveResult.id)
            if (i === index) {
              return (
                {
                  ...el, 
                  supplier_platform: orders.saveResult.supplier_order.supplier_platform,
                  status: orders.saveResult.supplier_order.status,
                  order_id: orders.saveResult.supplier_order.order_id,
                  subtotal: orders.saveResult.supplier_order.subtotal,
                  purchase_cost: orders.saveResult.supplier_order.purchase_cost,
                  tax: orders.saveResult.supplier_order.tax,
                  shipping_price: orders.saveResult.supplier_order.shipping_price,
                  coupon_savings: orders.saveResult.supplier_order.coupon_savings,
                  quantity: orders.saveResult.supplier_order.quantity,
                  tracking_link: orders.saveResult.supplier_order.tracking_link,
                  ship_to: orders.saveResult.supplier_order?.customer_name ? orders.saveResult.supplier_order?.customer_name + ', ' + orders.saveResult.supplier_order?.shipping_address?.original : orders.saveResult.supplier_order?.shipping_address?.original,
                }
              )
            } else {
              return ({...el})
            }
          }),
        }      
        setCurrentRecord({...currentRecord, data: newData});
      }
      if (close) {
        setModalVisible(false);
        setCurrentRecord(null);
        setCurrentRecordId(null);
      }
    }
  }, [orders.saveResult])

  useEffect(() => {
    if (orders.saveStatusResult) {
      handleQuery(orders?.page);
      if (close && modalType !== 'orderForm') {
        setModalVisible(false);
        setCurrentRecord(null);
        setCurrentRecordId(null);
      }
    }
  }, [orders.saveStatusResult])

  //old version
  // useEffect(() => {
  //   if (data) {
  //     if (currentRecordId) {
  //       setModalType('orderForm');
  //       openForm(currentRecordId);
  //     }
  //   }
  // }, [currentRecordId, data])

  //new version
  useEffect(() => {
    if (orderData) {
      if (currentRecordId) {
        setModalType('orderForm');
        openForm(currentRecordId);
      }
    }
  }, [currentRecordId, orderData])
 
  useEffect(() => {
    if (currentAsin) {
      setModalType('history');
      setModalVisible(true);
    }
  }, [currentAsin])

  useEffect(() => {
    if (state) {
        setSearchQuery(state)
    }
    if (state && state === searchQuery && orders?.list.results?.length === 1 && data) {
      document.getElementById("order_id").click();
    }
  }, [state, data])

  const pageSizeHandler = value => {
    setPaginate({
      page: 1,
      page_size: value
    });
  }

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   }
  // }

  const handleTableSort = ({ order, field }) => {
    if (order && field) {
      const ordering = order === 'ascend' ? `${field}` : `-${field}`
      setSortField(ordering)
    } else setSortField(null)
  }

  const onPaginate = (page, page_size) => {
    setPaginate({ page, page_size });
  }

  const handleSearch = value => {
    let val = value.trim().toLowerCase();
    if (val !== '') setSearchQuery(value)
    else setSearchQuery(null)
  }

  const onUpdateStatus = (status, record, index) => {
    if (status === 'refunded') {
      setCurrentRecord({
        data: record,
        index
      });
      setModalType('refundable');
      setModalVisible(true);
    } else {
      props.updateInternalStatus({
        order_id: record.id[index],
        status
      });
    }
  }

  const onUpdateAssigned = (assigned, record) => {
    props.changeAssigned({
      order_id: record.customer_order__order_number,
      assigned
    });
  }

  const generateString = obj => {
    if (obj) {
      let res = [];
      if (obj.name) res.push(obj.name);
      if (obj.street1) res.push(obj.street1);
      if (obj.street2) res.push(obj.street2);
      if (obj.city) res.push(obj.city);
      if (obj.state) res.push(obj.state);
      if (obj.postal_code) res.push(obj.postal_code);
      if (obj.phone) res.push(obj.phone);
      return res.join(', ');
    } else {
      return '';
    }
  }

  const beforeOpenForm = (recordId, record) => {
    setCurrentRecordId(recordId);
    setOrderData(record);
    // setOrderData(record.id.map((el, i)=> {
    //   let newData = {};
    //   data.filter(order => {
    //     if (order.id[i] === el) {
    //       return newData = {...newData, ...order}
    //     } else {
    //       return null
    //     }
    //   })
    //   return newData;
    // }));
  }

  const openForm = recordId => {
    setSpinActivator(true);
    props.getSupplierList();
    const res = {
      id: [],
      ava: [],
      joint: [],
      date: null,
      assigned: null,
      net_profit: null,
      net_revenue: null,
      net_margin: null,
      amazon_fee: null,

      jointAmazon: [],
      order_id_: null,
      paid: null,
      buyer_name: null,
      buyer_email: null,
      total_price: null,
      quantity_: null,
      item_sale_price: null,
      adjustment_amount: null,
      total_tax: null,
      total_shipping: null,
      marketplace: null,
      changeable: [],

      jointSupplier: [],
    }
    recordId.forEach(id => {
      const item = orders.list?.results?.asMutable({ deep: true }).find(el => el.id === id);

      res.id.push(item.id);
      res.ava.push(item.customer_order?.image);
      res.changeable.push(item.changeable);
      res.joint.push({
        asin: item.customer_order?.asin,
        supplier_source_url: item.supplier_order?.supplier_source_url,
        title: item.customer_order?.title,
        product_sku: item.customer_order?.sku,
        internal_status: item.internal_status_obj?.id,
        refund_admin_fee: transformValue(item.customer_order?.refund_admin_fee),
        refund_shipping_cost: transformValue(item.customer_order?.refund_shipping_cost),
        yra: transformValue(item.customer_order?.yra),
        sra: transformValue(item.supplier_order?.sra),
      });
      res.date = item.customer_order?.purchased_at ? moment.utc(item.customer_order?.purchased_at).format('MM/DD/YYYY, h:mm a') : null;
      res.assigned = item.customer_order?.assigned_to?.id;
      res.net_profit = transformValue(item.net_profit);
      res.net_margin = transformValue(item.net_margin);
      res.net_revenue = transformValue(item.customer_order?.net_revenue);
      res.amazon_fee = transformValue(item.customer_order?.amazon_fee);

      res.jointAmazon.push({
        status_: item.customer_order?.status,
        ship_to_: generateString(item.customer_order?.ship_to),
        delivery_estimated_date: item.customer_order?.delivery_end_date ? moment.utc(item.customer_order?.delivery_end_date).format('MM/DD/YYYY, h:mm a') : null,
        latest_ship_date: item.latest_ship_date ? moment.utc(item.latest_ship_date).format('MM/DD/YYYY, h:mm a') : null
      });
      res.order_id_ = item.customer_order?.order_number;
      res.paid = item.customer_order?.paid;
      res.buyer_name = item.customer_order?.buyer_info?.name;
      res.buyer_email = item.customer_order?.buyer_info?.email;
      res.total_price = transformValue(item.customer_order?.total_amount);
      res.quantity_ = item.customer_order?.quantity_sold;
      res.item_sale_price = transformValue(item.customer_order?.item_sale_price);
      res.adjustment_amount = transformValue(item.customer_order?.adjustment_amount);
      res.total_tax = transformValue(item.customer_order?.tax);
      res.total_shipping = transformValue(item.customer_order?.shipping);
      res.marketplace = item.customer_order?.marketplace_name;

      res.jointSupplier.push({
        supplier_platform: item.supplier_order?.supplier_platform,
        status: item.supplier_order?.status,
        order_id: item.supplier_order?.order_id,
        tracking_link: item.supplier_order?.tracking_link,
        carrier_tracking_link: item.supplier_order?.carrier_tracking_link,
        ship_to: item.supplier_order?.customer_name ? item.supplier_order?.customer_name + ', ' + item.supplier_order?.shipping_address?.original : item.supplier_order?.shipping_address?.original,
        customer_name: item.supplier_order?.customer_name,
        quantity: item.supplier_order?.quantity,
        tax: transformValue(item.supplier_order?.tax),
        subtotal: transformValue(item.supplier_order?.subtotal),
        purchase_cost: transformValue(item.supplier_order?.purchase_cost),
        shipping_price: transformValue(item.supplier_order?.shipping_price),
        coupon_savings: transformValue(item.supplier_order?.coupon_savings),
        tracking_info: item.supplier_order?.tracking_info?.map(el => (
            {
              ...el,
              shipping_date: el.shipping_date && (el.shipping_date !== 'Invalid date') ? moment.utc(el.shipping_date) : null,
              shipping_time: el.shipping_time && (el.shipping_time !== 'Invalid date') ? moment.utc(el.shipping_time, "HH:mm:ss") : null,
            }
          ))
        });
      res.comment = item.comment;
    })
    // console.log(res)
    setCurrentRecord({
      data: res,
      index: null
    });
    setSpinActivator(false);
    setModalVisible(true);
  }

  const changeFilter = (field, value) => {
      setFilterBy({
        ...filterBy,
        [field]: value
      })
  }

  const changeFilterDate = values => {
    setFilterBy({
      ...filterBy,
      date_from: values ? values[0].format('YYYY-MM-DD') : null,
      date_to: values ? values[1].format('YYYY-MM-DD') : null,
    })
  }

  const ModalContent = {
    orderForm: {
      width: '80vw',
      children: () => (
        <Spin size="large" spinning={orders.fetching || orders.saveFetching}>
          <OrderForm
            data={currentRecord?.data}
            suppliers={products.supplierList}
            statuses={orders.filters?.internal_statuses}
            avList={orders.avList}
            shippingServices={orders.shippingServices}
            onSubmit={props.updateOrder}
            setClose={setClose}
            // changeAssigned={props.changeAssigned}
            getShippingServices={props.getShippingServices}
            permissions={permissions}
          />
        </Spin>
      ),
      okButtonProps: {
        htmlType: "submit",
        type: "primary",
        className: "btn-primary",
        form: "supplier-order-form",
        disabled: orders.fetching || orders.saveFetching || !permissions?.orders_card_edit
      }
    },
    refundable: {
      width: 420,
      title: `Order ${currentRecord?.data?.customer_order__order_number}`,
      children: () => (
        <Spin size="large" spinning={orders.saveFetching}>
          <RefundableForm
            init={currentRecord}
            onSubmit={props.updateOrder}
          />
        </Spin>
      ),
      okButtonProps: {
        htmlType: "submit",
        type: "primary",
        className: "btn-primary",
        form: "refundable-form",
        disabled: orders.saveFetching
      }
    },
    history: {
      width: 620,
      title: `Product history`,
      isFooter: true,
      children: () => (
          <HistoryPopUp
            asin={currentAsin}
          />
      ),
      okButtonProps: {
        htmlType: "submit",
        type: "primary",
        className: "btn-primary",
        // form: "refundable-form",
        // disabled: orders.saveFetching
      }
    }
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const columns = [...tableColumns];
      const item = columns.splice(fromIndex, 1)[0];
      columns.splice(toIndex, 0, item);
      setTableColumns(columns);
      props.setTableOrder(columns.map(el => el.dataIndex));
    },
    nodeSelector: "th"
  };

  return (
    <div className="orders-page content-page">
      <Spin size="large" spinning={spinActivator}></Spin>
      <div className="table-options">
        <div className="table-options-inputs">
          <Search
            placeholder="Search"
            className="search-input"
            allowClear
            onSearch={handleSearch}
            suffix={<SearchIcon />}
          />
          < SelectComponent data={orders.filters?.customer_statuses}
            allowClear
            onChange={e => changeFilter('customer_order__status', e)}
            placeholder="Status"
          />
          <SelectComponent data={orders.filters?.suppliers}
            allowClear
            onChange={e => changeFilter('supplier_order__supplier_platform', e)}
            placeholder="Supplier"
          />
          {/* <SelectComponent data={orders.filters?.shops}
            allowClear
            onChange={e => changeFilter('customer_order__shop__name', e)}
            placeholder="Marketplace"
          /> */}
          <SelectComponent data={orders.filters?.supplier_statuses}
            allowClear
            onChange={e => changeFilter('supplier_order__status', e)}
            placeholder="Status (supplier)"
          />
          <SelectComponent data={orders.filters?.internal_statuses}
            extendedName={true}
            allowClear
            onChange={e => changeFilter('internal_status', e)}
            placeholder="Internal status"
          />
          <RangePickerComponent
            onChange={changeFilterDate}
          />
        </div >
        <TableOptionsActions
          defaultValue={orders.page_size}
          pageSizeHandler={pageSizeHandler}
        />
      </div >
      <div className="table-options">
        <div className="table-options-inputs">
          <SelectComponent data={productFilter}
            onChange={e => changeFilter('all', e)}
            defaultValue={false}
          />
          <SelectComponent
            showSearch
            extendedFullName={true}
            data={orders.avList}
            allowClear
            onChange={e => changeFilter('assigned_to', e)}
            placeholder="VA"
          />
        </div>
      </div>
      <ReactDragListView.DragColumn
        {...dragProps}
        nodeSelector="th"
      >
        <TableComponent
          loading={orders.fetching}
          data={data}
          columns={tableColumns}
          onSort={handleTableSort}
          // scrollY="72Vh"
        // selection={rowSelection}
        />
      </ReactDragListView.DragColumn>
      <PaginationComponent
        onChange={(page, page_size) => onPaginate(page, page_size)}
        total={orders.list?.count}
        current={orders.page}
        pageSize={orders.page_size}
      />
      <Modal
        wrapClassName="modal-wrap"
        className="modal"
        width={ModalContent[modalType]?.width}
        title={ModalContent[modalType]?.title}
        visible={modalVisible}
        closable={ModalContent[modalType]?.isFooter ? true : false}
        children={ModalContent[modalType]?.children()}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          setCurrentRecord(null);
          setCurrentRecordId(null);
          setCurrentAsin(null);
          setClose(true);
        }}
        cancelText="Cancel"
        okButtonProps={ModalContent[modalType]?.okButtonProps}
        cancelButtonProps={cancelButtonProps}
        destroyOnClose={true}
        footer={ModalContent[modalType]?.isFooter}
      />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    role: state.auth.role,
    orders: state.orders,
    products: state.products,
    permissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOrders: (query) => dispatch(OrdersActions.ordersRequest(query)),
  updateOrder: (data) => dispatch(OrdersActions.orderSaveRequest(data)),
  getOrderFilters: () => dispatch(OrdersActions.orderFiltersRequest()),
  updateInternalStatus: (data) => dispatch(OrdersActions.internalStatusSaveRequest(data)),
  setTableOrder: (data) => dispatch(OrdersActions.setTableOrder(data)),
  changeAssigned: (data) => dispatch(OrdersActions.assignedSaveRequest(data)),
  getAvList: () => dispatch(OrdersActions.avListRequest()),
  getShippingServices: () => dispatch(OrdersActions.shippingServicesRequest()),

  getSupplierList: () => dispatch(ProductsActions.suppliersRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage)
