import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  usersRequest: ['query'],
  usersSuccess: ['list'],

  userRequest: ['id'],
  userSuccess: ['selectedUser'],

  userSaveRequest: ['data'],
  userSaveSuccess: ['saveResult'],

  userDeleteRequest: ['id'],
  userDeleteSuccess: ['deleteResult'],

  userBlockRequest: ['id'],
  userBlockSuccess: ['blockResult'],

  userUnblockRequest: ['id'],
  userUnblockSuccess: ['blockResult'],

  userRolesRequest: null,
  userRolesSuccess: ['roles'],
 
  getPermissionsListRequest: null,
  getPermissionsListSuccess: ['permissionsList'],

  updatePermissionsListRequest: ['data'],
  updatePermissionsListSuccess: ['permissionsList'],

  userTableSettingsReset: null,

  usersReset: null,
  userSearchReset: null,

  resultsReset: null,

  userFetchingReset: null
})

export const UsersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,
  selectedUser: null,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  blockResult: null,
  blockFetching: false,

  roles: null,
  permissionsList: null,

  page: 1,
  page_size: 10,
  ordering: '',
  search: ''

})

/* ------------- Selectors ------------- */
export const UsersSelectors = {
  getState: state => state.users,
}

/* ------------- Reducers ------------- */


export const usersRequest = (state, { query }) => {
  const newState = {
    fetching: true
  };
  if (query.page) newState.page = query.page;
  if (query.page_size) newState.page_size = query.page_size;
  if (query.ordering) newState.ordering = query.ordering;
  newState.search = query.q || '';
  return state.merge(newState)
}

export const usersSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const userRequest = (state, { id }) => {
  return state.merge({ fetching: true })
}

export const userSuccess = (state, { selectedUser }) => {
  return state.merge({ fetching: false, selectedUser })
}

export const userSaveRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const userSaveSuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const userDeleteRequest = (state, { id }) => {
  return state.merge({ deleteFetching: true })
}

export const userDeleteSuccess = (state, { deleteResult }) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const userBlockRequest = (state, { id }) => {
  return state.merge({ blockFetching: true })
}

export const userBlockSuccess = (state, { blockResult }) => {
  return state.merge({ blockFetching: false, blockResult })
}

export const userUnblockRequest = (state, { id }) => {
  return state.merge({ blockFetching: true })
}

export const userUnblockSuccess = (state, { blockResult }) => {
  return state.merge({ blockFetching: false, blockResult })
}

export const userRolesRequest = (state) => {
  return state.merge({ fetching: true })
}

export const userRolesSuccess = (state, { roles }) => {
  return state.merge({ fetching: false, roles })
}

export const getPermissionsListRequest = (state) => {
  return state.merge({ fetching: true })
}

export const getPermissionsListSuccess = (state, { permissionsList }) => {
  return state.merge({ fetching: false, permissionsList })
}

export const updatePermissionsListRequest = (state, { data }) => {
  return state.merge({ fetching: true, data })
}

export const updatePermissionsListSuccess = (state, { permissionsList }) => {
  return state.merge({ fetching: false, permissionsList })
}

export const userTableSettingsReset = (state) => {
  const newState = {
    page: 1,
    ordering: '',
    search: '',
  };
  return state.merge(newState)
}

export const usersReset = (state) => {
  return INITIAL_STATE
}

export const userSearchReset = (state) => {
  return state.merge({ search: '' })
}

export const resultsReset = (state) => {
  return state.merge({ saveResult: null, deleteResult: null, blockResult: null })
}

export const userFetchingReset = (state) => {
  return state.merge({ fetching: false, saveFetching: false, deleteFetching: false, blockFetching: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_REQUEST]: usersRequest,
  [Types.USERS_SUCCESS]: usersSuccess,

  [Types.USER_REQUEST]: userRequest,
  [Types.USER_SUCCESS]: userSuccess,

  [Types.USER_SAVE_REQUEST]: userSaveRequest,
  [Types.USER_SAVE_SUCCESS]: userSaveSuccess,

  [Types.USER_DELETE_REQUEST]: userDeleteRequest,
  [Types.USER_DELETE_SUCCESS]: userDeleteSuccess,

  [Types.USER_BLOCK_REQUEST]: userBlockRequest,
  [Types.USER_BLOCK_SUCCESS]: userBlockSuccess,

  [Types.USER_UNBLOCK_REQUEST]: userUnblockRequest,
  [Types.USER_UNBLOCK_SUCCESS]: userUnblockSuccess,

  [Types.USER_ROLES_REQUEST]: userRolesRequest,
  [Types.USER_ROLES_SUCCESS]: userRolesSuccess,

  [Types.GET_PERMISSIONS_LIST_REQUEST]: getPermissionsListRequest,
  [Types.GET_PERMISSIONS_LIST_SUCCESS]: getPermissionsListSuccess,
 
  [Types.UPDATE_PERMISSIONS_LIST_REQUEST]: updatePermissionsListRequest,
  [Types.UPDATE_PERMISSIONS_LIST_SUCCESS]: updatePermissionsListSuccess,

  [Types.USER_TABLE_SETTINGS_RESET]: userTableSettingsReset,

  [Types.USERS_RESET]: usersReset,
  [Types.USER_SEARCH_RESET]: userSearchReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.USER_FETCHING_RESET]: userFetchingReset,
})
