import React from 'react'
import { DatePicker } from 'antd'

import CalendarIcon from '../Icons/Calendar'
import LineIcon from '../Icons/Line'

import './style.less'

const { RangePicker } = DatePicker;

const RangePickerComponent = props => {
  return (
    <RangePicker
      {...props}
      format="MM/DD/YYYY"
      placeholder={['From date', 'To date']}
      separator={<LineIcon />}
      suffixIcon={<CalendarIcon />}
      style={{ width: 'auto' }}
    />
  );
};

export default RangePickerComponent;
