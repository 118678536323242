
const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="7.5" stroke="#a6aaba" strokeWidth="1.8" />
      <path d="M21 21L15.5 15.5" stroke="#a6aaba" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default SearchIcon;
