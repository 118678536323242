import React from 'react'
import { Select } from 'antd'

import './style.less'
import Arrowdownselect from '../Icons/Arrowdownselect'

const { Option } = Select;

const SelectComponent = (props) => {
  const { data, extendedFullName, extendedName, onChange, placeholder, defaultValue, className, allowClear, showSearch, width, marginRight } = props;

  let options;

  if (extendedFullName) {
    options = data?.map(el => <Option key={el.id} value={el.id}>{`${el.first_name} ${el.last_name}`}</Option>)
  } else if (extendedName) {
    options = data?.map(el => <Option key={el.id} value={el.id}>{el.name}</Option>)
  } else {
    options = data?.map(el => <Option key={el.value != null ? el.value : el} value={el.value != null ? el.value : el}>{el.name || el}</Option>)
  }

  return (
    <Select
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={className}
      allowClear={allowClear}
      showSearch={showSearch}
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      suffixIcon={<Arrowdownselect />}
      style={{ width, marginRight }}
    >
      {options}
    </Select>
  );
};

export default SelectComponent;
