import React from 'react'
import { Table } from 'antd'

import './style.less'

const TableComponent = (props) => {
  const { loading, data, columns, onSort, selection, scrollY } = props

  return (
    <div className="table-wrapper">
      <Table
        className="table"
        components={{
          table: ({ className, children, ...other }) => (
            <table
              {...other}
            >
              {children}
            </table>
          ),
        }}
        //change default text if table is empty
        locale={{ emptyText: 'Not found. Try another search query' }}
        loading={loading}
        bordered
        dataSource={data}
        //fix header
        sticky={true}
        //disable native pagination(pages)
        pagination={false}
        columns={columns}
        //set unique react key for every tr
        rowKey={record => record.id}
        //table height
        scroll={{ x: 1000, y: scrollY || '78Vh' }}
        style={{ height: '100%' }}
        rowSelection={selection ? { type: 'checkbox', ...selection } : null}
        showSorterTooltip={false}
        //antd table function for sorting (parent handleSort)
        onChange={(pagination, filters, sorter, extra) => onSort(sorter)}
      />
    </div>
  );
};

export default TableComponent;
