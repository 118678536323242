/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Layout } from 'antd'
import {
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'

import PrivateRoute from '../../Components/PrivateRoute'
import AuthActions from '../../Redux/AuthRedux'
import ErrorsActions from '../../Redux/ErrorsRedux'
import OrdersActions from '../../Redux/OrdersRedux'
import ProductsActions from '../../Redux/ProductsRedux'
import UsersActions from '../../Redux/UsersRedux'
import NotesActions from '../../Redux/NotesRedux'

import MainPage from '../MainPage'
import OrdersPage from '../OrdersPage'
import ProductsPage from '../ProductsPage'
import ProductCardPage from '../ProductCardPage'
import UploadsPage from '../UploadsPage'
import NotesPage from '../NotesPage'
import ProfilePage from '../ProfilePage'
import StartupPage from '../StartupPage'
import NotFoundPage from '../NotFoundPage'
import UsersPage from '../UsersPage'
import DashboardPage from '../DashboardPage'
import PermissionsPage from '../UsersPage/components/PermissionsPage'

import errorHandler from '../../Utils/errorHandler'

function HomePage(props) {
  const {
    auth,
    // role,
    error,
    startup,
    permissions,
  } = props;

  useEffect(() => {
    props.resetOrdersTable();
    props.resetProductsTable();
    props.resetUsersTable();
    props.resetNotes();

    props.notesSyncToggle(true);
    return function cleanup() {
      props.notesSyncToggle(false);
    }
  }, [0]);

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  if (auth.userInfo === null || startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  }

  return (
    <Layout style={{ overflowY: 'hidden' }}>
      <MainPage>
        <Switch>
          <PrivateRoute path="/home/orders" isAllowed={permissions?.orders_view} component={OrdersPage} />
          <PrivateRoute path="/home/products" isAllowed={permissions?.products_view} component={ProductsPage} />
          <PrivateRoute path="/home/product/:id" isAllowed={permissions?.products_card_view} component={ProductCardPage} />
          <PrivateRoute path="/home/uploads" isAllowed={true} component={UploadsPage} />
          <PrivateRoute path="/home/notifications" isAllowed={true} component={NotesPage} />
          <PrivateRoute path="/home/profile" isAllowed={true} component={ProfilePage} />
          <PrivateRoute path="/home/dashboard" isAllowed={permissions?.dashboard} component={DashboardPage} />
          <PrivateRoute path="/home/users" isAllowed={permissions.create_user || permissions.block_user || permissions.edit_user || permissions.delete_user} component={UsersPage} />
          <PrivateRoute path="/home/permissions" isAllowed={permissions.role === 'Admin'} component={PermissionsPage} />
          <Route path="/home/*" component={NotFoundPage} />
        </Switch>
      </MainPage>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    // role: state.auth.role,
    permissions: state.auth.permissions,
    startup: state.startup,
    error: state.errors.data,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  resetError: () => dispatch(ErrorsActions.errorReset()),
  resetOrdersTable: () => dispatch(OrdersActions.orderTableSettingsReset()),
  resetProductsTable: () => dispatch(ProductsActions.productTableSettingsReset()),
  resetUsersTable: () => dispatch(UsersActions.userTableSettingsReset()),
  resetNotes: () => dispatch(NotesActions.notesSettingReset()),
  notesSyncToggle: (syncIsStart) => dispatch(NotesActions.notesSyncToggle(syncIsStart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
