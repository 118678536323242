import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import AuthApi from '../Services/AuthApi'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password', 'pathname', 'hash'],
  signInSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  signInFailure: ['error'],

  authErrorReset: null,
  authFetchingReset: null,

  updateTokens: ['accessToken', 'refreshToken'],

  logout: null,

  resultsReset: null,

  getUserPermissionsRequest: null,
  getUserPermissionsSuccess: ['permissions'],
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  accessToken: null,
  refreshToken: null,
  error: null,
  fetching: false,

  role: null,

  permissions: null,
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUserInfo: state => state.auth.userInfo,
  getAccessToken: state => state.auth.accessToken,
  getRefreshToken: state => state.auth.refreshToken,
  getRole: state => state.auth.role
}

/* ------------- Reducers ------------- */

export const signInRequest = (state, { email, password, pathname, hash }) => {
  return state.merge({ fetching: true, error: null })
}

export const signInSuccess = (state, { userInfo, accessToken, refreshToken }) => {
  const role = userInfo.role?.id;
  return state.merge({ fetching: false, error: null, userInfo, accessToken, refreshToken, role })
}

export const signInFailure = (state, { error }) => {
  return state.merge({ error, fetching: false })
}

export const authErrorReset = (state) => {
  return state.merge({ error: null, fetching: false })
}

export const authFetchingReset = (state) => {
  return state.merge({ fetching: false })
}

export const updateTokens = (state, { accessToken, refreshToken }) => {
  return state.merge({ accessToken, refreshToken })
}

export const logout = (state) => {
  AuthApi.updateAccessToken(null, null);
  return INITIAL_STATE
}

export const getUserPermissionsRequest = (state) => {
  return state.merge({ fetching: true })
}

export const getUserPermissionsSuccess = (state, { permissions }) => {
  return state.merge({ fetching: false, permissions })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.AUTH_ERROR_RESET]: authErrorReset,
  [Types.AUTH_FETCHING_RESET]: authFetchingReset,

  [Types.UPDATE_TOKENS]: updateTokens,

  [Types.LOGOUT]: logout,

  [Types.GET_USER_PERMISSIONS_REQUEST]: getUserPermissionsRequest,
  [Types.GET_USER_PERMISSIONS_SUCCESS]: getUserPermissionsSuccess,
})
