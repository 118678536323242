/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Button, Menu, Modal, Spin, Tooltip, Input, message, InputNumber, Form, Col, Row} from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import SelectComponent from '../../Components/SelectComponent'
import TableOptionsActions from '../../Components/TableOptionsActions'
import TableComponent from '../../Components/TableComponent'
import PaginationComponent from '../../Components/PaginationComponent'
import ProductsActions from '../../Redux/ProductsRedux'
import ProductCreateForm from './components/ProductCreateForm'
import ProductUploadComponent from './components/ProductUploadComponent'
import HistoryPopUp from '../OrdersPage/components/HistoryPopUp'

import SearchIcon from '../../Components/Icons/Search'
import UploadIcon from '../../Components/Icons/Upload'
import ArrowDownSelect from '../../Components/Icons/Arrowdownselect'
import History from '../../Components/Icons/history'

const { Search } = Input;

const editData = [
  { key: 0, value: 'Delete from platform and Amazon' },
  // { key: 1, value: 'Change product quantity' },
  { key: 2, value: 'Make listing inactive on Amazon' },
  { key: 3, value: 'Make listing active on Amazon' },
  { key: 4, value: 'Change handling time' },
];

const modalProps = {
  centered: true,
  closable: false,
  destroyOnClose: true,
  width: 360,
};

const okButtonProps = {
  type: "primary",
  className: "btn-primary",
};

const cancelButtonProps = {
  type: "primary white",
  className: "btn-primary",
};

const ProductsPage = props => {
  const { products, orders, permissions } = props;
  
  const history = useHistory();

  const [start, setStart] = useState(false);
  const [data, setData] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);

  const [action, setAction] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [paginate, setPaginate] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [qty, setQty] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [active, setActive] = useState(false);
  const [fileListData, setFileListData] = useState([]);
  const [currentAsin, setCurrentAsin] = useState(null);

  const [filterBy, setFilterBy] = useState({
    active_status: null,
    supplier: null,
  });

  useEffect(() => {
    if (!start) {
      setPaginate({
        page: 1,
        page_size: products.page_size
      });
      setStart(true);
    }
  }, [0])

  useEffect(() => {
    let arr = products.list?.results ? products.list.results.asMutable({ deep: true }) : [];
    arr = arr.map(el => ({
      id: el.oms_id,
      ava: el.image,
      asin: el.asin,
      sku: el.sku,
      upc: el.upc,
      title: el.title,
      product_url: el.product_url,
      status: el.status,
      active_status: el.active_status,
      listed_by: el.listed_by,
      created: el.listed_date,
    }))
    setData(arr);
  }, [products.list]);

  const handleQuery = page => {
    const query = {
      q: searchQuery,
      ordering: sortField,
      page: page ? page : paginate.page,
      page_size: paginate.page_size,
      ...filterBy
    };
    for (let key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    props.getProducts(query);
  }

  useEffect(() => {
    if (start) {
      handleQuery();
    }
  }, [paginate, sortField])

  useEffect(() => {
    if (start) {
      handleQuery(1);
    }
  }, [searchQuery, filterBy])

  useEffect(() => {
    if (action != null) {
      switch (action) {
        case '0': {
          setModalType('delete');
          break;
        }
        case '1': {
          setModalType('changeQty');
          break;
        }
        case '2': {
          const data = {
              edit_ids: selected,
              status: "inactive"
              };
          props.productChangeStatus(data)
          break;
        }
        case '3': {
          const data = {
              edit_ids: selected,
              status: "active"
              };
          props.productChangeStatus(data)
          break;
        }
        case '4': {
          setModalType('changeHandlingTime');
          break;
        }
        default: break;
      }
    }
  }, [action])

  useEffect(() => {
    if (products.saveResult) {
      setModalType(null);
      props.getProducts({
        page: 1,
        page_size: paginate.page_size
      });
    }
  }, [products.saveResult])

  useEffect(() => {
    if (products.saveHandlingTimeResult) {
      setModalType(null);
      setAction(null);
    }
  }, [products.saveHandlingTimeResult])

  useEffect(() => {
    if (products.deleteResult) {
      setModalType(null);
      setAction(null);
      props.getProducts({
        page: 1,
        page_size: paginate.page_size
      });
    }
  }, [products.deleteResult])

  useEffect(() => {
    if (products.editQtyResult) {
      setModalType(null);
      setAction(null);
      setSelected([]);
      props.getProducts({
        page: 1,
        page_size: paginate.page_size
      });
    }
  }, [products.editQtyResult])

  useEffect(() => {
    if (products.uploadResult) {
      setUploadData(null);
      props.getUploadProductData();
    }
  }, [products.uploadResult])

  useEffect(() => {
    if (products.changeStatusResult) {
      if (products.changeStatusResult.message) {
        message.warning(products.changeStatusResult.message, 5)
      }
      const query = {
        q: searchQuery,
        ordering: sortField,
        page: paginate?.page ? paginate?.page : 1,
        page_size: paginate?.page_size ? paginate?.page_size : 10,
        ...filterBy
      };
      for (let key in query) {
        if (!query[key]) {
          delete query[key];
        }
      }
      props.getProducts(query);
    }
  }, [products.changeStatusResult])

  useEffect(() => {
    if (modalType) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [modalType])

  const pageSizeHandler = value => {
    setPaginate({
      page: 1,
      page_size: value
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelected(selectedRowKeys);
    },
    selectedRowKeys: selected
  }

  const deleteRows = () => {
    const data = {
      delete_ids: selected
    }
    props.deleteProducts(data);
  }

  const changeQuantity = () => {
    const data = {
      edit_ids: selected,
      quantity: qty
    }
    props.changeProductQuantity(data);
  }

  const handleTableSort = ({ order, field }) => {
    if (order && field) {
      const ordering = order === 'ascend' ? `${field}` : `-${field}`
      setSortField(ordering)
    } else setSortField(null)
  }

  const onPaginate = (page, page_size) => {
    setPaginate({ page, page_size });
  }

  const onClose = () => {
    setModalType(null);
    setAction(null);
    setQty(null);
  }

  const handleSearch = value => {
    let val = value.trim().toLowerCase();
    if (val !== '') setSearchQuery(value)
    else setSearchQuery(null)
  }

  const openCreateModal = () => {
    props.getStoreList();
    props.getSupplierList();
    setModalType('create');
  }

  const generateStatus = status => {
    let color = status || 'orange';
    return (
      <div className="status-item" style={{ background: color }}></div>
    )
  }

  const changeFilter = (field, value) => {
    setFilterBy({
      ...filterBy,
      [field]: value
    })
  }

  const openHistory = (asin) => {
    setCurrentAsin(asin);
  }

  useEffect(() => {
    if (currentAsin) {
      setModalType('history');
      setModalVisible(true);
    }
  }, [currentAsin])

  const tableColumns = [
    {
      title: 'ASIN',
      dataIndex: 'asin',
      width: 140,
      render: (text, record) => (
        <div className="twin-cell">
          <div className="table-product-preview">
            {record.ava ?
              <img src={record.ava} alt={record.ava} />
              :
              <div className="no-image"></div>
            }
          </div>
          <a href={record.product_url} target="_blank" rel="noreferrer"
            className="ant-table-cell ant-table-cell--underline ant-table-cell--link"
          >
            {text}
          </a >
          <span style={{width: 6}}></span>
          <Tooltip title={'See product history'}>
            <a
              style={{display: 'flex', alignItems: 'center'}}
              onClick={() => {
                return (permissions?.products_card_view ? openHistory(text) : null)
              }}
            >
              <History />
            </a>
          </Tooltip>
        </div>
      )
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: 112,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <a className="ant-table-cell ant-table-cell--underline ant-table-cell--link"
            onClick={() => {
            return (permissions?.products_card_view ? history.push(`/home/product/${record.id}`) : null)
            }}
          >{text}</a>
        </div>
      )
    },
    // {
    //   title: 'UPC',
    //   dataIndex: 'upc',
    //   width: 140,
    //   sorter: true,
    //   render: text => <Tooltip title={text}>{text}</Tooltip>
    // },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 200,
      render: text => <Tooltip title={text} placement="topLeft">{text}</Tooltip>
    },
    // {
    //   title: 'Product URL',
    //   dataIndex: 'product_url',
    //   width: 190,
    //   render: text => (
    //     <Tooltip title={text}>
    //       <a href={text} target="_blank" rel=" noreferrer"
    //         className="ant-table-cell ant-table-cell--underline ant-table-cell--link">
    //         {text}
    //       </a>
    //     </Tooltip >
    //   )
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: text => generateStatus(text)
    },
    {
      title: 'Manual inactivation',
      dataIndex: 'active_status',
      width: 100,
      render: text => <span>{text}</span>
    },
    {
      title: 'Listed by',
      dataIndex: 'listed_by',
      width: 120,
      sorter: true,
      render: text => <span>{text ? `${text.first_name} ${text.last_name}` : null}</span>
    },
    {
      title: 'Listed date',
      dataIndex: 'created',
      width: 120,
      sorter: true,
      render: text =>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{text && moment.utc(text).format('MM/DD/YYYY')}</span>
          <span>{text && moment.utc(text).format('LT').toLowerCase()}</span>
        </div>
    },
    // {
    //   title: 'oms ID',
    //   dataIndex: 'id',
    //   width: 140,
    //   sorter: true,
    //   render: text => <Tooltip title={text}>{text}</Tooltip>
    // },
  ]

  const menu = (
    <Menu onClick={e => setAction(e.key)}>
      {
        editData.map(el => (
          <Menu.Item key={el.key}>{el.value}</Menu.Item>
        ))
      }
    </Menu>
  );

  const onFinish = values => {
    const data = { edit_ids: selected, ...values };
    props.saveHandlingTime(data);
  }

  const ModalContent = {
    create: {
      title: 'Create product',
      modalProps: {
        ...modalProps,
        width: 520,
      },
      children: () => (
        <Spin size="large" spinning={products.fetching}>
          <ProductCreateForm
            shops={products.storeList}
            suppliers={products.supplierList}
            onSubmit={props.saveProduct}
          />
        </Spin>
      ),
      okText: 'Submit',
      okButtonProps: {
        ...okButtonProps,
        htmlType: "submit",
        form: "product-create-form",
        disabled: products.fetching
      },
      cancelText: 'Cancel',
      onCancel: () => setModalType(null),
    },
    delete: {
      modalProps,
      children: () => (
        <div className="delete-title">
          <span>Are you sure you want to delete selected products?</span>
        </div>
      ),
      okText: 'Confirm',
      okButtonProps,
      onOk: deleteRows,
      cancelText: 'Cancel',
      onCancel: onClose,
    },
    changeQty: {
      title: 'Change product quantity',
      modalProps,
      children: () => (
        <div className="delete-title">
          <span>Are you sure you want to delete selected products?</span>
        </div>
      ),
      okText: 'Confirm',
      okButtonProps,
      onOk: changeQuantity,
      cancelText: 'Cancel',
      onCancel: onClose,
    },
    changeHandlingTime: {
      title: 'Change Handling Time',
      modalProps,
      width: 400,
      children: () => (
        <Form id="change-handling-time-form" className="custom-modal-form" 
          onFinish={onFinish} layout="vertical" requiredMark={false} initialValues={null}
        >
          <Row gutter={16} style={{ width: '80%' }}>
            <Col span={24}>
              <Form.Item
                label="Handling time"
                name="handling_time"
              >
                <InputNumber step="1" />
              </Form.Item>
            </Col>
            </Row>
        </Form>
      ),
      cancelText: 'Cancel',
      onCancel: onClose,
      okButtonProps: {
        ...okButtonProps,
        htmlType: 'submit',
        form: 'change-handling-time-form',
        disabled: products.saveHandlingTimeResult
      }
    },
    upload: {
      title: 'Upload products',
      modalProps: {
        ...modalProps,
        width: 800,
      },
      children: () => (
        <Spin size="large" spinning={products.uploadFetching}>
          <ProductUploadComponent
            uploadList={products.uploadList}
            uploadResult={products.uploadResult}
            getUploadProductData={props.getUploadProductData}
            changeUploadData={setUploadData}
            fileListData={fileListData}
            setFileListData={setFileListData}
          />
        </Spin>
      ),
      okText: 'Upload',
      okButtonProps: {
        ...okButtonProps,
        disabled: products.uploadFetching || !fileListData.length
      },
      onOk: () => uploadData && props.uploadProductData(uploadData),
      cancelText: 'Cancel',
      onCancel: () => setModalType(null),
    },
    history: {
      width: 620,
      title: `Product history`,
      isFooter: true,
      children: () => (
          <HistoryPopUp
            asin={currentAsin}
          />
      ),
      // onCancel: () => setModalType(null),
      onCancel: () => {
        setModalType(null);
        setCurrentAsin(null);
      },
      okButtonProps: {
        htmlType: "submit",
        type: "primary",
        className: "btn-primary",
        // form: "refundable-form",
        // disabled: orders.saveFetching
      }
    }
  };

  return (
    <div className="products-page content-page">
      <div className="table-options">
        <div className="table-options-inputs" >
          {permissions?.products_edit && <Dropdown overlay={menu} trigger={['click']} disabled={!selected.length}>
            <Button className="btn-primary" type="primary white withoutborder" style={{ height: 40, padding: '0 8px' }}>
              Edit<div style={{ width: 5}}></div><ArrowDownSelect />
            </Button>
          </Dropdown>}
          {/* <SelectComponent data={editData}
            onChange={e => console.log(e)}
            placeholder="Edit"
            className="table-options-select--bold select--transparent"

          /> */}
          {/* <SelectComponent data={[1, 2, 3]}
            onChange={e => console.log(e)}
            placeholder="Filter listings"
            defaultValue="Filter listings"
          />
          <SelectComponent data={[1, 2, 3]}
            onChange={e => console.log(e)}
            placeholder="Supplier"
            defaultValue="Supplier"
          /> */}
          <div className="header-link-divider" />
          <Search
            style={{ marginRight: 0 }}
            placeholder="Search"
            className="search-input"
            allowClear
            onSearch={handleSearch}
            suffix={<SearchIcon />}
          />
          <div className="header-link-divider" />
          {permissions?.products_edit && <Button
            className="btn-primary"
            style={{ marginRight: 16 }}
            onClick={openCreateModal}
          >
            Create product
          </Button>}

          {permissions?.products_edit && 
            <>
              <Button
                className="btn-primary"
                type="primary white"
                onClick={() => setModalType('upload')}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
              >
                <UploadIcon isActive={active} /><div style={{ width: 4 }} />
                <span style={{ color: active ? '#FFF' : '#232D53' }}>
                  Upload Bulk Product
                </span>
              </Button>
              <div className="header-link-divider" />
            </>
          }
          <SelectComponent 
            data={['inactive', 'pending']}
            allowClear
            onChange={e => changeFilter('active_status', e)}
            placeholder="Manual inactivation"
          />
          <SelectComponent 
            data={orders.filters?.suppliers}
            allowClear
            onChange={e => changeFilter('supplier', e)}
            placeholder="Supplier"
          />
        </div >
        <TableOptionsActions
          defaultValue={products.page_size}
          pageSizeHandler={pageSizeHandler}
        />
      </div >
      <div style={{ height: 50, width: '100%', display: 'flex' }}></div>
      <TableComponent
        loading={products.fetching}
        data={data}
        columns={tableColumns}
        onSort={handleTableSort}
        selection={permissions?.products_edit ? rowSelection : null}
      />
        <PaginationComponent
          onChange={(page, page_size) => onPaginate(page, page_size)}
          total={products.list?.count}
          current={products.page}
          pageSize={products.page_size}
        />
        <div className="total-products" >{`Total products: ${products?.list?.count}`}</div>
      <Modal
        {...ModalContent[modalType]?.modalProps}
        wrapClassName="modal-wrap"
        className="modal"
        title={ModalContent[modalType]?.title}
        visible={modalVisible}
        children={ModalContent[modalType]?.children()}
        okText={ModalContent[modalType]?.okText}
        okButtonProps={ModalContent[modalType]?.okButtonProps}
        onOk={ModalContent[modalType]?.onOk}
        cancelText={ModalContent[modalType]?.cancelText}
        cancelButtonProps={cancelButtonProps}
        onCancel={ModalContent[modalType]?.onCancel}
        handleClose={() => setModalType(null)}
        footer={ModalContent[modalType]?.isFooter}
        closable={ModalContent[modalType]?.isFooter ? true : false}
        width={ModalContent[modalType]?.width}
      />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products,
    orders: state.orders,
    permissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProducts: (query) => dispatch(ProductsActions.productsRequest(query)),
  saveProduct: (data) => dispatch(ProductsActions.productSaveRequest(data)),
  deleteProducts: (data) => dispatch(ProductsActions.productsDeleteRequest(data)),
  getStoreList: () => dispatch(ProductsActions.storesRequest()),
  getSupplierList: () => dispatch(ProductsActions.suppliersRequest()),
  changeProductQuantity: (data) => dispatch(ProductsActions.productQtyEditRequest(data)),
  getUploadProductData: () => dispatch(ProductsActions.productUploadListRequest()),
  uploadProductData: (data) => dispatch(ProductsActions.productUploadRequest(data)),
  productChangeStatus: (data) => dispatch(ProductsActions.productChangeStatusRequest(data)),
  saveHandlingTime: (data) => dispatch(ProductsActions.productSaveHandlingTimeRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage)
