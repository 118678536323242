const History = ({ isActive }) => {
  return (
    <svg id="Capa_1" enableBackground="new 0 0 512 512" height="16" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m497 162h-66v-66c0-8.284-6.716-15-15-15h-66v-66c0-8.284-6.716-15-15-15h-320c-8.284 0-15 6.716-15 15v320c0 8.284 6.716 15 15 15h66v66c0 8.284 6.716 15 15 15h66v66c0 8.284 6.716 15 15 15h220c3.978 0 7.793-1.581 10.606-4.394l100-100c2.813-2.813 4.394-6.628 4.394-10.606v-220c0-8.284-6.716-15-15-15zm-467 158v-290h290v51h-224c-8.284 0-15 6.716-15 15v224zm81 81v-290h290v51h-224c-8.284 0-15 6.716-15 15v224zm81-209h290v190h-85c-8.284 0-15 6.716-15 15v85h-190zm268.787 220-48.787 48.787v-48.787z"/>
        <path d="m237 252h200c8.284 0 15-6.716 15-15s-6.716-15-15-15h-200c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
        <path d="m237 312h200c8.284 0 15-6.716 15-15s-6.716-15-15-15h-200c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
        <path d="m357 342h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
        <path d="m337 402h-100c-8.284 0-15 6.716-15 15s6.716 15 15 15h100c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
      </g>
    </svg>
  );
};

export default History;
