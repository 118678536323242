import React from 'react'
import { Form, Select, Row, Col, InputNumber } from 'antd'

const ProductStoreForm = (props) => {
  const { initial, shops, onSubmit } = props
  const onFinish = values => {
    const data = { ...values };
    onSubmit(data);
  }

  return (
    <Form id="product-store-form" className="custom-modal-form" onFinish={onFinish} layout="vertical" requiredMark={false} initialValues={initial}>
      <Row gutter={16} style={{ width: '100%' }}>
        {initial ?
          <>
            <Col span={24}>
              <Form.Item
                label="Default Qty"
                name="default_quantity"
              >
                <InputNumber step="1" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Handling time"
                name="handling_time"
              >
                <InputNumber step="1" />
              </Form.Item>
            </Col>
          </>
          :
          <Col span={24}>
            <Form.Item
              label="MarketPlaces"
              name="seller_shops"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
              >
                {
                  shops?.map(el => (
                    <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        }
      </Row>
    </Form>
  );
};

export default ProductStoreForm;
