const CartItem = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M0.191162 4.00043C0.191162 3.55308 0.553811 3.19043 1.00116 3.19043H3.00116C3.36643 3.19043 3.68651 3.43491 3.78262 3.7873L4.44885 6.23014L19.0544 7.19218C19.4802 7.22023 19.8112 7.57375 19.8112 8.00043V14.0004C19.8112 14.4237 19.4853 14.7756 19.0633 14.808L6.06329 15.808C5.67591 15.8378 5.32193 15.5884 5.2197 15.2136L3.03789 7.21355L2.38249 4.81043H1.00116C0.553811 4.81043 0.191162 4.44778 0.191162 4.00043ZM7.78665 8.80399C7.67815 8.37 7.23838 8.10613 6.80438 8.21463C6.37039 8.32313 6.10652 8.76291 6.21502 9.1969L7.21502 13.1969C7.32352 13.6309 7.7633 13.8948 8.19729 13.7863C8.63128 13.6778 8.89515 13.238 8.78665 12.804L7.78665 8.80399ZM11.3863 8.44849C11.8291 8.38522 12.2394 8.69294 12.3027 9.1358L12.8027 12.6358C12.866 13.0787 12.5582 13.4889 12.1154 13.5522C11.6725 13.6155 11.2622 13.3078 11.199 12.8649L10.699 9.3649C10.6357 8.92204 10.9434 8.51175 11.3863 8.44849ZM16.8108 9.5004C16.8108 9.05305 16.4482 8.6904 16.0008 8.6904C15.5535 8.6904 15.1908 9.05305 15.1908 9.5004V12.5004C15.1908 12.9477 15.5535 13.3104 16.0008 13.3104C16.4482 13.3104 16.8108 12.9477 16.8108 12.5004V9.5004ZM10.0003 19.0003C10.0003 20.1049 9.10489 21.0003 8.00032 21.0003C6.89575 21.0003 6.00032 20.1049 6.00032 19.0003C6.00032 17.8957 6.89575 17.0003 8.00032 17.0003C9.10489 17.0003 10.0003 17.8957 10.0003 19.0003ZM16.0007 21.0003C17.1053 21.0003 18.0007 20.1049 18.0007 19.0003C18.0007 17.8957 17.1053 17.0003 16.0007 17.0003C14.8961 17.0003 14.0007 17.8957 14.0007 19.0003C14.0007 20.1049 14.8961 21.0003 16.0007 21.0003Z"
        fill={isActive ? "#526DCB" : "#262F56"} />
    </svg>

  );
};

export default CartItem;
