import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dashboardRequest: ['query'],
  dashboardSuccess: ['list'],

  dashboardReset: null,

  dashboardFetchingReset: null
})

export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

})

/* ------------- Selectors ------------- */
export const DashboardSelectors = {
  getState: state => state.dashboard,
}

/* ------------- Reducers ------------- */

export const dashboardRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const dashboardSuccess = (state, { list }) => {
  return state.merge({ fetching: false, list })
}

export const dashboardReset = (state) => {
  return INITIAL_STATE
}

export const dashboardFetchingReset = (state) => {
  return state.merge({ fetching: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_REQUEST]: dashboardRequest,
  [Types.DASHBOARD_SUCCESS]: dashboardSuccess,

  [Types.DASHBOARD_RESET]: dashboardReset,

  [Types.DASHBOARD_FETCHING_RESET]: dashboardFetchingReset,
})
