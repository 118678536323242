import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getNotesRequest: ['query'],
  getNotesSuccess: ['notes'],

  // notificationsDeleteRequest: ['noteId'],
  // notificationsDeleteSuccess: ['deleteResult'],

  getNewNotesRequest: null,
  getNewNotesSuccess: ['isNewNotes'],

  resultsReset: null,
  notesFetchingReset: null,

  notesSyncToggle: ['syncIsStart'],

  notesSettingReset: null,
  isNewNotesReset: null,

  historyNotesSettingReset: null,

  getHistoryNotesRequest: ['query'],
  getHistoryNotesSuccess: ['historyNotes'],

  saveNoteHistoryRequest: ['data'],
  saveNoteHistorySuccess: ['saveResult'],
})

export const NotesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notes: null,
  fetching: false,

  isNewNotes: false,

  saveResult: null,
  saveFetching: false,

  // deleteResult: null,
  // deleteFetching: false,

  page: 1,
  page_size: 10,
  ordering: '',
  search: '',
  filters: null,

  syncIsStart: false,

  historyNotes: null,
  historyFetching: false,
  history_page: 1,
  history_page_size: 10,
  history_search: '',
})

/* ------------- Selectors ------------- */
export const NotesSelectors = {
  getState: (state) => state.notes
}

/* ------------- Reducers ------------- */

export const getNotesRequest = (state, { query }) => {
  const newState = {
    fetching: true
  };
  if (query.page) newState.page = query.page;
  if (query.page_size) newState.page_size = query.page_size;
  if (query.ordering) newState.ordering = query.ordering;
  newState.search = query.q || '';
  return state.merge(newState)
}

export const getNotesSuccess = (state, { notes }) => {
  return state.merge({ fetching: false, notes })
}

export const getNewNotesRequest = (state) => {
  return state.merge()
}

export const getNewNotesSuccess = (state, { isNewNotes }) => {
  return state.merge({ isNewNotes })
}

export const notesSyncToggle = (state, { syncIsStart }) => {
  return state.merge({ syncIsStart })
}

export const getHistoryNotesRequest = (state, { query }) => {
  const newState = {
    historyFetching: true
  };
  if (query.page) newState.history_page = query.page;
  if (query.page_size) newState.history_page_size = query.page_size;

  newState.history_search = query.q || '';
  return state.merge(newState)
}

export const getHistoryNotesSuccess = (state, { historyNotes }) => {
  return state.merge({ historyFetching: false, historyNotes })
}

export const saveNoteHistoryRequest = (state, { data }) => {
  return state.merge({ saveFetching: true })
}

export const saveNoteHistorySuccess = (state, { saveResult }) => {
  return state.merge({ saveFetching: false, saveResult })
}

// export const ordersReset = (state) => {
  //   return INITIAL_STATE
// }

export const resultsReset = (state) => {
  return state.merge({
    saveResult: null,
    deleteResult: null,
  })
}

export const notesFetchingReset = (state) => {
  return state.merge({
    fetching: false,
    saveFetching: false,
    historyfetching: false,
    // deleteFetching: false,
  })
}

export const notesSettingReset = (state) => {
  const newState = {
    page: 1,
    // ordering: '',
    // search: '',
  };
  return state.merge(newState)
}

export const historyNotesSettingReset = (state) => {
  const newState = {
    history_page: 1,
  };
  return state.merge(newState)
}

export const isNewNotesReset = (state) => {
  return state.merge({ isNewNotes: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTES_REQUEST]: getNotesRequest,
  [Types.GET_NOTES_SUCCESS]: getNotesSuccess,
  
  [Types.GET_HISTORY_NOTES_REQUEST]: getHistoryNotesRequest,
  [Types.GET_HISTORY_NOTES_SUCCESS]: getHistoryNotesSuccess,

  [Types.GET_NEW_NOTES_REQUEST]: getNewNotesRequest,
  [Types.GET_NEW_NOTES_SUCCESS]: getNewNotesSuccess,
  
  [Types.SAVE_NOTE_HISTORY_REQUEST]: saveNoteHistoryRequest,
  [Types.SAVE_NOTE_HISTORY_SUCCESS]: saveNoteHistorySuccess,

  // [Types.NOTIFICATIONS_DELETE_REQUEST]: notificationsDeleteRequest,
  // [Types.NOTIFICATIONS_DELETE_SUCCESS]: notificationsDeleteSuccess,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.NOTES_FETCHING_RESET]: notesFetchingReset,

  [Types.NOTES_SYNC_TOGGLE]: notesSyncToggle,
  
  [Types.NOTES_SETTING_RESET]: notesSettingReset,
  [Types.IS_NEW_NOTES_RESET]: isNewNotesReset,
  
  [Types.HISTORY_NOTES_SETTING_RESET]: historyNotesSettingReset,
})
