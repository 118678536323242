const ListItem = ({ isActive }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 7.5C23.0523 7.5 23.5 7.05229 23.5 6.5C23.5 5.94772 23.0523 5.5 22.5 5.5L22.5 7.5ZM9.5 5.5C8.94772 5.5 8.5 5.94772 8.5 6.5C8.5 7.05228 8.94772 7.5 9.5 7.5L9.5 5.5ZM22.5 5.5L9.5 5.5L9.5 7.5L22.5 7.5L22.5 5.5Z"
        fill={isActive ? "#526DCB" : "#262F56"} />
      <path
        d="M22.5 13C23.0523 13 23.5 12.5523 23.5 12C23.5 11.4477 23.0523 11 22.5 11L22.5 13ZM9.5 11C8.94772 11 8.5 11.4477 8.5 12C8.5 12.5523 8.94772 13 9.5 13L9.5 11ZM22.5 11L9.5 11L9.5 13L22.5 13L22.5 11Z"
        fill={isActive ? "#526DCB" : "#262F56"} />
      <path
        d="M22.5 18.5C23.0523 18.5 23.5 18.0523 23.5 17.5C23.5 16.9477 23.0523 16.5 22.5 16.5L22.5 18.5ZM9.5 16.5C8.94772 16.5 8.5 16.9477 8.5 17.5C8.5 18.0523 8.94772 18.5 9.5 18.5L9.5 16.5ZM22.5 16.5L9.5 16.5L9.5 18.5L22.5 18.5L22.5 16.5Z"
        fill={isActive ? "#526DCB" : "#262F56"} />
      <circle cx="4.5" cy="6.5" r="1" fill={isActive ? "#526DCB" : "#262F56"} stroke={isActive ? "#526DCB" : "#262F56"} strokeWidth="1.33333" />
      <circle cx="4.5" cy="12" r="1" fill={isActive ? "#526DCB" : "#262F56"} stroke={isActive ? "#526DCB" : "#262F56"} strokeWidth="1.33333" />
      <circle cx="4.5" cy="17.5" r="1" fill={isActive ? "#526DCB" : "#262F56"} stroke={isActive ? "#526DCB" : "#262F56"} strokeWidth="1.33333" />
    </svg>

  );
};

export default ListItem;
