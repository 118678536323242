import { takeLatest, takeEvery, all, fork } from 'redux-saga/effects'
import AuthAPI from '../Services/AuthApi'
import API from '../Services/Api'

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux'

import { AuthTypes } from '../Redux/AuthRedux'

import { OrdersTypes } from '../Redux/OrdersRedux'

import { ProductsTypes } from '../Redux/ProductsRedux'

import { UsersTypes } from '../Redux/UsersRedux'

import { DashboardTypes } from '../Redux/DashboardRedux'

import { NotesTypes } from '../Redux/NotesRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'

import {
  signIn,
  getUserPermissions,
} from './AuthSagas'

import {
  getOrders,
  saveOrder,
  updateInternalStatus,
  getOrderFilters,
  getAvList,
  changeAssigned,
  getShippingServices,
  saveComment,
  getComments,
} from './OrdersSagas'

import {
  getProducts,
  getProduct,
  saveProduct,
  // deleteProduct,
  deleteProducts,
  changeProductQuantity,
  getStoreList,
  addStoreToProduct,
  deleteStoreFromProduct,
  getSupplierList,
  addSupplierToProduct,
  deleteSupplierFromProduct,
  editSupplierUrl,
  editStoreData,
  getUploadProductData,
  uploadProductData,
  changeProductStatus,
  saveHandlingTime,
  // productsSyncStartMonitor,
} from './ProductsSagas'

import {
  getUsers,
  getUser,
  saveUser,
  deleteUser,
  markAsDeletedUser,
  unmarkAsDeletedUser,
  getUserRoles,
  getPermissionsList,
  updatePermissionsList,
} from './UsersSagas'

import {
  getDashboard,
} from './DashboardSagas'

import {
  getNotes,
  getNewNotes,
  notesSyncStartMonitor,
  getHistoryNotes,
  saveNoteHistory,
} from './NotesSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;
const api = API;
//const usersApi = UsersAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),

    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),
    takeLatest(AuthTypes.GET_USER_PERMISSIONS_REQUEST, getUserPermissions, authApi),

    takeLatest(OrdersTypes.ORDERS_REQUEST, getOrders, api, authApi),
    takeEvery(OrdersTypes.ORDER_SAVE_REQUEST, saveOrder, api, authApi),
    takeLatest(OrdersTypes.INTERNAL_STATUS_SAVE_REQUEST, updateInternalStatus, api, authApi),
    takeLatest(OrdersTypes.ORDER_FILTERS_REQUEST, getOrderFilters, api, authApi),
    takeLatest(OrdersTypes.AV_LIST_REQUEST, getAvList, api, authApi),
    takeLatest(OrdersTypes.ASSIGNED_SAVE_REQUEST, changeAssigned, api, authApi),
    takeLatest(OrdersTypes.SHIPPING_SERVICES_REQUEST, getShippingServices, api, authApi),
    takeLatest(OrdersTypes.SAVE_COMMENT_REQUEST, saveComment, api, authApi),
    takeLatest(OrdersTypes.GET_COMMENTS_REQUEST, getComments, api, authApi),

    takeLatest(ProductsTypes.PRODUCTS_REQUEST, getProducts, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_REQUEST, getProduct, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_SAVE_REQUEST, saveProduct, api, authApi),
    // takeLatest(ProductsTypes.PRODUCT_DELETE_REQUEST, deleteProduct, api, authApi),
    takeLatest(ProductsTypes.PRODUCTS_DELETE_REQUEST, deleteProducts, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_QTY_EDIT_REQUEST, changeProductQuantity, api, authApi),
    takeLatest(ProductsTypes.STORES_REQUEST, getStoreList, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_STORE_ADD_REQUEST, addStoreToProduct, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_STORE_DELETE_REQUEST, deleteStoreFromProduct, api, authApi),
    takeLatest(ProductsTypes.SUPPLIERS_REQUEST, getSupplierList, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_SUPPLIER_ADD_REQUEST, addSupplierToProduct, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_SUPPLIER_DELETE_REQUEST, deleteSupplierFromProduct, api, authApi),
    takeLatest(ProductsTypes.SUPPLIER_URL_EDIT_REQUEST, editSupplierUrl, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_STORE_EDIT_REQUEST, editStoreData, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_UPLOAD_LIST_REQUEST, getUploadProductData, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_UPLOAD_REQUEST, uploadProductData, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_CHANGE_STATUS_REQUEST, changeProductStatus, api, authApi),
    takeLatest(ProductsTypes.PRODUCT_SAVE_HANDLING_TIME_REQUEST, saveHandlingTime, api, authApi),
    // fork(productsSyncStartMonitor),

    takeLatest(UsersTypes.USERS_REQUEST, getUsers, api, authApi),
    takeLatest(UsersTypes.USER_REQUEST, getUser, api, authApi),
    takeLatest(UsersTypes.USER_SAVE_REQUEST, saveUser, api, authApi),
    takeLatest(UsersTypes.USER_DELETE_REQUEST, deleteUser, api, authApi),
    takeLatest(UsersTypes.USER_BLOCK_REQUEST, markAsDeletedUser, api, authApi),
    takeLatest(UsersTypes.USER_UNBLOCK_REQUEST, unmarkAsDeletedUser, api, authApi),
    takeLatest(UsersTypes.USER_ROLES_REQUEST, getUserRoles, api, authApi),
    takeLatest(UsersTypes.GET_PERMISSIONS_LIST_REQUEST, getPermissionsList, api, authApi),
    takeLatest(UsersTypes.UPDATE_PERMISSIONS_LIST_REQUEST, updatePermissionsList, api, authApi),

    takeLatest(DashboardTypes.DASHBOARD_REQUEST, getDashboard, api, authApi),
    
    takeLatest(NotesTypes.GET_NOTES_REQUEST, getNotes, api, authApi),
    takeLatest(NotesTypes.GET_NEW_NOTES_REQUEST, getNewNotes, api, authApi),
    fork(notesSyncStartMonitor),
    takeLatest(NotesTypes.GET_HISTORY_NOTES_REQUEST, getHistoryNotes, api, authApi),
    takeLatest(NotesTypes.SAVE_NOTE_HISTORY_REQUEST, saveNoteHistory, api, authApi),
  ])
}
