/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, InputNumber, Row, Col } from 'antd'

import './style.less'

const RefundableForm = props => {
  const { init, onSubmit } = props;
// console.log('init', init)
  const [form] = Form.useForm();

  useEffect(() => {
    if (init) {
      const newData = {
        yra: init.data.yra[init.index],
        sra: init.data.sra[init.index],
        refund_shipping_cost: init.data.refund_shipping_cost[init.index],
      }
      form.setFieldsValue(newData);
    }
  }, [init])

  const onFinish = values => {
    let res = { ...values };
    res.id = init.data.id[init.index];
    res.internal_status = 'refunded';

    onSubmit(res);
  }

  return (
    <Form id="refundable-form" className="custom-modal-form" form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
      <div className="refundable-info">
        {/* <div>Buyer paid: {init?.data?.customer_order__amount_paid}</div> */}
        <div>Buyer paid: {init?.data?.customer_order__amount_paid[init.index]}</div>
        <div>Price after AZ fee: {Number(init?.data?.customer_order__amount_paid[init.index] - init?.data?.amazon_fee[init.index]).toFixed(2)}</div>
        <div>Purchase Cost: {(Number(init?.data?.supplier_order__total_price[init.index]) || 0).toFixed(2)}</div>
        <div>Refund admin fee: {(Number(init?.data?.refund_admin_fee[init.index]) || 0).toFixed(2)}</div>
        <div>Net profit: {(Number(init?.data?.net_profit) || 0).toFixed(2)}</div>
        <div>Net margin: {(Number(init?.data?.net_margin[init.index]) || 0).toFixed(2)}</div>
      </div>
      <div className="divider"></div>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="You Refunded the Buyer Amount"
            name="yra"
          >
            <InputNumber
              addonBefore="$"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Supplier Refunded Your Amount"
            name="sra"
          >
            <InputNumber
              addonBefore="$"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Refund shipping cost"
            name="refund_shipping_cost"
          >
            <InputNumber
              addonBefore="$"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form >
  );
}

export default RefundableForm
