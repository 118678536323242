import React from 'react';

const LineIcon = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33329 1L11.3333 4M11.3333 4L8.33329 7M11.3333 4H0.666626" stroke="#a6aaba" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LineIcon;
