import React from 'react'
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from './Redux'
import { Layout } from 'antd'


import HomePage from './Containers/HomePage'
import StartupPage from './Containers/StartupPage'
import LoginPage from './Containers/LoginPage'
import NotFoundPage from './Containers/NotFoundPage'

function Routes(props) {
  const { startup } = props;

  let isStartup = true;
  if (startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  } else {
    isStartup = true;
  }

  return (
    <Router history={history}>
      <Layout className="main-layout">
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route exact path="/login" component={LoginPage} />
          {isStartup ? <Route path="/home/*" component={HomePage} /> : null}
          <Route path="/*" component={NotFoundPage} />
        </Switch>
      </Layout>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    startup: state.startup,
  }
}

export default connect(mapStateToProps)(Routes)
